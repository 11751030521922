import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { MeteorObservable } from 'meteor-rxjs';
import { SupplyPackage } from 'common/models';

@Injectable()
export class SupplyPackagesService {
  constructor() {}

  validate(pkg: SupplyPackage): Observable<any> {
    return MeteorObservable.call('supplyPackage.validate', pkg);
  }

  create(pkg: SupplyPackage): Promise<{ _id: string }> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call<{ _id: string }>('supplyPackage.create', pkg).subscribe(
        (result) => {
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  changeStatus(_id: string, active: boolean): Observable<SupplyPackage> {
    return MeteorObservable.call('supplyPackage.changeStatus', _id, active);
  }
}
