export interface CurrencyRate {
  gbp?: { [attr: string]: string };
  usd?: { [attr: string]: string };
  eur?: { [attr: string]: string };
  pln?: { [attr: string]: string };
}
export interface CurrencyExchangeRate {
  _id?: string;
  ratesFromTo: CurrencyRate;
  updatedAt?: Date;
}
