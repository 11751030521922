import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price2',
})
export class Price2Pipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '0.00';
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return '' + value.toFixed(2);
  }
}

@Pipe({
  name: 'price6',
})
export class Price6Pipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '0.000000';
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return '' + value.toFixed(6);
  }
}
