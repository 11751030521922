import { Directive, HostListener, Renderer2, ElementRef, Input, AfterContentInit, ContentChild } from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements AfterContentInit {
  @Input() appTooltip: any;
  @ContentChild(TooltipComponent) private _appTooltip: TooltipComponent;
  constructor(private _renderer: Renderer2, private _el: ElementRef) {}

  ngAfterContentInit(): void {
    if (!this.appTooltip) {
      if (!this._appTooltip) {
        throw new Error('You must add reference to tolltip component or add it as child');
      }
      this.appTooltip = this._appTooltip;
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.appTooltip && this.appTooltip.msg) {
      const pos = this._el.nativeElement.getBoundingClientRect();
      const left = pos.x + pos.width / 2;
      const top = pos.y - 8;
      this._renderer.setStyle(this.appTooltip._el.nativeElement, 'top', `${top}px`);
      this._renderer.setStyle(this.appTooltip._el.nativeElement, 'left', `${left}px`);
      this._renderer.addClass(this.appTooltip._el.nativeElement, 'fade');
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.appTooltip) {
      this._renderer.removeClass(this.appTooltip._el.nativeElement, 'fade');
    }
  }
}
