<div class="row list">
  <table>
    <thead>
      <tr>
        <th class="sort broaden" (click)="changeSort('date')">
          <app-table-title [sort]="sort" [selected]="sortField === 'date'"> Last<br />played at </app-table-title>
        </th>
        <th class="sort broaden" (click)="changeSort('name')">
          <app-table-title [sort]="sort" [selected]="sortField === 'name'">
            campaign
          </app-table-title>
        </th>
        <th
          class="text-right sort"
          *ngIf="status !== 'rejected' && status !== 'pending'"
          (click)="changeSort('totalPlayout')"
        >
          <app-table-title [sort]="sort" [selected]="sortField === 'totalPlayout'" [alignRight]="true">
            playouts
          </app-table-title>
        </th>
        <th
          class="text-right sort"
          *ngIf="status !== 'rejected' && status !== 'pending'"
          (click)="changeSort('uniqueListeners')"
        >
          <app-table-title [sort]="sort" [selected]="sortField === 'uniqueListeners'" [alignRight]="true">
            unique
          </app-table-title>
        </th>
        <th
          class="text-right sort"
          *ngIf="status !== 'rejected' && status !== 'pending'"
          (click)="changeSort('averagePosition')"
        >
          <app-table-title [sort]="sort" [selected]="sortField === 'averagePosition'" [alignRight]="true">
            Avg. Position
          </app-table-title>
        </th>
        <th class="text-right sort" *ngIf="!isAdvertiser" (click)="changeSort('advertiser')">
          <app-table-title [sort]="sort" [selected]="sortField === 'advertiser'">
            advertiser
          </app-table-title>
        </th>
        <th class="text-right sort" (click)="changeSort('normalizedTotalCPP')">
          <app-table-title [sort]="sort" [selected]="sortField === 'normalizedTotalCPP'" [alignRight]="true">
            total cpp
          </app-table-title>
        </th>
        <th
          class="text-right sort broaden"
          *ngIf="status !== 'rejected' && status !== 'pending'"
          (click)="changeSort('normalizedTotalCost')"
        >
          <app-table-title [sort]="sort" [selected]="sortField === 'normalizedTotalCost'" [alignRight]="true">
            cost
          </app-table-title>
        </th>
        <th class="text-right sort broaden" (click)="changeSort('normalizedTotalBudget')" *ngIf="!isAdvertiser">
          <app-table-title [sort]="sort" [selected]="sortField === 'normalizedTotalBudget'" [alignRight]="true">
            budget
          </app-table-title>
        </th>
        <th class="text-right sort broaden" (click)="changeSort('progress')">
          <app-table-title [appTooltip]="progressPaceTooltip" [sort]="sort" [selected]="sortField === 'progress'" [alignRight]="true">
            Progress
          </app-table-title>
          <app-tooltip
            msg="Color-coded according to pace:
            Green means good pace,
            Orange - healthy, but could be better,
            Red - campaign is underperforming."
          #progressPaceTooltip></app-tooltip>
        </th>
        <th class="text-left sort broaden" (click)="changeSort('status')" *ngIf="status === 'all'">
          <app-table-title [sort]="sort" [selected]="sortField === 'status'">
            status
          </app-table-title>
        </th>
        <th class="text-left" *ngIf="status === 'pending'">
          <app-table-title>
            date range
          </app-table-title>
        </th>
        <th class="text-left" *ngIf="!isAdvertiser && status === 'pending'"></th>
      </tr>
    </thead>

    <ng-container *ngIf="campaigns$ | async as campaigns; else loading">
      <tbody *ngIf="campaigns.length; else noCampaigns">
        <tr *ngFor="let c of campaigns | paginate: config">
          <td>
            {{ c.lastPlayout || c.updatedAt || c.createdAt | amDateFormat: 'DD.MM.YYYY' }}
          </td>
          <td>
            <a class="text-ellipsis" [routerLink]="['../', 'campaigns', c._id]" [title]="c.name">{{
              c.name | truncate: [20, '...']
            }}</a>
          </td>
          <td class="text-right" *ngIf="status !== 'rejected' && status !== 'pending'">{{ c.totalPlayout || 0 | number: '1.0-0' }}</td>
          <td class="text-right" *ngIf="status !== 'rejected' && status !== 'pending'">{{ c.uniqueListeners || 0 | number: '1.0-0' }}</td>
          <td class="text-right" *ngIf="status !== 'rejected' && status !== 'pending'">
            {{ c.averagePosition || 0 | number: '1.1-1' }}
          </td>
          <td *ngIf="!isAdvertiser">
            <a [routerLink]="['../', 'users', c.advertiser._id]" [title]="c.advertiser.emails[0].address">
              {{ userTitle(c.advertiser) }}
            </a>
          </td>

          <td class="text-right" *ngIf="isAdvertiser">

            {{ c.totalCostPerPlay | price6 }} {{ c.currency | uppercase }}
          </td>
          <td class="text-right" *ngIf="!isAdvertiser">{{ c.normalizedTotalCPP | price6 }} GBP</td>

          <td class="text-right" *ngIf="isAdvertiser && status !== 'rejected' && status !== 'pending'">
            {{ c.totalCost | price6 }} {{ c.currency | uppercase }}
          </td>
          <td class="text-right" *ngIf="!isAdvertiser && status !== 'rejected' && status !== 'pending'">
            {{ c.normalizedTotalCost | price6 }} GBP
          </td>

          <td class="text-right" *ngIf="!isAdvertiser">
            <span> {{ c.normalizedTotalBudget | price2 }} GBP </span>
          </td>
          <td class="text-right">
            <span class="pace--bad" *ngIf="c.pace === '0/2'"> {{ c.progress | percent:'.2' }}</span>
            <span class="pace--so-so" *ngIf="c.pace === '1/2'"> {{ c.progress | percent:'.2' }}</span>
            <span class="pace--good" *ngIf="c.pace === '2/2'"> {{ c.progress | percent:'.2' }}</span>
          </td>
          <td class="text-left" *ngIf="status === 'all'">{{ c.status | capitalize }}</td>
          <td class="text-left" *ngIf="status === 'pending'">
            {{ c.startDate | amDateFormat: 'DD.MM.YYYY' }} - {{ c.endDate | amDateFormat: 'DD.MM.YYYY' }}
          </td>
          <td class="text-left" *ngIf="!isAdvertiser && status === 'pending'">
            <div class="dropdown" dropdown>
              <button class="button button__secondary button--small" dropdownToggle>
                <span>
                  Action
                </span>
                <img src="assets/images/icons/dropdown.svg" />
              </button>
              <div *dropdownMenu class="dropdown-menu">
                <a class="dropdown-item" (click)="changeStatus(c, 'active')">Accept</a>
                <a class="dropdown-item" (click)="changeStatus(c, 'rejected')">Reject</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-container>

    <ng-template #noCampaigns>
      <tbody>
        <tr>
          <td colspan="11">
            <div class="list__empty list__empty_table">
              <div class="list__empty__inner">
                <img src="assets/images/icons/empty_state.svg" />
                <p *ngIf="isAdvertiser">
                  <span *ngIf="status === 'all'">
                    You have no campaigns. Create one
                    <a routerLink="new">here</a>.
                  </span>
                  <span *ngIf="status !== 'all'">
                    There are no campaigns that meet the criteria.
                  </span>
                </p>
                <p *ngIf="!isAdvertiser">There are no campaigns that meet the criteria.</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #loading>
      <tbody>
        <tr>
          <td colspan="11">
            <div class="list__empty list__empty_table">
              <div class="list__empty__inner">
                <img src="/assets/images/loader.svg" width="50" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>
<div class="row list__pagination justify-content-center" *ngIf="(campaigns$ | async)?.length > config.itemsPerPage">
  <pagination-template
    #p="paginationApi"
    [id]="config.id"
    (click)="pagination(config.currentPage)"
    (pageChange)="config.currentPage = $event"
  >
    <div class="app-pagination">
      <div class="app-pagination__previous" [class.disabled]="p.isFirstPage()">
        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
          <img src="assets/images/icons/dropdown.svg" />
        </a>
      </div>
      <div *ngFor="let page of p.pages" class="app-pagination__page" [class.current]="p.getCurrent() === page.value">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </div>
      <div class="app-pagination__next" [class.disabled]="p.isLastPage()">
        <a *ngIf="!p.isLastPage()" (click)="p.next()">
          <img src="assets/images/icons/dropdown.svg" />
        </a>
      </div>
    </div>
  </pagination-template>
</div>
