<div class="container campaigns">
  <div class="row campaigns__header">
    <div>
      <h3>
        Edit campaign <span class="muted">#{{ campaign._id }}</span>
      </h3>
    </div>
  </div>
  <div class="row campaigns__form">
    <form class="campaigns__form__content campaigns__form__content--row" [formGroup]="formGroup">
      <div class="col-md-12">
        <p>Impression URLs</p>
        <div id="impression_urls">
          <ng-container formArrayName="impressionUrls">
            <div *ngFor="let control of impressionUrls.controls; let idx = index" class="row py-1 mx-0">
              <ng-container [formGroupName]="idx">
                <div class="col-sm-12 col-md-8 form-group" [ngClass]="{ 'has-error': isInvalid(idx) }">
                  <input
                    type="text"
                    class="form-control"
                    [id]="idx + 'url'"
                    placeholder="Enter Impression URL"
                    formControlName="uri"
                  />
                  <small class="form-text text-error col-12">
                    {{
                      control && control.errors && control.errors.required
                        ? 'Link is required'
                        : 'Wrong url format, link should start with http:// or https://'
                    }}
                  </small>
                </div>

                <div class="col-sm-12 col-md-3 form-group">
                  <select class="form-control" [id]="idx + 'event'" formControlName="event">
                    <option *ngFor="let event of impressionUrlsEvents" [value]="event">
                      {{ event | titlecase }}
                    </option>
                  </select>
                </div>

                <div class="col-sm-12 col-md-1">
                  <button (click)="removeImpressionUrl(idx)" class="remove-btn button button__secondary button--small">
                    <span>&times;</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 py-3">
        <button (click)="addImpressionUrl()">Add</button>
      </div>
    </form>
    <div class="campaigns__form__footer">
      <button class="button button__secondary button--small" routerLink="../">
        Cancel
      </button>
      <button class="button button__primary button--small" [disabled]="formGroup.invalid" (click)="update()">
        Save
      </button>
    </div>
  </div>
</div>
