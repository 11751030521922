import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  addControlError,
  ControlStatus,
  isHttpStatusCodeSuccess,
  removeControlError,
  WebsiteDataService,
  WebsiteStatus,
} from 'common';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-website-url',
  templateUrl: './website-url.component.html',
  styleUrls: ['website-url.component.scss'],
})
export class WebsiteUrlComponent implements OnInit, OnDestroy {
  public form: FormGroup = this.formGroupDirective.control;
  public websiteControlStatus: ControlStatus<WebsiteStatus> = {
    control: this.form.controls['website'],
    isLoading: true,
    isDataFetched: false,
  };
  private destructor: Subject<boolean> = new Subject();
  constructor(private formGroupDirective: FormGroupDirective, private websiteDataService: WebsiteDataService) {}

  ngOnDestroy(): void {
    this.destructor.next(true);
  }

  ngOnInit(): void {
    const { control } = this.websiteControlStatus;
    this.websiteControlStatus.details$ = control.valueChanges.pipe(
      tap(() => {
        removeControlError(control, 'websiteStatusFail');
        this.websiteControlStatus.isDataFetched = false;
      }),
      filter(
        (value) =>
          value !== '' &&
          !(control.errors && Object.keys(control.errors).length > 0 && !control.hasError('websiteStatusFail'))
      ),
      tap(() => {
        this.switchOnLoader(this.websiteControlStatus);
      }),
      debounceTime(1000),
      startWith(control.value || ''),
      switchMap((websiteUrl) => (websiteUrl ? this.websiteDataService.getWebsiteStatus(websiteUrl) : of(undefined))),
      tap((websiteStatus) => {
        this.switchOffLoader(this.websiteControlStatus);
        this.websiteControlStatus.isDataFetched = true;
        if ((websiteStatus && this.isWebsiteStatusValid(websiteStatus)) || control.value === '') {
          removeControlError(control, 'websiteStatusFail');
        } else {
          addControlError(control, 'websiteStatusFail');
        }
      })
    );
  }

  public isControlInvalid(control: AbstractControl) {
    return (
      control.invalid &&
      (Object.keys(control.errors).length > 1 || !control.hasError('blockSubmitingWhileLoading')) &&
      (control.dirty || control.value !== '')
    );
  }

  public isWebsiteStatusValid(websiteStatus: WebsiteStatus | undefined): boolean {
    return (
      websiteStatus &&
      !this.websiteControlStatus.isLoading &&
      this.websiteControlStatus.isDataFetched &&
      websiteStatus.isOnline &&
      isHttpStatusCodeSuccess(websiteStatus.status)
    );
  }

  private switchOffLoader(controlStatus: ControlStatus<unknown>) {
    controlStatus.isLoading = false;
    removeControlError(controlStatus.control, 'blockSubmitingWhileLoading');
  }

  private switchOnLoader(controlStatus: ControlStatus<unknown>) {
    controlStatus.isLoading = true;
    addControlError(controlStatus.control, 'blockSubmitingWhileLoading');
  }
}
