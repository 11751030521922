import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DspCampaignComponent } from './dsp-campaign.component';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';

@NgModule({
  declarations: [DspCampaignComponent],
  exports: [DspCampaignComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, BsDropdownModule.forRoot()],
  providers: [],
  bootstrap: [DspCampaignComponent],
})
export class DspCampaignModule {}
