import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, Subject } from 'rxjs';
import { AutopromoteAdStatus, User, AutopromoteAd } from 'common/models';
import { Streams } from 'common/collections';
import { AutopromoteAdsService, UserService } from 'common/services';
import { StaticStore } from 'app/staticStore.service';
import { shareReplay, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-autopromote-ads-list',
  templateUrl: './autopromote-ads-list.component.html',
})
export class AutopromoteAdsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isPublisher = false;

  public sort: 1 | -1 = -1;
  public sortField: string = 'date';
  public autopromoteAds$: Observable<AutopromoteAd[]>;
  public config: PaginationInstance = {
    id: 'pagination',
    itemsPerPage: 10,
    currentPage: 1,
  };
  public isAutopromoInVast: boolean = false;
  public hasVastIntegrations: boolean = false;

  private _status: string = 'active';
  private _search: string = '';
  private subscriptionDestructor: Subject<void> = new Subject<void>();

  constructor(
    private autopromoteAdsService: AutopromoteAdsService,
    private userService: UserService,
    private staticStore: StaticStore,
    private router: Router
  ) {}

  @Input() public set status(value: string) {
    if (this._status !== value) {
      this._status = value;
      this.config.currentPage = this.staticStore.getPage();
    }
  }

  @Input() public set search(value: string) {
    if (this._search !== value) {
      this._search = value;
      this.config.currentPage = this.staticStore.getPage();
    }
  }

  public get search(): string {
    return this._search;
  }

  public get status(): string {
    return this._status;
  }

  ngOnInit() {
    this.staticStore.setListingRoute(this.router.url);
    this.userService.currentUser$.pipe(take(1)).subscribe((user: User) => {
      this.isAutopromoInVast = user.autopromoInVast || false;
      this.hasVastIntegrations =
        Streams.find({ publisherId: user._id, vastIntegration: true }).fetch().length > 0 ? true : false;
    });

    this.staticStore.clearSearch.pipe(takeUntil(this.subscriptionDestructor)).subscribe((changed: boolean) => {
      if (changed) {
        this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : 'dateSort';
        this.sort = this.staticStore.getSortOrder();
        this.loadAutopromoteAds();
      }
    });
  }

  ngOnChanges() {
    this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : 'dateSort';
    this.sort = this.staticStore.getSortOrder();
    this.loadAutopromoteAds();
  }

  ngOnDestroy() {
    this.subscriptionDestructor.next();
    this.subscriptionDestructor = null;
  }

  pagination(page: number) {
    this.staticStore.setPage(page);
  }

  public changeSort(fieldName: string) {
    if (this.sortField === fieldName) {
      this.sort = this.sort === -1 ? 1 : -1;
    } else {
      this.sort = -1;
    }
    this.sortField = fieldName;
    this.staticStore.setSortLabel(fieldName);
    this.staticStore.setSortOrder(this.sort);
    this.loadAutopromoteAds();
  }

  public changeStatus(ad: AutopromoteAd, status: AutopromoteAdStatus) {
    ad.status = status;
    try {
      this.autopromoteAdsService.update(ad);
    } catch (error) {}
  }

  private loadAutopromoteAds() {
    this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : 'dateSort';
    this.sort = this.staticStore.getSortOrder();
    const sort = {
      [this.sortField]: this.sort,
    };
    const search: string = this.staticStore
      .getGeneralSearch()
      .trim()
      .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    this.autopromoteAds$ = this.autopromoteAdsService.searchAds(search, this.status, sort).pipe(shareReplay());
    this.config.currentPage = this.staticStore.getPage();
  }
}
