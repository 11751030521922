export enum AudioProductType {
  RADIO = 'radio',
  PODCAST = 'podcast',
  GAME = 'game',
  APP = 'app',
  ARTICLE = 'article',
}

export interface AudioProductTypeData {
  id: AudioProductType;
  name: string;
}
