<div class="container settings" *ngIf="dspCampaign">
  <div class="row align-items-center settings__header">
    <h3>
      DSP Campaign
      <span class="muted">#{{ dspCampaign._id }}</span>
    </h3>
    <button routerLink="../" class="button button__secondary button--small">
      Back
    </button>

    <div [appTooltip]="editButtonTooltip">
      <button
        routerLink="./edit"
        [disabled]="ui.edit.disabled"
        class="button button__primary button--small"
      >
        Edit
      </button>
      <app-tooltip
        [hidden]="!ui.edit.disabled"
        msg="Archived campaigns can't be modified"
        #editButtonTooltip
      ></app-tooltip>
    </div>

    <button
      *ngIf="ui.csvReport.visible"
      routerLink="./csv"
      [disabled]="ui.csvReport.disabled"
      class="button button__primary button--small"
    >
      CSV report
    </button>
    <a
      *ngIf="ui.csvReportUrl.visible"
      [href]="filesUrl + dspCampaign.archiveReportFile"
      class="button button__primary button--small"
    >
      CSV report
    </a>


    <div
      *ngIf="ui.archive.visible"
      [appTooltip]="archiveButtonTooltip"
    >
      <button
        (click)="archive()"
        [disabled]="ui.archive.disabled"
        class="button button__primary button--small"
      >
        Archive
      </button>
    <app-tooltip
      [hidden]="!ui.archive.disabled"
      msg="Archived campaigns can't be activated"
      #archiveButtonTooltip></app-tooltip>
    </div>

  </div>
  <div class="row settings__data">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field label="Name" [content]="dspCampaign.name"></app-detail-field>
        <div class="detail-field">
          <label class="detail-field__label">Audio file</label>
          <div class="detail-field__value">
            <audio controls>
              <source [src]="adUrl" type="audio/mpeg" />
            </audio>
          </div>
        </div>
        <app-detail-field label="Network" [content]="dspCampaign.networkId"></app-detail-field>
        <app-detail-field
          *ngIf="isAdmin"
          label="Advertiser"
          [content]="advertiser ? advertiser.billingName : 'None'"
        ></app-detail-field>
        <app-detail-field
          label="Duration"
          *ngIf="dspCampaign.duration"
          [content]="dspCampaign.duration + ''"
        ></app-detail-field
        ><!-- + '' needed to cast number to string datail-field can't handle numbers-->
        <app-detail-field
          label="Average CPP"
          *ngIf="dspCampaign.averageCpp"
          [content]="dspCampaign.averageCpp + ''"
        ></app-detail-field
        ><!-- + '' needed to cast number to string datail-field can't handle numbers-->
        <app-detail-field
          label="Total Cost"
          *ngIf="dspCampaign.totalCost"
          [content]="dspCampaign.totalCost + ''"
        ></app-detail-field
        ><!-- + '' needed to cast number to string datail-field can't handle numbers-->
        <app-detail-field
          label="Currency"
          *ngIf="dspCampaign.costCurrency"
          [content]="dspCampaign.costCurrency"
        ></app-detail-field>
        <app-detail-field
          label="Last Played"
          *ngIf="dspCampaign.lastPlayed"
          [content]="dspCampaign.lastPlayed"
        ></app-detail-field>
      </div>
    </div>
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field
          label="Languages"
          [content]="dspCampaign.languages || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Devices"
          [content]="dspCampaign.deviceTypes || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Country"
          [content]="dspCampaign.countryCodes || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Cities"
          [content]="dspCampaign.cityIds || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
      </div>
    </div>
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field
          [label]="dspCampaign.gendersExcludeFlag ? 'Gender (exclude)' : 'Gender'"
          [content]="dspCampaign.genderNames || []"
          [emptyArrayPlaceholder]="dspCampaign.gendersExcludeFlag ? 'None' : 'All'"
        ></app-detail-field>
        <app-detail-field
          [label]="dspCampaign.ageRangeExcludeFlag ? 'Age (exclude)' : 'Age'"
          [content]="dspCampaign.ageRanges || []"
          [emptyArrayPlaceholder]="dspCampaign.ageRangeExcludeFlag ? 'None': 'All'"
        ></app-detail-field>
        <app-detail-field
          label="Interest"
          [content]="dspCampaign.interests || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Intent"
          [content]="dspCampaign.intents || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Brand"
          [content]="dspCampaign.brands || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Event"
          [content]="dspCampaign.events || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
      </div>
    </div>
  </div>
</div>
