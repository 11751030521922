<div class="modal__header">
  <h4 class="modal__header__title">Request CSV report</h4>
  <button type="button" class="modal__header__close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal__body text-center">
  <app-date-picker *ngIf="dates" [(dates)]="dates" [dayOffset]="dayOffset"></app-date-picker>
</div>
<div class="modal__body text-center">
  <p>
    <small>
      Generating a report can take up to 30 minutes.<br />
      We will send it to your email as soon as it is ready.
    </small>
  </p>
</div>
<div class="modal__footer">
  <button type="button" class="button button__secondary button--small" (click)="close()">Cancel</button>
  <button type="button" class="button button__primary button--small" [disabled]="dates.length < 2" (click)="save()">
    OK
  </button>
</div>
