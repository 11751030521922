import { DaysOfWeekObject, DaysOfWeek } from '../models';
import * as moment from 'moment';

export function getDaysFromDateRange(data: { startDate: Date; endDate: Date }): DaysOfWeekObject {
  const startDay =
    DaysOfWeek[
      moment(data.startDate)
        .format('dddd')
        .toUpperCase()
    ];
  const endDay =
    DaysOfWeek[
      moment(data.endDate)
        .format('dddd')
        .toUpperCase()
    ];

  const isWholeWeek = moment.duration(moment(data.endDate).diff(moment(data.startDate))).as('weeks') >= 1;
  if (isWholeWeek) {
    const days = Object.keys(DaysOfWeek);
    days.pop(); // removing last day its not necessary because SATURDAY and SUNDAY has same binary.
    return {
      array: days,
      binary: 63, // 6 days in binary
      binaryArray: ['1', '1', '1', '1', '1', '1'],
    };
  }

  if (data.startDate === data.endDate) {
    const binary = startDay.toString('2');
    return {
      array: [startDay],
      binary: binary,
      binaryArray: ('000000'.substr(binary.length) + binary).split('') as ('0' | '1')[],
    };
  }
  let day = startDay;
  let binaryDays = startDay;
  const days = [DaysOfWeek[startDay]];
  while (day !== endDay) {
    day = day + day > 32 ? 1 : day + day;
    days.push(DaysOfWeek[day]);
    binaryDays += day;
  }
  const binary = binaryDays.toString('2');
  return {
    array: days,
    binary: binaryDays,
    binaryArray: ('000000'.substr(binary.length) + binary).split('') as ('0' | '1')[],
  };
}
