import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fade } from '../animations';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isVisible | async" @fade class="loader-bg">
      <div class="app-loader"></div>
      <div *ngIf="status === 'waiting'" class="app-loader__message">Reconnecting...</div>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
  animations: [fade],
})
export class LoaderComponent implements OnInit, OnDestroy {
  public isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status: string;
  private tracker: Tracker.Computation;
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.status = Meteor.status().status;
    this.tracker = Tracker.autorun(() => {
      this.status = Meteor.status().status;
      if (this.status === 'connected') {
        this.hide();
      }
      if (this.status === 'waiting') {
        this.show();
      }
    });
  }

  ngOnDestroy(): void {
    this.tracker.stop();
  }

  public show(): void {
    this.isVisible.next(true);
    this.changeDetector.detectChanges();
  }

  public hide(): void {
    this.isVisible.next(false);
    this.changeDetector.detectChanges();
  }

  public get visible(): boolean {
    return !!this.isVisible.value;
  }
}
