<div
  class="d-flex align-items-center"
  [ngClass]="{ 'justify-content-start': !alignRight, 'justify-content-end': alignRight }"
>
  <img
    *ngIf="sort"
    class="sort--arrow"
    [ngClass]="{ reverse: sort === -1 }"
    [src]="selected ? 'assets/images/icons/sorting arrow.svg' : 'assets/images/icons/sorting.svg'"
    alt=""
  />
  <span><ng-content></ng-content></span>
</div>
