import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {
  UserService,
  User,
  ToastService,
  UserRole,
  AcquirerType,
  AdCategory,
  UserProfile,
  CampaignsService,
} from 'common';
import { environment } from 'environments/environment';
import { take, catchError, tap, switchMap, takeUntil, debounceTime } from 'rxjs/operators';
import { filesUrl } from '../../common/config';
import { MeteorObservable } from 'meteor-rxjs';
import { Subject, Observable } from 'rxjs';
import { PASSWORD_REGEX, PHONE_REGEX, URL_REGEX } from 'common/utils';

interface PartialUser {
  _id: string;
  billingName: string;
  acquirerType?: AcquirerType;
}

const ADVERTISER_PREFERENCE_FIELDS = {
  campaignStatusChange: false,
  noPlayoutsAlert: false,
};

const PUBLISHER_PREFERENCE_FIELDS = {
  autopromotionAdStatusChange: false,
  stationStatusChange: false,
  stationIntegrationProblem: false,
  audienceMonthlyReport: false,
  revenueDailyReport: false,
  revenueMonthlyReport: false,
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit, OnDestroy {
  public processing = false;
  public isAdvertiser = false;
  public isPublisher = false;
  public isAcquirer = false;
  public user: User;
  public userId: string;
  public userFormBasic: FormGroup;
  public userFormPassword: FormGroup;
  public streamProvider: string = '';
  public streamerUrl: string = environment.streamerUrl;
  public acquirers: PartialUser[];
  public referredByAcquirer: PartialUser;
  public logoFile: string;
  public isPasswordEdited = false;
  public excludedAdsTooltip = 'Select Ads by category You do not want to be served to your listeners.';
  private subscriptionDestructor: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private campaignService: CampaignsService,
    private formBuilder: FormBuilder,
    private toast: ToastService
  ) {}

  ngOnInit() {
    MeteorObservable.call('acquirer.getStreamingProviders')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap((acquirers: PartialUser[]) => {
          this.acquirers = acquirers;
        }),
        switchMap(() => this.userService.currentUser$),
        take(1)
      )
      .subscribe(
        (user: User) => {
          this.user = user;
          this.userId = user._id;
          this.isAdvertiser = user.roles.includes(UserRole.Advertiser);
          this.isPublisher = user.roles.includes(UserRole.Publisher);
          this.isAcquirer = user.roles.includes(UserRole.Acquirer);

          if (this.isAcquirer && this.user.hasLogo) {
            this.logoFile = `${filesUrl}${this.user._id}.png`;
          }
          if (this.isPublisher && this.user.referredByAcquirerId) {
            this.referredByAcquirer = this.acquirers.find((a) => a._id === this.user.referredByAcquirerId);
            this.streamProvider = this.referredByAcquirer ? this.referredByAcquirer._id : '';
          }
          this.cancelBasic();
          this.addNotificationFields();

          if (this.isPublisher) {
            this.userFormBasic
              .get('website')
              .setValidators([Validators.required, Validators.pattern(URL_REGEX), Validators.maxLength(100)]);
          }
        },
        (error) => this.toast.error(error)
      );

    this.userFormBasic = this.formBuilder.group({
      first: ['', [Validators.required, Validators.maxLength(100)]],
      last: ['', [Validators.required, Validators.maxLength(100)]],
      phone: ['', [Validators.pattern(PHONE_REGEX), Validators.maxLength(100)]],
      email: [{ value: '', disabled: true }, [Validators.email, Validators.maxLength(100)]],
      website: ['', []],
      stationType: [
        '',
        this.isPublisher ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)],
      ],
      streamProvider: [
        '',
        this.isPublisher ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)],
      ],
      otherStreamProvider: [
        '',
        this.streamProvider === 'other'
          ? [Validators.required, Validators.maxLength(100)]
          : [Validators.maxLength(100)],
      ],
      excludedAdCategories: [],
    });

    this.userFormPassword = this.formBuilder.group({
      oldPassword: ['', []],
      newPassword: ['', []],
    });

    this.userFormPassword.valueChanges.pipe(takeUntil(this.subscriptionDestructor), debounceTime(1)).subscribe(() => {
      const oldPassword: FormControl = <FormControl>this.userFormPassword.get('oldPassword');
      const newPassword: FormControl = <FormControl>this.userFormPassword.get('newPassword');
      const changingPass = !!oldPassword.value || !!newPassword.value;
      if (changingPass) {
        oldPassword.setValidators([Validators.required]);
        newPassword.setValidators([Validators.required, Validators.pattern(PASSWORD_REGEX)]);
      } else {
        oldPassword.setValidators([]);
        newPassword.setValidators([]);
      }
      oldPassword.updateValueAndValidity();
      newPassword.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.subscriptionDestructor.next();
    this.subscriptionDestructor = null;
  }

  public getExcludedAds = (text: string): Observable<AdCategory> => {
    return this.campaignService.getAdCategories(text);
  };

  public getAdsSettingsTooltip(): string {
    return this.excludedAdsTooltip;
  }

  public cancelBasic() {
    this.userFormBasic.reset(this.userFormBasic.value);
    if (this.user) {
      if (!this.user.profile) {
        this.user.profile = {};
      }
      this.streamProvider = this.user.profile.streamProvider;
      let updateFields: Record<string, any> = {
        first: this.user.profile.first || '',
        last: this.user.profile.last || '',
        phone: this.user.profile.phone || '',
        email: this.user.emails[0].address || '',
        website: this.user.profile.website || '',
        stationType: this.user.profile.stationType || '',
        streamProvider: this.user.profile.streamProvider || '',
        otherStreamProvider: this.user.profile.otherStreamProvider || '',
        excludedAdCategories: this.user.excludedAdCategories || [],
      };

      if (this.userFormBasic.get('notificationPreferences')) {
        let preferencesFields = {};
        if (this.isAdvertiser) {
          preferencesFields = ADVERTISER_PREFERENCE_FIELDS;
        } else if (this.isPublisher) {
          preferencesFields = PUBLISHER_PREFERENCE_FIELDS;
        }
        const notificationPreferences = {};
        for (const [fieldName, defaultValue] of Object.entries(preferencesFields)) {
          const currentValue = this.user.notificationPreferences && this.user.notificationPreferences[fieldName];
          notificationPreferences[fieldName] = typeof currentValue !== 'undefined' ? currentValue : defaultValue;
        }
        const notificationFields = {
          notificationPreferences,
        };

        updateFields = {
          ...updateFields,
          ...notificationFields,
        };
      }
      if (this.userFormBasic.get('additionalEmailAddresses')) {
        updateFields = {
          ...updateFields,
          additionalEmailAddresses: this.user.additionalEmailAddresses || [],
        };
      }

      this.userFormBasic.patchValue(updateFields);
    }
  }

  public submitBasic() {
    if (this.userFormBasic.valid) {
      if (this.userFormBasic.get('streamProvider').value !== 'other') {
        this.userFormBasic.patchValue({ otherStreamProvider: '' });
      }
      this.updateProfile();
    }
  }

  public submitPassword() {
    Accounts.changePassword(
      this.userFormPassword.get('oldPassword').value,
      this.userFormPassword.get('newPassword').value,
      (e: Meteor.Error) => {
        if (e) {
          this.toast.error(e.reason);
        } else {
          this.toast.success('Password changed.');
          this.isPasswordEdited = false;
          this.userFormPassword.patchValue({
            oldPassword: '',
            newPassword: '',
          });
          setTimeout(() => this.updateProfile(), 1500);
        }
      }
    );
  }

  public cancelPassword() {
    this.userFormPassword.reset(this.userFormPassword.value);
  }

  public toggle() {
    this.cancelBasic();
    this.cancelPassword();
    this.isPasswordEdited = !this.isPasswordEdited;
  }

  public streamProviderUpdate(value: string) {
    this.streamProvider = value;
    if (value === 'other') {
      this.userFormBasic.get('otherStreamProvider').setValidators([Validators.required, Validators.maxLength(100)]);
      return this.userFormBasic.updateValueAndValidity();
    }
    this.userFormBasic.get('otherStreamProvider').clearValidators();
    return this.userFormBasic.get('otherStreamProvider').updateValueAndValidity();
  }

  public addEmail(url?: string): void {
    // @ts-ignore Angular doesn't allow to override the type AbstractControl
    this.userFormBasic.controls.additionalEmailAddresses.push(
      new FormControl(url || '', [Validators.email, Validators.required])
    );
  }

  public removeEmail(index: number) {
    // @ts-ignore
    this.userFormBasic.controls.additionalEmailAddresses.removeAt(index);
    this.userFormBasic.get('additionalEmailAddresses').markAsDirty();
    this.userFormBasic.get('additionalEmailAddresses').updateValueAndValidity();
  }

  public isInvalid(name: string) {
    // @ts-ignore
    const control = this.userFormBasic.controls.additionalEmailAddresses.controls[name];
    return control.invalid && control.touched;
  }

  private addNotificationFields(): void {
    if (this.isAdvertiser || this.isPublisher) {
      const notificationCheckboxes = {};
      const preferenceFields = this.isAdvertiser ? ADVERTISER_PREFERENCE_FIELDS : PUBLISHER_PREFERENCE_FIELDS;
      const userNotificationPreferences = this.user.notificationPreferences || {};

      for (const [fieldName, defaultValue] of Object.entries(preferenceFields)) {
        const currentValue = userNotificationPreferences[fieldName];
        notificationCheckboxes[fieldName] = typeof currentValue !== 'undefined' ? currentValue : defaultValue;
      }
      this.userFormBasic.addControl('notificationPreferences', this.formBuilder.group(notificationCheckboxes));
      this.userFormBasic.addControl('additionalEmailAddresses', this.formBuilder.array([]));

      if (this.user.additionalEmailAddresses && this.user.additionalEmailAddresses.length) {
        this.user.additionalEmailAddresses.forEach((email) => {
          this.addEmail(email);
        });
      }
    }
  }

  private updateProfile() {
    this.processing = true;
    const value: User & Record<string, any> = { ...this.userFormPassword.value, ...this.userFormBasic.value };
    this.userService
      .updateProfile(value)
      .pipe(takeUntil(this.subscriptionDestructor))
      .subscribe(
        () => {
          if (value['excludedAdCategories']) {
            this.user.excludedAdCategories = value['excludedAdCategories'];
            delete value['excludedAdCategories'];
          }

          if (value['notificationPreferences']) {
            this.user.notificationPreferences = value['notificationPreferences'];
            delete value.notificationPreferences;
          }

          if (value['additionalEmailAddresses']) {
            this.user.additionalEmailAddresses = value['additionalEmailAddresses'];
            delete value.additionalEmailAddresses;
          }

          this.user.profile = value as UserProfile;
          this.cancelBasic();
          this.cancelPassword();
          this.processing = false;
        },
        () => {
          this.processing = false;
        }
      );
  }
}
