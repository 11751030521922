import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastService } from 'common/services';
import { Subscription } from 'rxjs';
import { Toast } from 'common/models/toast';

@Component({
  selector: 'app-toast',
  template: ` <app-toast-item *ngFor="let toast of toasts" [toast]="toast"></app-toast-item> `,
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnDestroy, OnInit {
  toasts: Toast[];
  private _toastSub: Subscription;

  constructor(private _toastService: ToastService, private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._toastSub = this._toastService.toast$.subscribe((toasts: Toast[]) => {
      this.toasts = toasts;
      this._cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this._toastSub) {
      this._toastSub.unsubscribe();
    }
  }
}
