import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from './toast.service';
import { AutopromoteAd, AutopromoteAdStatus } from 'common/models';

@Injectable()
export class AutopromoteAdsService {
  private _toast: ToastService;
  constructor(private _i: Injector, private _router: Router) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  validate(ad: AutopromoteAd): Observable<any> {
    return MeteorObservable.call('autopromoteAd.validate', ad);
  }
  create(ad: AutopromoteAd): Promise<boolean> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('autopromoteAd.create', ad).subscribe(
        (_data: any) => {
          this._toast.success('Autopromotion ad created');
          resolve(true);
          this._router.navigate(['/publisher/autopromote-ads']);
        },
        (error: Meteor.Error) => {
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(false);
        }
      );
    });
  }
  update(ad: AutopromoteAd): Promise<boolean> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('autopromoteAd.update', ad).subscribe(
        (_data) => {
          this._toast.success('Autopromotion ad updated');
          resolve(true);
        },
        (error: Meteor.Error) => {
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(false);
        }
      );
    });
  }
  getStatusesMap(): string[] {
    return Object.keys(AutopromoteAdStatus).map((k) => AutopromoteAdStatus[k]);
  }

  public searchAds(name: string, filter: Object, sort: Object): Observable<AutopromoteAd[]> {
    return MeteorObservable.call('autopromoteAd.searchAds', name, filter, sort);
  }
}
