<div id="form_input_pair" [formGroup]="formGroup">
  <ng-container formArrayName="formInputPairArray">
    <div *ngFor="let control of formInputPairArray.controls; let idx = index" class="row py-1 mx-0">
      <ng-container [formGroupName]="idx">
        <div class="col-sm-12 col-md-6 form-group" [ngClass]="{ 'has-error': isInvalid(idx, inputKey) }">
          <div class="input-group w-100">
            <!-- because https://github.com/angular/angular/issues/13243 -->
            <input *ngIf="inputType === 'number'"
              type="number"
              step="0.01"
              class="form-control"
              [id]="idx + inputKey"
              [placeholder]="inputPlaceholder"
              [formControlName]="inputKey"
            />
            <input *ngIf="inputType !== 'number'"
              #textInput
              type="text"
              class="form-control"
              [id]="idx + inputKey"
              [placeholder]="inputPlaceholder"
              [formControlName]="inputKey"
            />
            <div class="input-group-append" *ngIf="inputTypeText">
              <span class="input-group-text">{{ inputTypeText | uppercase}}</span>
            </div>
            <small class="form-text text-error col-12">
              {{
                control?.controls[inputKey].errors?.required
                  ? inputRequiredErrorMsg
                  : inputValidationErrorMsg
              }}
            </small>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 form-group" [ngClass]="{ 'has-error': isInvalid(idx, optionKey) }">
          <select (change)="updateOptions($event.target.value, idx)" class="form-control" [id]="idx + optionKey" [formControlName]="optionKey">
            <option value="">{{ selectDefaultOption }}</option>
            <option *ngFor="let item of options[idx]" [value]="item[valueKey]">
              {{ displayFormat !== '' ? formatOutput(item) : item[displayKey] }}
            </option>
          </select>
          <small class="form-text text-error col-12">
            {{ selectRequiredErrorMsg }}
          </small>
        </div>

        <div class="col-sm-12 col-md-2">
          <button (click)="removePairFromFormGroup(idx)" class="remove-btn button button__secondary button--small">
            <span>&times;</span>
          </button>
          <button
            *ngIf="idx === 0"
            class="copy-btn button button__secondary button--small"
            [disabled]="!canCopyToAll()"
            (click)="copyToAll()">
            Copy to all
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<div class="col-md-12 py-3">

  <button
    class="button button__primary add-btn"
    [disabled]="!canAddNextGroup()"
    (click)="addPairToFormGroup()">
    Add new
  </button>
</div>
