<span *ngIf="costs">
  <div>Targeting cost: {{ costs.totalTargetingCost | price6 }}
    {{ costs.currency | uppercase }}</div>
  <div *ngIf="formCostPerPlay >= costs.basePrice">
    Total Cost Per Play: {{ calculateCPP() | price6 }}
    {{ costs.currency | uppercase }}
  </div>
</span>
<div *ngIf="audience !== null && audiencePartial !== null && showCalculations">
  Estimated audience:
  <span *ngIf="!!audienceLoading" class="loader"></span>
  <span *ngIf="!audienceLoading">{{
    audience === audiencePartial
      ? (audience | number: '1.0-3')
      : (audience | number: '1.0-3') + ' - ' + (audiencePartial | number: '1.0-3')
  }}</span>
  <span *ngIf="showRecalculeteBtn">
    <app-tooltip [msg]="'Recalculate estimated audience'" #reloadEstimatedAudience></app-tooltip>
    <a [appTooltip]="reloadEstimatedAudience" (click)="calculateAudience(true)">
      <img src="/assets/images/reload.svg" width="15" height="15"/>
    </a>
  </span>
</div>
