import { Component, Input, OnChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { RateLimit } from '../../common';

const NAMES = ['value', 'period'];

@Component({
  selector: 'rate-limiter-form',
  templateUrl: './rate-limiter-form.component.html',
  styleUrls: ['./rate-limiter-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RateLimiterFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RateLimiterFormComponent,
    },
  ],
})
export class RateLimiterFormComponent implements ControlValueAccessor, Validator {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;

  public form: FormGroup;

  private subscriptions: Subscription[] = [];

  onChangeListener: (value: any) => {};
  onTouchedListener: () => {};
  onValidateListener: () => void;

  ngOnInit() {
    const fb = new FormBuilder();
    this.form = fb.group({
      enabled: fb.control(false),
      values: fb.group({
        value: fb.control(0, [Validators.required, Validators.min(1)]),
        period: fb.control('', [Validators.required]),
      }),
    });
    this.subscriptions.push(
      this.form.controls.enabled.valueChanges.subscribe((value) => {
        if (value) {
          this.form.controls.values.enable();
        } else {
          this.form.controls.values.disable();
          this.form.controls.values.reset();
        }
      })
    );
    this.form.valueChanges.subscribe(({ enabled, values }) => {
      const { period, value } = values || {};
      this.onTouchedListener();
      this.onChangeListener({ enabled, value, period });
    });
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  writeValue(values: RateLimit) {
    if (values) {
      const { enabled, value, period } = values;
      this.form.setValue({ enabled, values: { value, period } }, { emitEvent: false });
    }
  }
  registerOnChange(onChangeListener: (value: RateLimit) => {}) {
    this.onChangeListener = onChangeListener;
  }

  registerOnTouched(onTouchedListener: () => {}) {
    this.onTouchedListener = onTouchedListener;
  }
  validate(): ValidationErrors {
    if (this.form.controls.enabled.value) {
      return ['period', 'value'].reduce((errors, fieldName) => {
        const values = this.form.controls.values as FormGroup;
        const field = values.controls[fieldName];
        if (!field.valid) {
          return { ...errors, ...field.errors };
        }
        return errors;
      }, {});
    }
    return {};
  }
  registerOnValidatorChange(onValidateListener: () => void): void {
    this.onValidateListener = onValidateListener;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  isRateLimiterDisable(): boolean {
    return !this.form.controls.enabled.value;
  }
}
