import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-title',
  templateUrl: './table-title.component.html',
  providers: [],
})
export class TableTitleComponent {
  @Input() title: string;
  @Input() sort: 1 | -1;
  @Input() alignRight: boolean = false;
  @Input() selected: boolean = false;
}
