import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit {
  @Input() data: Array<{
    data: number[];
    label: string;
  }>;
  @Input() labels: string[];

  public options: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            suggestedMax: 100,
            beginAtZero: true,
          },
        },
      ],
    },
    responsive: true,
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
  };
  public colors: (any)[] = [
    {
      backgroundColor: 'rgba(27,125,240,0.16)',
      borderColor: '#1B7DF0',
      pointBackgroundColor: '#1B7DF0',
      pointBorderColor: '#1B7DF0',
      pointHoverBackgroundColor: '#1B7DF0',
      pointHoverBorderColor: '#1B7DF0',
    },
  ];
  constructor() {}
  ngOnInit() {}
}
