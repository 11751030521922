import { Injectable, Injector } from '@angular/core';
import { Stream } from 'common/models';
import { ToastService } from './toast.service';
import { MeteorObservable } from 'meteor-rxjs';
import { Router } from '@angular/router';
import { LoaderManager } from 'components/loader';

@Injectable()
export class PublisherService {
  private _toast: ToastService;
  constructor(private _i: Injector, private _router: Router, private loader: LoaderManager) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  /** @deprecated use StreamService */
  updateStream(stream: Stream): Promise<any> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('stream.update', stream).subscribe(
        (_) => {
          this._toast.success('Stream updated');
          resolve(null);
        },
        (error: Meteor.Error) => {
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(error.error);
        }
      );
    });
  }

  /** @deprecated use StreamService instead */
  createStream(stream: Stream, streamToRemove?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loader.show();
      MeteorObservable.call('stream.create', stream, streamToRemove).subscribe(
        (_) => {
          this.loader.hide();
          this._toast.success('Stream created');
          resolve(null);
          this._router.navigate(['/publisher/streams']);
        },
        (error: Meteor.Error) => {
          this.loader.hide();
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(error.error);
        }
      );
    });
  }
}
