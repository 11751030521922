import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { PlayTimesInputComponent } from './play-times-input.component';
import { CommonModule } from 'common';

@NgModule({
  declarations: [PlayTimesInputComponent],
  imports: [CommonModule, FormsModule, TagInputModule],
  exports: [PlayTimesInputComponent],
})
export class PlayTimesInputModule {}
