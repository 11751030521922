export enum CampaignStatus {
  Draft = 'draft',
  Pending = 'pending',
  Active = 'active',
  Paused = 'paused',
  Closed = 'closed',
  Rejected = 'rejected',
  Processing = 'processing',
  Archived = 'archived',
}
