<div class="container profile">
  <h3>Profile</h3>

  <form *ngIf="!isPasswordEdited" [formGroup]="userFormBasic" (ngSubmit)="submitBasic()" class="panel">
    <div style="padding: 30px;">
      <h4>Profile Settings</h4>

      <div class="row">
        <div class="col-12 col-md-4">
          <div class="form-group" [class.error]="userFormBasic.controls.first?.errors">
            <label>
              First name
              <span *ngIf="userFormBasic.controls.first?.errors" appTooltip class="error-info">
                <app-tooltip msg="First name is required."></app-tooltip>
              </span>
            </label>
            <input class="form-control" formControlName="first" type="text" />
          </div>

          <div class="form-group" [class.error]="userFormBasic.controls.last?.errors">
            <label>
              Last name
              <span *ngIf="userFormBasic.controls.last?.errors" appTooltip class="error-info">
                <app-tooltip msg="Last name is required."></app-tooltip>
              </span>
            </label>
            <input class="form-control" formControlName="last" type="text" />
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group" [class.error]="userFormBasic.controls.phone?.errors">
            <label>
              Phone
              <span *ngIf="userFormBasic.controls.phone?.errors" [appTooltip]="phoneTooltip" class="error-info">
                <app-tooltip msg="Wrong phone format." #phoneTooltip></app-tooltip>
              </span>
            </label>
            <input class="form-control" formControlName="phone" type="text" />
          </div>

          <div class="form-group" [class.error]="userFormBasic.controls.email?.errors">
            <label>
              Email
              <span *ngIf="userFormBasic.controls.email?.errors" [appTooltip]="emailTooltip" class="error-info">
                <app-tooltip msg="Wrong email format." #emailTooltip></app-tooltip>
              </span>
            </label>
            <input class="form-control" formControlName="email" type="email"/>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div *ngIf="isPublisher" class="form-group" [class.error]="userFormBasic.controls.website?.errors">
            <label>
              Website
              <span *ngIf="userFormBasic.controls.website?.errors" [appTooltip]="websiteTooltip" class="error-info">
                <app-tooltip msg="Website is required." #websiteTooltip></app-tooltip>
              </span>
            </label>
            <input
              type="text"
              class="form-control"
              id="website"
              placeholder="Website url starting with http:// or https://"
              formControlName="website"
            />
          </div>
        </div>
      </div>

      <div style="margin-top: 30px;" *ngIf="isPublisher">
        <h4>Station Settings</h4>
      </div>

      <div class="row" *ngIf="isPublisher">
        <div class="col-12 col-md-4">
          <div class="form-group" [class.error]="userFormBasic.controls.stationType?.errors">
            <label>
              Type of station
              <span *ngIf="userFormBasic.controls.stationType?.errors" appTooltip class="error-info">
                <app-tooltip msg="Station type need to be selected."></app-tooltip>
              </span>
            </label>
            <select class="form-control" id="stationType" formControlName="stationType">
              <option hidden value="">Select Station Type</option>
              <option value="online">Online</option>
              <option value="fm">FM</option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group" [class.error]="userFormBasic.controls.streamProvider?.errors">
            <label>
              Streaming provider
              <span
                *ngIf="userFormBasic.controls.streamProvider?.errors"
                [appTooltip]="streamProviderTooltip"
                class="error-info"
              >
                <app-tooltip msg="Streaming provider need to be selected." #phoneTooltip></app-tooltip>
              </span>
            </label>
            <select
              class="form-control"
              id="streamProvider"
              formControlName="streamProvider"
              (change)="streamProviderUpdate($event.target.value)"
              [attr.disabled]="referredByAcquirer?.acquirerType === 'streaming-provider' ? true : null"
            >
              <option hidden value="">Select Streaming Provider</option>
              <option value="selfhosted">Self-Hosted</option>
              <option value="sharpstream">SharpStream</option>
              <option value="sourcefabric">Sourcefabric</option>
              <option value="radiosolution">Radiosolution</option>
              <option [value]="a.billingName" *ngFor="let a of acquirers">{{ a.billingName }}</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group" [class.error]="userFormBasic.controls.otherStreamProvider?.errors">
            <label>
              Other Streaming Provider
              <span
                *ngIf="userFormBasic.controls.otherStreamProvider?.errors"
                [appTooltip]="otherStreamProviderTooltip"
                class="error-info"
              >
                <app-tooltip msg="Streaming provider need to be selected." #otherStreamProviderTooltip></app-tooltip>
              </span>
            </label>
            <input
              [attr.disabled]="streamProvider === 'other' ? null : true"
              type="text"
              class="form-control"
              id="otherStreamProvider"
              placeholder="Other streaming provider"
              formControlName="otherStreamProvider"
            />
          </div>
        </div>
      </div>

      <div style="margin-top: 30px;" *ngIf="isPublisher">
        <h4>Ads Settings</h4>
      </div>

      <div class="row" *ngIf="isPublisher">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <div class="form-group">
              <app-form-label
                [msg]="getAdsSettingsTooltip()"
                label="Excluded Ads"
                inputId="excludedAd"
              ></app-form-label>
              <app-tag
                id="excludedAd"
                formControlName="excludedAdCategories"
                placeholder="Excluded ads by category"
                secondaryPlaceholder="Select excluded ad category"
                displayKey="value"
                valueKey="uniqueId"
                mainCategoryKey="isMainSubject"
                [requestAutocompleteItems]="getExcludedAds"
                source="adCategories"
              ></app-tag>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 30px; margin-left: 2px;" *ngIf="isAcquirer">
        <div class="col-12">
          <div class="row">
            <h2 class="detail-field__label">
              Logo
            </h2>
          </div>
          <div class="row">
            <div class="detail-field">
              <div class="detail-field__value" *ngIf="!isArray">
                <app-image-upload [logoFile]="logoFile"></app-image-upload>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 30px;" *ngIf="isAdvertiser || isPublisher">
        <h4>Email notification preferences</h4>
      </div>

      <div
        class="row"
        *ngIf="(isAdvertiser || isPublisher) && userFormBasic.controls.notificationPreferences"
        formGroupName="notificationPreferences"
      >
        <div class="col-12 col-md-4">

          <div class="form-group" *ngIf="isAdvertiser">
            <label class="checkbox" id="campaignStatusChange">
              <input type="checkbox" formControlName="campaignStatusChange" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Campaign status change</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isAdvertiser">
            <label class="checkbox" id="noPlayoutsAlert">
              <input type="checkbox" formControlName="noPlayoutsAlert" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">No playouts alert</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="autopromotionAdStatusChange">
              <input type="checkbox" formControlName="autopromotionAdStatusChange" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Autopromotion Ad Status Change</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="stationStatusChange">
              <input type="checkbox" formControlName="stationStatusChange" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Station Status Change</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="stationIntegrationProblem">
              <input type="checkbox" formControlName="stationIntegrationProblem" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Integration Problem Warning</div>
            </label>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="audienceMonthlyReport">
              <input type="checkbox" formControlName="audienceMonthlyReport" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Audience Monthly Report</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="revenueDailyReport">
              <input type="checkbox" formControlName="revenueDailyReport" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Revenue Daily Report</div>
            </label>
          </div>

          <div class="form-group" *ngIf="isPublisher">
            <label class="checkbox" id="revenueMonthlyReport">
              <input type="checkbox" formControlName="revenueMonthlyReport" />
              <div class="checkbox__indicator"></div>
              <div class="checkbox_label ml-2">Revenue Monthly Report</div>
            </label>
          </div>

        </div>
      </div>

      <div
        class="row mt-1"
        *ngIf="(isAdvertiser || isPublisher) && userFormBasic.controls.additionalEmailAddresses"
        formArrayName="additionalEmailAddresses"
      >
        <div class="col-12 mt-1">
          <h4><small><strong>
            Additional Emails
          </strong></small></h4>
        </div>
        <div class="row py-1 mx-0" *ngFor="let control of userFormBasic.controls.additionalEmailAddresses.controls; let idx = index">
          <div class="col-sm-12 col-md-8 form-group" [ngClass]="{ 'has-error': isInvalid(idx) }">
            <input
              type="text"
              class="form-control"
              [id]="idx + 'email'"
              placeholder="Enter email address"
              [formControl]="userFormBasic.controls.additionalEmailAddresses.controls[idx]"
            />
            <small class="form-text text-error col-12">
              {{
                control && control.errors && control.errors.required
                  ? 'Emial is required'
                  : 'Wrong email format'
              }}
            </small>
          </div>
          <div class="col-sm-12 col-md-1 ml-5 mt-1">
            <button (click)="removeEmail(idx)" class="remove-btn button button__secondary button--small">
              <span>&times;</span>
            </button>
          </div>
        </div>
        <div class="col-12">
          <button (click)="addEmail()">Add</button>
        </div>
      </div>
    </div>

    <div class="panel__footer">
      <span class="button button__secondary" (click)="cancelBasic()" [class.disabled]="userFormBasic.pristine || processing"
        >Cancel</span
      >
      <button
        class="button button__primary ml-1"
        [ngClass]="{'load': processing}"
        [disabled]="userFormBasic.pristine || !userFormBasic.valid || processing"
      >
        Save
      </button>
      <span type="button" class="button button__secondary float_left" (click)="toggle()">Change password</span>
    </div>
  </form>

  <form *ngIf="isPasswordEdited" [formGroup]="userFormPassword" (ngSubmit)="submitPassword()" class="panel">
    <div style="padding: 30px;">
      <h4>Password Settings</h4>
      <div class="col-12 col-md-4">
        <div class="form-group" [class.error]="userFormPassword.controls.oldPassword?.errors">
          <label>
            Current password
            <span
              *ngIf="userFormPassword.controls.oldPassword?.errors"
              [appTooltip]="oldPasswordTooltip"
              class="error-info"
            >
              <app-tooltip msg="Enter current password to set a new one." #oldPasswordTooltip></app-tooltip>
            </span>
          </label>
          <input class="form-control" formControlName="oldPassword" type="password" autocomplete="off" />
        </div>

        <div class="form-group" [class.error]="userFormPassword.controls.newPassword?.errors">
          <label>
            New password
            <span
              *ngIf="userFormPassword.controls.newPassword?.errors"
              [appTooltip]="newPasswordTooltip"
              class="error-info"
            >
              <app-tooltip
                msg="Requires upper and lowercase character, number, must be at least 6 chars long."
                #newPasswordTooltip
              ></app-tooltip>
            </span>
          </label>
          <input class="form-control" formControlName="newPassword" type="password" autocomplete="off" />
        </div>
      </div>
    </div>

    <div class="panel__footer">
      <span class="button button__secondary" (click)="cancelPassword()" [class.disabled]="userFormPassword.pristine"
        >Cancel</span
      >
      <button class="button__primary ml-1" [disabled]="userFormPassword.pristine || !userFormPassword.valid">
        Save Password
      </button>
      <span type="button" class="button button__secondary float_left" (click)="toggle()">Back</span>
    </div>
  </form>
</div>
