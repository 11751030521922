<div class="row list">
  <table *ngIf="(autopromoteAds$ | async)?.length">
    <thead>
      <tr>
        <th class="sort" (click)="changeSort('dateSort')">
          <app-table-title [sort]="sort" [selected]="sortField === 'dateSort'"> last<br />modified at </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('nameSort')">
          <app-table-title [sort]="sort" [selected]="sortField === 'nameSort'">
            ad name
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('autopromoteAdId')">
          <app-table-title [sort]="sort" [selected]="sortField === 'autopromoteAdId'">
            ad #
          </app-table-title>
        </th>
        <th *ngIf="!isPublisher" class="sort" (click)="changeSort('publisherSort')">
          <app-table-title [sort]="sort" [selected]="sortField === 'publisherSort'">
            publisher
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('broadcastNameSort')">
          <app-table-title [sort]="sort" [selected]="sortField === 'broadcastNameSort'">
            station
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('dateRangeSort')">
          <app-table-title [sort]="sort" [selected]="sortField === 'dateRangeSort'">
            date range
          </app-table-title>
        </th>
        <th *ngIf="status === 'all'" class="sort" (click)="changeSort('status')">
          <app-table-title [sort]="sort" [selected]="sortField === 'status'">
            status
          </app-table-title>
        </th>
        <th *ngIf="!isPublisher && status === 'pending'"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ad of autopromoteAds$ | async | paginate: config">
        <td>
          {{ ad.dateSort | amDateFormat: 'DD.MM.YYYY' }}
        </td>
        <td>
          <a [routerLink]="['../', 'autopromote-ads', ad._id]" [title]="ad.name">{{ ad.name }}</a>
        </td>
        <td>{{ ad.autopromoteAdId }}</td>
        <td *ngIf="!isPublisher">
          <a [routerLink]="['../', 'users', ad.publisher._id]" [title]="ad.publisher.billingName">{{
            ad.publisher.billingName
          }}</a>
        </td>
        <td>
          <a [routerLink]="['../', 'streams', ad.stream._id]" [title]="ad.stream.broadcastName">{{
            ad.stream.broadcastName
          }}</a>
        </td>
        <td>{{ ad.startDate | amDateFormat: 'DD.MM.YYYY' }} - {{ ad.endDate | amDateFormat: 'DD.MM.YYYY' }}</td>
        <td *ngIf="status === 'all'">{{ ad.status | capitalize }}</td>
        <td *ngIf="!isPublisher && status === 'pending'">
          <div class="dropdown" dropdown>
            <button class="button button__secondary button--small" dropdownToggle>
              <span>
                Action
              </span>
              <img src="assets/images/icons/dropdown.svg" />
            </button>
            <div *dropdownMenu class="dropdown-menu">
              <a class="dropdown-item" (click)="changeStatus(ad, 'active')">Accept</a>
              <a class="dropdown-item" (click)="changeStatus(ad, 'rejected')">Reject</a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="list__empty" *ngIf="!(autopromoteAds$ | async)?.length">
    <div class="list__empty__inner">
      <img src="assets/images/icons/empty_state.svg" />
      <p *ngIf="isPublisher">
        <span *ngIf="status === 'all'">
          You have no autopromotion ads. Create one
          <a routerLink="new">here</a>.
        </span>
        <span *ngIf="status !== 'all'">
          No autopromotion ads found.
        </span>
      </p>
      <p *ngIf="!isPublisher">No autopromotion ads found.</p>
    </div>
  </div>
</div>
<div class="list__info" *ngIf="!isAutopromoInVast && hasVastIntegrations">
  <span
    >For your type of integration Autopromotion Ads feature is available in paid plans only. Please contact us at
    &nbsp;</span
  >
  <a href="mailto: support@adtonos.com">support@adtonos.com</a>
</div>
<div
  class="row list__pagination justify-content-center"
  *ngIf="(autopromoteAds$ | async)?.length > config.itemsPerPage"
>
  <pagination-template
    #p="paginationApi"
    (click)="pagination(config.currentPage)"
    [id]="config.id"
    (pageChange)="config.currentPage = $event"
  >
    <div class="app-pagination">
      <div class="app-pagination__previous" [class.disabled]="p.isFirstPage()">
        <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
          <img src="assets/images/icons/dropdown.svg" />
        </a>
      </div>
      <div *ngFor="let page of p.pages" class="app-pagination__page" [class.current]="p.getCurrent() === page.value">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span>{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </div>
      <div class="app-pagination__next" [class.disabled]="p.isLastPage()">
        <a *ngIf="!p.isLastPage()" (click)="p.next()">
          <img src="assets/images/icons/dropdown.svg" />
        </a>
      </div>
    </div>
  </pagination-template>
</div>
