import { PricingForms as PricingFormInterface } from 'common/models';

export const PricingForms: PricingFormInterface = {
  base_first: {
    label: 'Minimum CPP Bid for First in a row',
  },
  base_in_block: {
    label: 'Minimum CPP Bid for Bid in block',
  },
  device: {
    label: 'Device',
    experimentalSwitch: true,
  },
  language: {
    label: 'Language',
    experimentalSwitch: true,
  },
  country: {
    label: 'Country',
    experimentalSwitch: true,
  },
  city: {
    label: 'City',
    experimentalSwitch: true,
  },
  geotargeting: {
    label: 'Geotargeting',
    experimentalSwitch: true,
  },
  gender: {
    label: 'Gender',
    experimentalSwitch: true,
  },
  intents: {
    label: 'Intent',
    experimentalSwitch: true,
  },
  brands: {
    label: 'Brand',
    experimentalSwitch: true,
  },
  events: {
    label: 'Event',
    experimentalSwitch: true,
  },
  age: {
    label: 'Age',
    experimentalSwitch: true,
  },
  interests: {
    label: 'Interest',
    experimentalSwitch: true,
  },
  stream: {
    label: 'Stream',
    experimentalSwitch: true,
  },
  remarketing: {
    label: 'Remarketing',
    experimentalSwitch: true,
  },
  playtime_selected: {
    label: 'Playtime',
    experimentalSwitch: true,
  },
  contentLanguage: {
    label: 'Content Language',
    experimentalSwitch: true,
  },
  contentCategory: {
    label: 'Content Category',
    experimentalSwitch: true,
  },
  mobile_segment: {
    label: 'Mobile Segment',
    experimentalSwitch: true,
  },
  nielsen_segment: {
    label: 'Nielsen Segment',
    experimentalSwitch: true,
  },
  audio_product_type: {
    label: 'Audio Channel Types',
    experimentalSwitch: true,
  },
  audio_product_type_article: {
    label: 'Article',
    experimentalSwitch: false,
  },
  audio_product_type_app: {
    label: 'App',
    experimentalSwitch: false,
  },
  audio_product_type_game: {
    label: 'Game',
    experimentalSwitch: false,
  },
  audio_product_type_radio: {
    label: 'Radio',
    experimentalSwitch: false,
  },
  audio_product_type_podcast: {
    label: 'Podcast',
    experimentalSwitch: false,
  },
};
