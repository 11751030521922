import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Region } from 'common/models';

export class CampaignRegionsService {
  constructor(private regions$: Observable<Region[]>) {}
  search(countryCodes: string[], text: string): Observable<Region[]> {
    const pharse = text ? text.toUpperCase() : '';
    const filterRegions = (regions) => {
      if (countryCodes.length) {
        return regions.filter(({ name, country }) => {
          return countryCodes.includes(country) && name.toUpperCase().includes(pharse);
        });
      }
      return [];
    };
    return this.regions$.pipe(switchMap((regions) => of(filterRegions(regions))));
  }

  filter(countryCodes: string[], ids: string[]): Observable<Region[]> {
    const filterRegions = (allRegions) => {
      if (countryCodes.length) {
        return allRegions.filter(({ _id, country }) => {
          return countryCodes.includes(country) && ids.includes(_id);
        });
      }
      return [];
    };
    return this.regions$.pipe(switchMap((regions) => of(filterRegions(regions))));
  }

  fetchNames(ids: string[]): Observable<string[]> {
    return this.regions$.pipe(
      switchMap((regions) => {
        const result = regions.length
          ? regions.filter((region) => ids.includes(region._id)).map(({ name }) => name)
          : ['All'];
        return of(result);
      })
    );
  }
}
