import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss'],
})
export class FormLabelComponent {
  @Input() msg: string;
  @Input() label: string;
  @Input() error: boolean;
  @Input() inputId: string;
  @Input() flag: string;
}
