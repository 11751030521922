<div>
  <div *ngIf="data.length === 0; else dataAvailable">
    No data available
  </div>
  <ng-template #dataAvailable>
    <ul>
      <li *ngFor="let entity of sortedData | slice: 0:displayLimit">
        <b>{{ entity['dspName'] }} {{ entity['countryCode'] }}:</b> {{ entity['counted'] || 0 | number: '1.0-2' }}
      </li>
      <li *ngIf="message === 'More +' && data.length > displayLimit">
        <button class="button button__secondary button--small" (click)="toggleFold()">
          <b>{{ message }}</b>
        </button>
      </li>
      <button
        *ngIf="message === 'Hide -' && data.length >= displayLimit"
        class="button button__secondary button--small"
        (click)="toggleFold()"
      >
        <b>{{ message }}</b>
      </button>
    </ul>
  </ng-template>
</div>
