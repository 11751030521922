import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent implements OnInit {
  @Input() data: Array<{
    data: number[];
    label: string;
  }>;
  @Input() labels: string[];
  @Input() max: number = 100;

  public options: any = {};
  public colors: any[] = [
    {
      backgroundColor: '#1B7DF0',
      borderColor: '#1B7DF0',
      pointBackgroundColor: '#1B7DF0',
      pointBorderColor: '#1B7DF0',
      pointHoverBackgroundColor: '#1B7DF0',
      pointHoverBorderColor: '#1B7DF0',
    },
  ];
  constructor() {}
  ngOnInit() {
    this.options = {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              suggestedMax: this.max,
              beginAtZero: true,
            },
          },
        ],
      },
      responsive: true,
    };
  }
}
