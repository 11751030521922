export type ToastType = 'error' | 'warning' | 'success' | 'info';

export const ToastType: {
  Error: ToastType;
  Warning: ToastType;
  Success: ToastType;
  Info: ToastType;
} = {
  Error: 'error',
  Warning: 'warning',
  Success: 'success',
  Info: 'info',
};

export interface Toast {
  _id: number;
  type: ToastType;
  message: string | number;
}
