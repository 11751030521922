import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, Invoice, CampaignStatus, Campaign, OrderItem, InvoiceStatus, UserRole } from 'common/models';
import { Orders, Settings, Invoices, Users, Campaigns } from 'common/collections';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { LoaderManager } from 'components/loader';
import { ToastService, InvoiceService } from '../../../common/services';
import { StaticStore } from 'app/staticStore.service';

@Component({
  selector: 'app-invoice-order',
  templateUrl: './invoice-order.component.html',
})
export class InvoiceOrderComponent implements OnInit {
  public isOrder: boolean = false;
  public item: Order | Invoice;
  public campaigns: Campaign[] = [];
  public bankAccount: string;
  public accountId: string;
  public isMediaHouse: boolean = false;
  public campaignStatus = CampaignStatus;
  public cities: string;
  public paymentStatus: { key: string; value: string };
  public statusArr: { key: string; value: string }[] = [];
  public backRoute: string = '';
  public isAdmin: boolean = false;
  private id: string;
  private loader: LoaderManager;
  private toast: ToastService;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private i: Injector,
    private staticStore: StaticStore,
    private invoiceService: InvoiceService
  ) {
    this.toast = this.i.get(ToastService);
    this.loader = this.i.get(LoaderManager);
  }

  ngOnInit() {
    const user: any = Meteor.user();
    this.isAdmin = user.roles.includes(UserRole.Admin);

    this.backRoute = this.staticStore.getListingRoute();
    this.statusArr = Object.keys(InvoiceStatus).map((key: string) => ({ key, value: InvoiceStatus[key] }));
    this.route.data.subscribe((data) => {
      this.isOrder = !!data.isOrder;
      this.route.params.subscribe((params) => {
        if (!params.id) {
          return;
        }
        this.id = params.id;
        if (this.isOrder) {
          Orders.find({ $or: [{ _id: this.id }, { campaignId: this.id }] })
            .pipe(first((v: Order[]) => v.length > 0))
            .subscribe((orders: Order[]) => {
              this.item = orders && orders.length ? orders[0] : null;
              this.campaigns = this.getCampaigns();
              switch (this.item.currency) {
                case 'pln':
                  this.bankAccount = Settings.findOne('PL').bankAccountPln;
                  break;
                default:
                  this.bankAccount = Settings.findOne('UK').bankAccountGbp;
              }
              this.subscribeForUser();
            });
        } else {
          Invoices.find({ $or: [{ _id: this.id }, { campaignId: this.id }] })
            .pipe(takeUntil(this.destroy$))
            .subscribe((invoices: Invoice[]) => {
              this.item = invoices && invoices.length ? invoices[0] : null;
              this.paymentStatus = this.statusArr.find((el: { key: string; value: string }) => {
                return el.key.toLowerCase() === invoices[0].status.toLowerCase();
              }) || { key: 'Due', value: 'due' };
              this.campaigns = this.getCampaigns();
              this.subscribeForUser();
            });
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$ = null;
  }

  getCampaigns(): Campaign[] {
    return this.item.orderItems
      .filter((i: OrderItem) => !!i.campaign)
      .map((i: OrderItem) => (this.isOrder ? i.campaign : Campaigns.findOne(i.campaign._id)));
  }

  async changeStatus() {
    this.loader.show();
    this.item = <Invoice>{
      ...this.item,
      ...{ status: `${this.paymentStatus.key[0].toLowerCase()}${this.paymentStatus.key.slice(1)}` },
    };
    try {
      await this.invoiceService.updateInvoice(this.item._id, this.item.status);
      this.loader.hide();
      this.toast.success(`Invoice status updated to ${this.paymentStatus.value}!`);
    } catch (error) {
      this.loader.hide();
      this.toast.error(`Invoice status not updated. Error:  ${error}`);
    }
  }
  async regeneratePdf() {
    if (this.item._id) {
      this.invoiceService.regenerateInvoice(this.item._id).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  private subscribeForUser() {
    const user = Users.findOne(
      this.item.advertiserId || (<Invoice>this.item).publisherId || (<Invoice>this.item).acquirerId
    );
    this.accountId = user.accountId;
    this.isMediaHouse = user.isMediaHouse;
    if (!this.item) {
      if (this.isOrder) {
        this.router.navigate(['/advertiser/orders']);
      } else {
        this.router.navigate(['/advertiser/billings']);
      }
    }
  }
}
