import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'common/classes';

type GA4 = {
  code: string,
  id: string,
}

@Component({
  selector: 'app-modal-integration-text',
  styleUrls: ['./modal-integration-text.component.scss'],
  templateUrl: './modal-integration-text.component.html',
})
export class ModalIntegrationText extends ModalComponent implements OnInit {
  public trackingServer: string;
  public pixelId: string;
  public param: boolean;
  public text1: string;
  public universalCode: string;
  public ga4: GA4 = {
    code: '',
    id: '',
  }

  ngOnInit() {
    const param = this.param ? 'product_type={{ productType }}' : '';
    this.text1 = `<img src="${this.trackingServer}atr/${this.pixelId}/px.gif${this.param ? '?' + param : ''}" width="0" height="0"/>`;
    this.universalCode = `<script>
  ga(function(tracker) {
    if (!tracker && ga.getAll().length) tracker = ga.getAll()[0];
    var clientId = !!tracker ? tracker.get('clientId') : '';${this.param ? '\n    var productType = {{ url }};' : ''}
    var img = document.createElement('img');
    img.src = '${this.trackingServer}atr/${this.pixelId}/px.gif?${
      this.param
      ? param.replace(/\{|}|\s/g, '').replace('=', '=\' + ') + ' + \'&'
      : '' }extid=\' + clientId;
    img.width = 0;
    img.height = 0;
    document.body.appendChild(img);
  });
</script>`;
  }

  close() {
    this._closeModal(false);
  }
  changeGa4Id(id: string) {
    if (id) {
      const param = this.param ? 'product_type={{ productType }}' : '';
      this.ga4.code = `<script>
      (function(){
        window.dataLayer = window.dataLayer || [];function gtag(){window.dataLayer.push(arguments); }
        gtag('get', '${id}', 'client_id', function(clientId) {
          ${this.param ? '    var productType = {{ url }};\n' : ''}var img = document.createElement('img');
          img.src = '${this.trackingServer}atr/${this.pixelId}/px.gif?${
            this.param
            ? param.replace(/\{|}|\s/g, '').replace('=', '=\' + ') + ' + \'&'
            : '' }extid=\' + clientId;
          img.width = 0;
          img.height = 0;
          document.body.appendChild(img);
        });
      })();
</script>`;
    } else {
      this.ga4.code ='';
    }
  }
}
