export enum RateLimitPeriod {
  'second',
  'minute',
  'hour',
  'day',
  'none',
}

export interface RateLimit {
  enabled: boolean;
  value: number;
  period: RateLimitPeriod;
}
