export type AcquirerType = 'streaming-provider' | 'media-house' | 'individual';

export const AcquirerType: {
  StreamingProvider: AcquirerType;
  MediaHouse: AcquirerType;
  Individual: AcquirerType;
} = {
  StreamingProvider: 'streaming-provider',
  MediaHouse: 'media-house',
  Individual: 'individual',
};
