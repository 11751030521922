import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { IntegrationCheck, ModalItem, Stream } from 'common/models';
import { ModalDateRangeComponent } from 'components/modal-date-range';
import { ModalConfirmComponent } from 'components/modal-confirm';
import { ModalIntegrationsReportComponent } from 'components/modal-integrations-report';
import { ModalIntegrationsStatusComponent } from 'components/modal-integrations-status';
import { ModalIntegrationText } from 'components/modal-integration-text';
import { ModalCheckPixelComponent } from 'components/modal-check-pixel';
import { ModalStreamApproveConfirmationComponent } from 'components/modal-stream-approve-confirmation';
import { ModalStreamApproveConfirmationDuplicateComponent } from 'components/modal-stream-approve-confirmation-duplicate';

const REPORT_CONFIRM_TEXT =
  'Generating a report can take up to 30 minutes. We will send it to your email as soon as it is ready.';

type IntegrationStatusRequest = {
  streamId: string;
  streamName: string;
  integrationType: string;
  integration: IntegrationCheck;
  platform: any;
};
@Injectable()
export class ModalService {
  private _modals: ModalItem[] = [];
  constructor(private _modalService: BsModalService) {}

  public openDateRangePicker(
    data: Date[],
    dayOffset?: number,
    startDate: moment.Moment = moment()
  ): Promise<ModalItem> {
    const modal: ModalItem = this._openModal(ModalDateRangeComponent, {
      data,
      dayOffset,
      startDate,
    });

    return this._resolve(modal);
  }

  public openDatePicker(date: Date, dayOffset?: number, startDate: moment.Moment = moment()): Promise<Date> {
    const modal: ModalItem = this._openModal(ModalDateRangeComponent, {
      data: [date],
      dayOffset,
      startDate,
      single: true,
    });
    return this._resolve(modal).then((dates: Date[]) => {
      return dates[0];
    });
  }

  public openIntegrationsReportModal(data: Date[]): Promise<any> {
    const modal: ModalItem = this._openModal(ModalIntegrationsReportComponent);
    modal.ref.content.data = data;
    return this._resolve(modal);
  }

  public openReportGenerateConfirm(text: string = REPORT_CONFIRM_TEXT) {
    return this.openConfirmModal({ text });
  }
  public openConfirmModal({ text }) {
    const modal: ModalItem = this._openModal(ModalConfirmComponent);
    modal.ref.content.text = text;
    return this._resolve(modal);
  }

  public openIntegrationTextModal(trackingServer: string, pixelId: string, param: string | null): Promise<any> {
    const modal: ModalItem = this._openModal(
      ModalIntegrationText,
      { trackingServer, pixelId, param },
      'integration-modal-dialog'
    );
    return this._resolve(modal);
  }

  public openCheckPixelModal(pixelId: string) {
    const modal: ModalItem = this._openModal(ModalCheckPixelComponent, { pixelId });
    return this._resolve(modal);
  }
  public openStreamIntegrationStatus(payload: IntegrationStatusRequest) {
    const modal: ModalItem = this._openModal(ModalIntegrationsStatusComponent, payload);
    return this._resolve(modal);
  }
  public openStreamApproveConfirmation(payload: Stream) {
    const modal: ModalItem = this._openModal(ModalStreamApproveConfirmationComponent, { stream: payload });
    return this._resolve(modal);
  }
  public openStreamApproveConfirmationDuplicate(payload: Stream) {
    const modal: ModalItem = this._openModal(ModalStreamApproveConfirmationDuplicateComponent, { stream: payload });
    return this._resolve(modal);
  }

  public show<T, S>(comp: T, state: S, extraClass?: string) {
    const modal: ModalItem = this._openModal(comp, state, extraClass);
    return this._resolve(modal);
  }
  private _openModal(comp: any, initialState?: Object, extraClass?: string): ModalItem {
    const _id = new Date().getMilliseconds();
    const ref: BsModalRef = this._modalService.show(comp, {
      ignoreBackdropClick: true,
      initialState,
      class: extraClass,
    });
    const modalItem: ModalItem = { _id, ref };
    this._modals.push(modalItem);
    return modalItem;
  }

  private _resolve(modal: ModalItem): Promise<any> {
    return new Promise<any>((resolve) => {
      modal.ref.content.resolve.subscribe((data: any) => {
        this._closeModal(modal);
        resolve(data);
      });
    });
  }

  private _closeModal(modal: ModalItem) {
    modal.ref.hide();
    this._modals = this._modals.filter((item: ModalItem) => item._id !== modal._id);
  }
}
