import { Injectable } from '@angular/core';
import { AdvertiserPixelDto } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class AdvertiserPixelsService {
  constructor() {}

  public fetch(advertiserId: string, options: Record<string, number>): Observable<AdvertiserPixelDto[]> {
    return MeteorObservable.call('pixel.fetch', advertiserId, options);
  }

  public fetchPixel(record: Record<string, any>): Observable<AdvertiserPixelDto> {
    return MeteorObservable.call('pixel.fetchPixel', record);
  }

  public create(pixelDTO: Partial<AdvertiserPixelDto>): Promise<Partial<AdvertiserPixelDto>> {
    return new Promise((resolve, reject) => {
      try {
        const pixel = MeteorObservable.call('pixel.create', pixelDTO).toPromise();
        resolve(pixel);
      } catch (error) {
        reject(error);
      }
    });
  }

  public update(pixel: Partial<AdvertiserPixelDto>): Promise<Partial<AdvertiserPixelDto>> {
    return new Promise((resolve, reject) => {
      try {
        const pixelData = MeteorObservable.call('pixel.update', pixel).toPromise();
        resolve(pixelData);
      } catch (error) {
        reject(error);
      }
    });
  }
}
