import { Injectable, Injector } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from './toast.service';

@Injectable()
export class AdminService {
  private _toast: ToastService;

  constructor(private _i: Injector) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  updatePricings(pricings: any) {
    MeteorObservable.call('pricing.update', pricings).subscribe(
      (data) => {
        this._toast.success('Pricings updated');
      },
      (error: Meteor.Error) => {
        if (error.reason) {
          this._toast.error(error.reason);
        }
        if (error.error) {
          if (typeof error.error === 'string') {
            this._toast.error(error.error);
          } else {
            Object.keys(error.error).forEach((k) => {
              this._toast.error(error.error[k]);
            });
          }
        }
      }
    );
  }
}
