<div class="container">
  <h3>Reports</h3>
  <div class="panel">
    <div class="panel__content">
      <app-form-label label="Date range" inputId="dateRange"></app-form-label>
      <app-date-picker [dayOffset]="-10000" [(dates)]="dates"></app-date-picker>
    </div>
    <div class="panel__footer">
      <span (click)="export()" class="button" [class.disabled]="!dates || dates.length < 1">Export CSV</span>
    </div>
  </div>
</div>
