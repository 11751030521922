import { MeteorObservable } from 'meteor-rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Campaign } from 'common/models';

export interface MeasureAudienceData {
  exact: number;
  partial: number;
}

export interface AudienceParameters {
  deviceTypes: string[];
  languages: string[];
  countryCodes: string[];
  cityIds: string[];
  genders: string[];
  ageRangeIds: string[];
  interestIds: string[];
  intentIds: string[];
  brandIds: string[];
  eventIds: string[];
  stationsIds: string[];
}

@Injectable()
export class AudienceService {
  measureAudience(campaign: Campaign): Observable<MeasureAudienceData> {
    return MeteorObservable.call('audience.stats', campaign);
  }
}
