import { Injectable, Injector } from '@angular/core';
import { Stream, FloorPrice, BroadcastType, ContentCategory, StreamDuplicate } from 'common/models';
import { ToastService } from './toast.service';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

type FloorPricesRecords = {
  _id: string;
  publisherId: string;
  floorPrices: FloorPrice[];
};

@Injectable()
export class StreamService {
  private _toast: ToastService;

  constructor(private _i: Injector) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  /** @deprecated use updateStream instead */
  public update(stream: Stream): Promise<any> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('stream.update', stream).subscribe(
        (_) => {
          this._toast.success('Stream updated');
          resolve(null);
        },
        (error: Meteor.Error) => {
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(error.error);
        }
      );
    });
  }

  public integrationCheck(streamIdModal: string, integrationTypeModal: string, platformTypeSend: string[]) {
    return new Promise(async (resolve, reject) => {
      try {
        await MeteorObservable.call(
          'stream.integrationCheck',
          streamIdModal,
          integrationTypeModal,
          platformTypeSend
        ).toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public searchStreams(name: string, filter: Object, sort: Object): Observable<Stream[]> {
    return MeteorObservable.call('stream.search', name, filter, sort);
  }

  public createOrUpdeateBySteps(stream: Stream): Promise<Stream> {
    return new Promise(async (resolve, reject) => {
      try {
        const updatedStream = await MeteorObservable.call('stream.createOrUpdateByStep', stream).toPromise();
        resolve(updatedStream);
      } catch (error) {
        reject(error);
      }
    });
  }

  public fetchFloorPrices(streamId: string): Observable<FloorPricesRecords> {
    return MeteorObservable.call('stream.fetchFloorPrices', streamId);
  }

  public updateFloorPrices(streamId: string, floorPrices: FloorPrice[]) {
    return MeteorObservable.call('stream.updateFloorPrices', streamId, floorPrices);
  }

  public fetchStreamById(streamId: string): Observable<Stream> {
    return MeteorObservable.call('stream.fetchStreamById', streamId);
  }

  public updateStream(stream: Stream): Observable<void> {
    return MeteorObservable.call('stream.update', stream);
  }

  public createStream(stream: Stream, streamToRemove?: string): Observable<void> {
    return MeteorObservable.call('stream.create', stream, streamToRemove);
  }

  public fetchBroadcastTypes(): Observable<BroadcastType[]> {
    return MeteorObservable.call('stream.fetchBroadcastTypes');
  }

  public fetchContentCategories(): Observable<ContentCategory[]> {
    return MeteorObservable.call('stream.fetchContentCategories');
  }
  public generateAdminStreamsReport(): Observable<void> {
    return MeteorObservable.call('stream.generateAdminReport');
  }
  public fetchDuplicates(streamId: string): Observable<StreamDuplicate[]> {
    return MeteorObservable.call('stream.fetchDuplicates', streamId);
  }
  public isDomainDuplicate(domain: string): Observable<boolean> {
    return this.isDuplicate('domain', [domain]);
  }
  public isBundleDuplicate(storeUrls: string[]): Observable<boolean> {
    return this.isDuplicate('bundle', storeUrls);
  }
  private isDuplicate(type: 'domain' | 'bundle', entities: string[]): Observable<boolean> {
    return MeteorObservable.call('stream.isDuplicate', type, entities);
  }
}
