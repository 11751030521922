import { IntegrationType } from 'common/models/integration-type';
import { Stream } from 'common/models/stream';
import { environment } from 'environments/environment';

/**
 * Constructs the output URL for a given stream based on its integration type.
 *
 * @param {Stream} stream - The stream for which the output URL is generated.
 * @returns {string} The output URL for the stream.
 */
export function getStreamOutputUrl(stream: Stream): string {
  switch (stream.integrationType) {
    case IntegrationType.Vast:
      return `${environment.exchangeVastAdapterUrl}xml/${stream._id}/vast.xml`;
    case IntegrationType.OpenRTB:
      return `${environment.exchangeUrl}bid/${stream.publisherId}/${stream._id}`;
    case IntegrationType.Replacement:
      return environment.streamerUrl + stream.streamOutput;
    case IntegrationType.Playlist:
      return environment.streamerUrl + stream.streamOutput;
    default:
      '';
  }
}
