import { Injectable } from '@angular/core';
import { UserRole, CampaignStatus, AutopromoteAdStatus, DspNetwork, OrderStatus, RedeemRequestStatus } from 'common';
import { Subject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { IntegrationType, StreamStatus, ISOLanguage, SupplyPackage } from 'common/models';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { DspStatus } from 'common/models/dsc-config';

export interface IListingScopes {
  users?: UserRole | 'all';
  campaigns?: CampaignStatus | 'all';
  dspCampaigns?: DspNetwork | 'all';
  dspConfig?: DspStatus | 'all';
  packages?: 'all';
  autopromoteAds?: AutopromoteAdStatus | 'all';
  invoices?: UserRole | 'all';
  orders?: OrderStatus | 'all';
  redeemRequests?: RedeemRequestStatus | 'all';
  stationIntegrationTypes?: IntegrationType | 'all';
  stationStatuses?: StreamStatus | 'all';
}

const defaultScopes: IListingScopes = {
  users: 'all',
  campaigns: 'all',
  dspCampaigns: 'all',
  dspConfig: 'all',
  packages: 'all',
  autopromoteAds: 'all',
  invoices: 'all',
  orders: 'all',
  redeemRequests: 'all',
  stationIntegrationTypes: 'all',
  stationStatuses: StreamStatus.ActiveOrPending,
};

/**
 * Static store serves as a service which can hold static state of the app across routes.
 */
@Injectable({
  providedIn: 'root',
})
export class StaticStore {
  public languageList: ReplaySubject<ISOLanguage[]>;
  public clearSearch = new Subject();

  /**
   * Filter scopes for listings across app and routes.
   */
  public listingScopes: BehaviorSubject<IListingScopes> = new BehaviorSubject(defaultScopes);
  public dashboardReroute: boolean = true;

  private listingRoute = '';
  private generalSearch = '';
  private startDate = '';
  private endDate = '';
  private sortOrder: 1 | -1 = -1;
  private sortLabel = '';
  private sortOrderDSP: 1 | -1 = 1;
  private sortLabelDSP = '';
  private page = 1;
  private pageDSP = 1;
  private dateCleared = false;

  constructor(private http: HttpClient) {}

  public setListingRoute(route: string): void {
    this.listingRoute = route;
  }

  public setDateCleared(): void {
    this.dateCleared = true;
  }

  public setDateSet(): void {
    this.dateCleared = false;
  }

  public setGeneralSearch(search: string): void {
    this.generalSearch = search;
  }

  public setStartDate(date: string): void {
    this.startDate = date;
  }

  public setEndDate(date: string): void {
    this.endDate = date;
  }

  public setSortOrder(sort: 1 | -1): void {
    this.sortOrder = sort;
  }

  public setSortLabel(label: string): void {
    this.sortLabel = label;
  }

  public setSortOrderDSP(sort: 1 | -1): void {
    this.sortOrderDSP = sort;
  }

  public setSortLabelDSP(label: string): void {
    this.sortLabelDSP = label;
  }

  public setDashboardReroute(request: boolean): void {
    this.dashboardReroute = request;
  }

  public setPage(page: number): void {
    this.page = page;
  }

  public setPageDSP(page: number): void {
    this.pageDSP = page;
  }

  public isDateCleared(): boolean {
    return this.dateCleared;
  }

  public getListingRoute() {
    return this.listingRoute;
  }

  public getGeneralSearch(): string {
    return this.generalSearch;
  }

  public getStartDate(): string {
    return this.startDate;
  }

  public getEndDate(): string {
    return this.endDate;
  }

  public getSortOrder(): 1 | -1 {
    return this.sortOrder;
  }

  public getSortLabel(): string {
    return this.sortLabel;
  }

  public getSortOrderDSP(): 1 | -1 {
    return this.sortOrderDSP;
  }

  public getSortLabelDSP(): string {
    return this.sortLabelDSP;
  }

  public getDashboardReroute(): boolean {
    return this.dashboardReroute;
  }

  public getPage(): number {
    return this.page;
  }

  public getPageDSP(): number {
    return this.pageDSP;
  }

  public getLanguageList(): Observable<ISOLanguage[]> {
    return this.languageList.asObservable();
  }

  /**
   * clear listing scope
   */

  public generalClear<T extends keyof IListingScopes>(type: T, secondaryType?: T): void {
    this.listingScopes.next(defaultScopes);
    this.basicClear();
  }

  public clearAiredAds(): void {
    this.startDate = null;
    this.endDate = null;
    this.basicClear();
  }

  public basicClear() {
    this.sortLabel = '';
    this.sortOrder = -1;
    this.sortLabelDSP = '';
    this.sortOrderDSP = -1;
    this.generalSearch = '';
    this.page = 1;
    this.pageDSP = 1;
    this.startDate = null;
    this.endDate = null;
    this.dateCleared = false;
    this.clearSearch.next(true);
  }

  public initLanguageList() {
    this.languageList = new ReplaySubject<ISOLanguage[]>();
    this.http
      .get<ISOLanguage[]>('../assets/JSON/lang.json')
      .pipe(take(1))
      .subscribe((langs) => this.languageList.next(langs));
  }
}
