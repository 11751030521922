<div class="checkbox-group-form form-group" [class.inline]="inline" [class.has-error]="isInvalid()">
  <label class="main">{{ label }}<span class="required" *ngIf="required">*</span></label>
  <div class="items">
    <div class="item" *ngFor="let item of localOptions">
      <label class="checkbox" for="{{item.id}}">
        <input class="checkbox__indicator" type="checkbox" id="{{item.id}}" [ngModel]="isChecked(item.value)" [value]="item.value" (change)="onToggle($event)" />
        <div class="checkbox__indicator"></div>
        <span class="checkbox__label">{{ item.label }}</span>
      </label>
    </div>
  </div>
  <span *ngIf="isInvalid()">
    <small class="form-text text-error">
      {{ label }} is required
    </small>
  </span>
</div>
