export enum TagSource {
  Brands = 'brands',
  Events = 'events',
  Countries = 'countries',
  Cities = 'cities',
  Publishers = 'publishers',
  PlayTimes = 'playTimes',
  Streams = 'streams',
  AgeRanges = 'ageRanges',
  Interests = 'interests',
  Intents = 'intents',
  Simple = 'simple',
  Languages = 'languages',
  Genders = 'genders',
  ContentCategories = 'contentCategories',
  Streamers = 'streamers',
  Campaigns = 'campaigns',
  AdCategories = 'adCategories',
}
