import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import {
  Campaign,
  Streams,
  Languages,
  Countries,
  Interests,
  AgeRanges,
  Intents,
  Brands,
  Stream,
  Language,
  Country,
  AgeRange,
  Interest,
  Intent,
  Brand,
  Price6Pipe,
  Price2Pipe,
  TimeRangeDay,
  Events,
  Event,
  City,
  AdCategories,
  Networks,
  CampaignsService,
  NielsenSegment,
  Gender,
  Genders,
} from 'common';
import { AdCategory, ISOLanguage, Network } from 'common/models';
import { citiesWithRegionsFormat } from 'common/utils/formats';

import { capitalize, groupBy } from 'lodash';
import { MeteorObservable } from 'meteor-rxjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GetContentCategories } from 'common/utils';
import { StaticStore } from 'app/staticStore.service';

@Component({
  selector: 'app-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss'],
  providers: [Price6Pipe, Price2Pipe],
})
export class OrderItemDetailsComponent implements OnInit {
  @Input() public campaign: Campaign;
  @Input() public showDetailButton: boolean = false;
  public adCategory: AdCategory;
  public networkData: Network;
  public displayLength: number = 300;
  public displayLimit: number = 300;
  public adLanguage$: Observable<string>;
  public cities$: Observable<string>;
  public contentLanguage$: Observable<string>;
  public contentCategory$: Observable<string>;
  public regions$: Observable<string>;
  public nielsenSegments$: BehaviorSubject<string> = new BehaviorSubject('Loading...');

  public isoLanguage$: Observable<ISOLanguage[]>;
  private streamsData: Stream[];
  private languagesData: Language[];
  private countriesData: Country[];
  private agesData: AgeRange[];
  private gendersData: Gender[];
  private interestsData: Interest[];
  private intentsData: Intent[];
  private brandsData: Brand[];
  private eventsData: Event[];

  constructor(
    private price6Pipe: Price6Pipe,
    private price2Pipe: Price2Pipe,
    private staticStore: StaticStore,
    private campaignService: CampaignsService
  ) {}

  public ngOnInit() {
    this.streamsData = Streams.find({ _id: { $in: this.campaign.streamIds } }).fetch();
    this.languagesData = Languages.find({ _id: { $in: this.campaign.languages || [] } }).fetch();
    this.countriesData = Countries.find({ code: { $in: this.campaign.countryCodes || [] } }).fetch();
    this.agesData = AgeRanges.find({ _id: { $in: this.campaign.ageRangeIds || [] } }).fetch();
    this.gendersData = Genders.find({ _id: { $in: this.campaign.genders || [] } }).fetch();
    this.interestsData = Interests.find({ _id: { $in: this.campaign.interestIds || [] } }).fetch();
    this.intentsData = Intents.find({ _id: { $in: this.campaign.intentIds || [] } }).fetch();
    this.brandsData = Brands.find({ _id: { $in: this.campaign.brandIds || [] } }).fetch();
    this.eventsData = Events.find({ _id: { $in: this.campaign.eventIds || [] } }).fetch();
    this.adCategory = AdCategories.findOne({ _id: this.campaign.adCategory });
    this.networkData = Networks.findOne({ _id: this.campaign.networkId });
    this.isoLanguage$ = this.staticStore.getLanguageList().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.cities$ = MeteorObservable.call<City[]>('city.getByIds', this.campaign.cityIds || []).pipe(
      map(this.setLimit.bind(this)),
      map((cities) => citiesWithRegionsFormat(cities as City[], !!this.campaign.regionsNames?.length)),
      startWith('Loading...')
    );
    this.adLanguage$ = this.isoLanguage$.pipe(
      switchMap((langs) => {
        const filteredLangs = langs
          .filter((lng) => (this.campaign.adLanguage || '') === lng.ISO639_1)
          .map((lng) => `[${lng.ISO639_1.toUpperCase()}] ${lng.name}`);
        return of(filteredLangs.length ? filteredLangs.join('') : 'All');
      })
    );
    this.contentLanguage$ = this.isoLanguage$.pipe(
      switchMap((langs) => {
        const filteredLangs = langs
          .filter((lng) => (this.campaign.contentLanguage || []).includes(lng.ISO639_1))
          .map((lng) => `[${lng.ISO639_1.toUpperCase()}] ${lng.name}`);
        return of(filteredLangs.length ? filteredLangs.join(', ') : 'All');
      })
    );
    this.contentCategory$ = GetContentCategories().pipe(
      switchMap((contentCategories) => {
        const filteredCategories = contentCategories
          .filter((cat) => (this.campaign.contentCategory || []).includes(cat.uniqueId))
          .map((cat) => cat.name.trim());
        return of(filteredCategories.length ? filteredCategories.join(', ') : 'All');
      })
    );
    if (this.campaign.nielsenSegment?.length) {
      this.campaignService
        .getNielsenSegmentsByIds(this.campaign.nielsenSegment)
        .pipe(
          take(1),
          switchMap((segments: NielsenSegment[]) => [segments.map((segment) => segment.name).join(', ')])
        )
        .subscribe(this.nielsenSegments$);
    } else {
      this.nielsenSegments$.next('All');
    }

    this.regions$ = this.campaignService
      .getRegions()
      .fetchNames(this.campaign.regionsNames)
      .pipe(map((regions) => regions.join(', ')));
  }

  public get DailyBudget(): string {
    return this.campaign.isBudgetManual
      ? `Manual (${this.campaign.manualBudget} ${this.campaign.currency.toUpperCase()} per day)`
      : this.campaign.budgetType.substring(0, 1).toUpperCase() + this.campaign.budgetType.substring(1);
  }

  public getFieldsWithExclude(field: string, exclude: boolean): string {
    let result = '';
    if (!field && !exclude) result = 'All';
    else if (!field && exclude) result = 'None';
    else if (field && !exclude) result = `${field}`;
    else if (field && exclude) result = `${field} (Exclude)`;
    return result;
  }

  public get name(): string {
    return this.campaign.name;
  }

  public get domain(): string {
    return this.campaign.domain;
  }

  public get dealId(): string {
    return this.campaign.dealId;
  }

  public get buyerSeat(): string {
    return this.campaign.buyerSeat;
  }

  public get campaignType(): string {
    return this.getCampaignType(this.campaign.length);
  }

  public get devices(): string {
    return (this.campaign.deviceTypes || []).map(capitalize).join(', ');
  }

  public get streams(): string {
    return this.streamsData.map((stream) => stream.broadcastName).join(', ');
  }

  public get languages(): string {
    return this.languagesData.map((lang) => lang.name).join(', ');
  }

  public get countries(): string {
    return this.countriesData.map((country) => country.name).join(', ');
  }
  public get remarketing(): string {
    return this.campaign.remarketing ? 'Yes' : 'No';
  }

  public get productType(): string {
    return this.campaign.productType || 'All';
  }

  public get genders(): string {
    return this.gendersData.map((g) => capitalize(g.name)).join(', ');
  }

  public get ages(): string {
    return this.agesData.map((age) => age.name).join(', ');
  }

  public get interests(): string {
    return this.interestsData.map((interest) => interest.name).join(', ');
  }

  public get intents(): string {
    return this.intentsData.map((intent) => intent.name).join(', ');
  }

  public get brands(): string {
    return this.brandsData.map((brand) => brand.name).join(', ');
  }

  public get events(): string {
    return this.eventsData.map((event) => event.name).join(', ');
  }

  public get network(): string {
    return this.networkData.name;
  }

  public get audioProductTypes(): string {
    return this.campaign.audioProductTypes.map((type) => `${type.charAt(0).toUpperCase()}${type.slice(1)}`).join(', ');
  }

  public get dateRanges(): string {
    const startDate =
      this.campaign.startDate instanceof Date ? this.campaign.startDate : new Date(this.campaign.startDate);
    const endDate = this.campaign.endDate instanceof Date ? this.campaign.endDate : new Date(this.campaign.endDate);

    return `${startDate.toDateString()} - ${endDate.toDateString()}`;
  }

  public get playoutCapping(): string {
    return this.campaign.playoutCapping ? 'Up to ' + this.campaign.playoutCapping : 'No limit';
  }

  public get totalCostPerPlay(): string {
    const totalCostPerPlay =
      this.campaign.totalCostPerPlay || this.campaign.totalTargetingCost + this.campaign.costPerPlay;
    const price = this.price6Pipe.transform(totalCostPerPlay);
    return price + ' ' + this.campaign.currency.toUpperCase();
  }

  public get groupedPlayTimes(): { day: string; timeRanges: string }[] {
    const playTimes = groupBy(
      this.campaign.playTimes.map((time) => ({
        ...time,
        day: TimeRangeDay[time.day],
      })),
      'day'
    );

    return Object.keys(playTimes).map((day) => {
      const timeRanges = playTimes[day];
      return {
        day,
        timeRanges: timeRanges.map((time) => `${time.from}-${time.to}`).join(', '),
      };
    });
  }

  public get totalBudget(): string {
    return this.price2Pipe.transform(this.campaign.totalBudget) + ' ' + this.campaign.currency.toUpperCase();
  }

  public get expectedAudience(): number {
    return this.campaign.expectedAudience || 0;
  }

  public get expectedPlayouts(): number {
    const tcpp = this.campaign.totalCostPerPlay
      ? this.campaign.totalCostPerPlay
      : this.campaign.costPerPlay + this.campaign.totalTargetingCost;
    return Math.floor(this.campaign.totalBudget / tcpp || 0);
  }

  public resize(toggler: boolean) {
    if (this.displayLimit > 300) {
      this.displayLength = toggler ? this.displayLimit : 300;
    }
  }
  /**
   * Return string representation of campaign type, based on campaign length.
   */
  private getCampaignType(campaignLength: number): string {
    const campaignLengthMap = {
      8: 'First in a row',
      15: 'First in a row',
      20: 'Bid in block',
      30: 'Bid in block',
    };

    return campaignLengthMap[campaignLength];
  }

  private setLimit(cities: City[]): City[] {
    const localLimit = cities.join(', ').length;
    this.displayLimit = localLimit > this.displayLimit ? localLimit : this.displayLimit;
    return cities;
  }
}
