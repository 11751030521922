import { throwError as observableThrowError, BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { UserRole } from 'common/models';
import { AccountStatus, Referral, User, UserProfile } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { LoaderManager } from 'components/loader';
import { tap, catchError, take } from 'rxjs/operators';
import { IListingScopes, StaticStore } from 'app/staticStore.service';

@Injectable()
export class UserService {
  public isAccountActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public displayLoginWindow = false;
  private userLoggedInSubject: Subject<void> = new Subject<void>();
  private toast: ToastService;
  private loader: LoaderManager;
  private _currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  constructor(
    private i: Injector,
    private router: Router,
    private location: Location,
    private staticStore: StaticStore
  ) {
    this.toast = this.i.get(ToastService);
    this.loader = this.i.get(LoaderManager);
    this.watchCurrentUser();
    this.currentUser$.subscribe((user: User) => {
      if (user && user.hasOwnProperty('emails') && user.hasOwnProperty('roles')) {
        this.isAccountActive$.next(
          user.roles.includes(UserRole.Admin) || (user.emails[0].verified && user.status === AccountStatus.Active)
        );
      }
    });
  }

  public userLoggedInfo(): Observable<void> {
    return this.userLoggedInSubject.asObservable();
  }

  public get currentUser$(): BehaviorSubject<User> {
    return this._currentUser$;
  }

  public login(email: string, password: string) {
    this.loader.show();
    Meteor.loginWithPassword(email, password, (error: Meteor.Error) => {
      this.loader.hide();
      this.staticStore.setDashboardReroute(true);
      if (error) {
        if (error.reason) {
          this.toast.error(error.reason);
        }
        if (error.error) {
          if (typeof error.error === 'string') {
            this.toast.error(error.error);
          } else {
            Object.keys(error.error).forEach((k) => {
              this.toast.error(error.error[k]);
            });
          }
        }
      }
    });
  }

  public logout() {
    this.loader.show();
    Meteor.logout((error: Error) => {
      if (!!error) {
        this.toast.error(error.message || 'Error while login out');
      } else {
        this.toast.success('User logout');
        this.router.navigate(['login']);
      }
      this.loader.hide();
    });
  }

  public register(
    email: string,
    password: string,
    type: string = 'publisher',
    referral?: Referral,
    acquirer?: User,
    audienceCountry?: string,
    audienceSize?: string,
    preferredIntegrationType?: string
  ) {
    this.loader.show();
    MeteorObservable.call(
      'account.create',
      email,
      password,
      type,
      referral ? referral._id : null,
      acquirer ? acquirer._id : null,
      audienceCountry || null,
      audienceSize || null,
      preferredIntegrationType || null
    ).subscribe(
      () => {
        this.toast.success('You should receive email with instructions. Check your inbox!');
        this.router.navigateByUrl('/login');
        this.loader.hide();
      },
      (error: Meteor.Error) => {
        if (error.reason) {
          this.toast.error(error.reason);
        } else if (error.error) {
          this.toast.error(error.error);
        }
        this.loader.hide();
      }
    );
  }

  public resetPassword(email: string) {
    this.loader.show();
    MeteorObservable.call('account.resetPassword', email).subscribe(
      () => {
        this.toast.success('You should receive email with instructions. Check your inbox!');
        this.router.navigateByUrl('/login');
        this.loader.hide();
      },
      (error: Meteor.Error) => {
        this.toast.error(error.error);
        this.loader.hide();
      }
    );
  }

  public resendActivation(email: string) {
    MeteorObservable.call('account.resendActivation', email).subscribe({
      next: (accountActive: boolean) => {
        if (accountActive) {
          this.toast.success('Your email has been confirmed already.');
          return this.router.navigateByUrl('/login');
        }
        this.toast.success('Activation email has been send again. Please check your inbox.');
        this.router.navigateByUrl('/login');
      },
      error: () => {
        this.toast.error('Incorrect email.');
      },
    });
  }

  public checkIfFilled(withRedirect: boolean = false): Promise<boolean> {
    return new Promise((resolve) => {
      Tracker.autorun(() => {
        const user: User | any = Meteor.user();
        if (!user) {
          return;
        }
        const isFilled = !!user.billingName && !!user.currency;
        if (!isFilled && withRedirect) {
          const isPublisher = user.roles.includes('publisher');
          if (isPublisher) {
            // TODO: set route for publisher
            this.router.navigate(['publisher', 'billing']);
          } else {
            this.router.navigate(['advertiser', 'settings']);
          }
        }
        resolve(isFilled);
      });
    });
  }

  public approveUser(id: string) {
    return new Promise((resolve, reject) => {
      this.loader.show();
      MeteorObservable.call('account.approve', id).subscribe(
        () => {
          this.toast.success('Account approved');
          this.loader.hide();
          resolve(true);
        },
        (error: Meteor.Error) => {
          this.toast.error(error.error);
          this.loader.hide();
          reject();
        }
      );
    });
  }

  public rejectUser(id: string) {
    return new Promise((resolve, reject) => {
      this.loader.show();
      MeteorObservable.call('account.reject', id).subscribe(
        () => {
          this.toast.success('Account rejected');
          this.loader.hide();
          resolve(true);
        },
        (error: Meteor.Error) => {
          this.toast.error(error.error);
          this.loader.hide();
          reject();
        }
      );
    });
  }

  public suspendUser(id: string) {
    return new Promise((resolve, reject) => {
      this.loader.show();
      MeteorObservable.call('account.suspend', id).subscribe(
        () => {
          this.toast.success('Account suspended');
          this.loader.hide();
          resolve(true);
        },
        (error: Meteor.Error) => {
          this.toast.error(error.error);
          this.loader.hide();
          reject();
        }
      );
    });
  }

  public updateBillings(user: User, showToast: boolean = true): Promise<any> {
    this.loader.show();
    return new Promise((resolve, reject) => {
      MeteorObservable.call('billing.update', user)
        .pipe(take(1))
        .subscribe(
          (_) => {
            if (showToast) {
              this.toast.success('Settings updated');
            }
            this.loader.hide();
            resolve(null);
          },
          (error: Meteor.Error) => {
            this.loader.hide();
            if (error.reason) {
              this.toast.error(error.reason);
            }
            if (error.error) {
              if (typeof error.error === 'string') {
                this.toast.error(error.error);
              } else {
                Object.keys(error.error).forEach((k) => {
                  this.toast.error(error.error[k]);
                });
              }
            }
            reject(error.error);
          }
        );
    });
  }

  public updateProfile(userProfile: User & Record<string, any>): Observable<void> {
    this.loader.show();
    return MeteorObservable.call('account.updateProfile', userProfile).pipe(
      tap(() => {
        this.loader.hide();
        this.toast.success('Profile updated!');
      }),
      catchError((e: Meteor.Error) => {
        this.loader.hide();
        throw this.toast.error(e.error);
      })
    ) as Observable<void>;
  }

  public updateUser(user: User): Observable<any> {
    this.loader.show();
    return MeteorObservable.call('account.updateUser', user).pipe(
      tap(() => {
        this.loader.hide();
        this.toast.success('User updated!');
      }),
      catchError((error: Meteor.Error, _caught: Observable<any>) => {
        this.loader.hide();
        this.toast.error(error.error);
        return of(false);
      })
    );
  }

  public updateUserCall(user: Partial<User>): Observable<any> {
    return MeteorObservable.call('account.updateUser', user);
  }

  public cancelUser(userId: Pick<User, '_id'>): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await MeteorObservable.call('account.cancel', userId).toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public search(search: string, scope: IListingScopes[keyof IListingScopes], sort: Record<string, number>) {
    return MeteorObservable.call<any[]>('account.searchUsers', search, scope, sort);
  }

  public setAutoInvoiceMail(activate: boolean, publisherId: string) {
    return MeteorObservable.call('account.setAutoInvoiceMail', activate, publisherId);
  }

  public fetchUserById(userId: string): Observable<User> {
    return MeteorObservable.call('account.fetchUserById', userId);
  }

  private watchCurrentUser() {
    MeteorObservable.autorun().subscribe(() => {
      const user: any = Meteor.user();
      if (user !== undefined && (!user || user.hasOwnProperty('roles'))) {
        this._currentUser$.next(user);
        this.checkAccess(user);
      }
    });
  }

  private checkAccess(user: User) {
    const path: string = this.location.path() || '/';
    if (user !== undefined && (!user || user.hasOwnProperty('roles'))) {
      if (user) {
        if (!path.includes(`/${user.roles[0]}`)) {
          this.router.navigate([user.roles[0]]);
        }
      } else {
        this.displayLoginWindow = true;
        this.userLoggedInSubject.next();
        if (
          (path.includes('/acquirer') ||
            path.includes('/advertiser') ||
            path.includes('/admin') ||
            path.includes('/publisher')) &&
          !path.includes('/register')
        ) {
          this.router.navigate(['/login']);
        }
      }
    }
  }
}
