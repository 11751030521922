import { AccountStatus } from './account-status';
import { UserEmail } from './user-email';
import { UserProfile } from './user-profile';
import { UserRole } from './user-role';
import { AcquirerType } from './acquirer-type';
import { UserNotifications } from './user-notifications';
import { InventoryRelationship } from './inventory-relationship';
import { PublisherIntegrationSide } from './publisher-integration-side';

export interface User {
  _id?: string;
  accountId?: string;
  publisherId?: string;
  createdAt?: Date;
  emails?: UserEmail[];
  roles?: UserRole[];
  profile?: UserProfile;
  additionalEmailAddresses?: string[];

  // publisher referred by acquirer
  acquirerType?: AcquirerType;
  referredByAcquirerId?: string;
  doNotInvoice?: boolean;

  //  advertiser referred by publisher
  referredByPublisherId?: string;
  allowAllPublishers?: boolean;

  //  status
  status?: AccountStatus;

  //  company details
  billingName?: string;
  vatNumber?: string;
  vatPayer?: boolean;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
  countryCode?: string;
  billingState?: string;

  //  payment details
  currency?: string;
  iban?: string;
  routingNumber?: string;
  accountNumber?: string;
  swiftBic?: string;
  bankName?: string;
  invoiceIssuer?: string;

  //  currentBalance
  currentBalance?: number;

  // playoutComission
  playoutComission?: number;
  selfSalesCommission?: number;

  // advertiser info
  campaignsCount?: number;
  spendings?: number;
  freeOfCharge?: boolean;
  isMediaHouse?: boolean;
  kickbackCommissionRate: number;

  // acquirer
  hasLogo?: boolean;

  // publisher
  adsPriorityFlag?: boolean;
  mailInvoice?: boolean;

  autopromoInVast?: boolean;

  acquirerProvision?: number;

  excludedAdCategories?: string[];

  integrationSide?: PublisherIntegrationSide;
  mobileSdk?: string;

  notificationPreferences: UserNotifications;
  inventoryRelationship?: InventoryRelationship;
  sspDomain?: string;

  partnershipExcellenceTier: number;
}
