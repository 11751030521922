import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ObservableCursor } from 'meteor-rxjs';

import { Payment, User } from 'common/models';
import { ModalService, UserService, PaymentService } from 'common/services';
import { Users } from 'common/collections';

@Component({
  selector: 'app-order-payments',
  templateUrl: './order-payments.component.html',
  styleUrls: ['./order-payments.component.scss'],
})
export class OrderPaymentsComponent implements OnInit, OnDestroy {
  public payments$: ObservableCursor<Payment>;
  public currentUser$: BehaviorSubject<User>;
  public paymentForm: FormGroup;
  public isAdmin: boolean = false;

  @Input() public currency: string;
  @Input() public set orderId(value: string) {
    this._orderId = value;
    this.payments$ = this._paymentService.getOrderPayments(value);
  }

  private _orderId: string;
  private _userSub: Subscription;

  constructor(
    private _userService: UserService,
    private _paymentService: PaymentService,
    private _fb: FormBuilder,
    private _modalService: ModalService
  ) {
    this.currentUser$ = this._userService.currentUser$;
    this.paymentForm = _fb.group({
      amount: ['', Validators.required],
    });
  }

  ngOnInit() {
    this._userSub = this._userService.currentUser$.subscribe((user: User) => {
      this.isAdmin = user && user.roles.includes('admin');
    });
  }

  ngOnDestroy() {
    this._userSub.unsubscribe();
  }

  public submit() {
    if (this.paymentForm.valid) {
      this._paymentService
        .addOrderPayment(this._orderId, this.paymentForm.value.amount)
        .subscribe(() => this.paymentForm.reset());
    }
  }

  public getUserName(userId): string {
    let user: User;
    if (userId && (user = Users.findOne(userId))) {
      return (
        (user.profile && user.profile.first && `${user.profile.first} ${user.profile.last}`) || user.emails[0].address
      );
    }
    return '-';
  }

  public cancelPayment(paymentId) {
    this._modalService
      .openConfirmModal({
        text: 'Are you sure that cancel this payment?',
      })
      .then((confirmed) => {
        if (confirmed) {
          this._paymentService.cancelPayment(paymentId).subscribe(() => {});
        }
      });
  }
}
