export enum Tracker {
  gendersExactTargeting = 'gendersExactTargeting',
  ageExactTargeting = 'ageExactTargeting',
  interestExactTargeting = 'interestExactTargeting',
  intentsExactTargeting = 'intentsExactTargeting',
  brandsExactTargeting = 'brandsExactTargeting',
  eventsExactTargeting = 'eventsExactTargeting',
  contentCategoryExactTargeting = 'contentCategoryExactTargeting',
  mobileSegmentExactTargeting = 'mobileSegmentExactTargeting',
  nielsenSegmentExactTargeting = 'nielsenSegmentExactTargeting',
  // NOTE: Content Language targeting is strict by default, because it makes no sense to mix ad and content languages.
}
