interface IModifier {
  (value: string): string;
}
type TModifiers = Record<string, IModifier>;

const DEFAULT_MODIFIERS: TModifiers = {
  uppercase(value: string): string {
    return value.toUpperCase();
  },
  lowercase(value: string): string {
    return value.toLowerCase();
  },
  capitalize(value: string): string {
    return value.substring(0, 1).toUpperCase() + value.substring(1);
  },
};

export function formatOutput(
  item: Record<string, any>,
  displayFormat: string,
  modifiers: TModifiers = DEFAULT_MODIFIERS
): string | undefined {
  if (!item) {
    return;
  }
  if (!displayFormat.includes('{') && !displayFormat.includes('}')) {
    return item[displayFormat];
  }

  return displayFormat.replace(/\{(.+?)\}/g, (_, name) => {
    if (!name.includes('|')) {
      return item[name] || '';
    }

    const [key, modifierName] = name.split('|');
    const modifier = modifiers[modifierName.toLowerCase()];
    if (!modifier) {
      console.warn(`Unknown format modifier: ${modifierName}`);
      return '';
    }
    let value = item[key] || '';
    const isString = typeof item[key] === 'string';
    return modifier(isString ? value : value.toString());
  });
}
