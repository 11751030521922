import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'common/services';
import { AccountStatus, UserRole } from 'common/models';

@Injectable()
export class ActiveAccountGuard implements CanActivateChild {
  constructor(private _userService: UserService, private _router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this._userService.currentUser$.subscribe((user) => {
        if (user !== undefined && (!user || user.hasOwnProperty('roles'))) {
          if (user && [UserRole.Acquirer, UserRole.Advertiser, UserRole.Publisher].includes(user.roles[0])) {
            const isActive: boolean = user.emails[0].verified && user.status === AccountStatus.Active;
            if (!isActive || (!!user && user.hasOwnProperty('countryCode') && user.countryCode === 'other')) {
              if (route.url.toString() !== 'inactive') {
                this._router.navigate([user.roles[0], 'inactive']);
                resolve(false);
              } else {
                resolve(true);
              }
            } else {
              if (route.url.toString() === 'inactive') {
                this._router.navigate([user.roles[0], 'dashboard']);
                resolve(false);
              } else {
                resolve(true);
              }
            }
          } else {
            resolve(true);
          }
        }
      });
    });
  }
}
