import { ListenerInfo } from '.';
export interface AdvertiserTracking {
  _id?: string;
  campaignIds?: string[];
  createdAt?: Date;
  listenerId?: string;
  advertiserId?: ListenerInfo;
  listenerInfo?: any;
  extId?: string | null;
  sinceLastPlayout?: number;
  referrer?: string | null;
  productType?: string | null;
}
