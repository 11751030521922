import { AbstractControl } from '@angular/forms';

export function removeControlError(control: AbstractControl, name: string | string[]) {
  const names = Array.isArray(name) ? name : [name];
  const { errors } = control;
  if (errors) {
    for (const name of names) {
      delete errors[name];
    }
    if (!Object.keys(errors).length) {
      control.setErrors(null);
    } else {
      control.setErrors(errors);
    }
  }
}

export function addControlError(control: AbstractControl, name: string | string[]) {
  const names = Array.isArray(name) ? name : [name];
  const errors = names.reduce((acc, name) => ({ ...acc, [name]: true }), {});
  control.setErrors({ ...control.errors, ...errors });
}
