import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Renderer2,
  forwardRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-editor',
  templateUrl: './input-editor.component.html',
  styleUrls: ['./input-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputEditorComponent),
    },
  ],
})
export class InputEditorComponent implements ControlValueAccessor, OnInit {
  @ViewChild('inputEditorControl') inputEditorControl: ElementRef;
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() required: string = 'false';
  @Input() requiredMessage: string = '';
  @Input() disabled: string = 'false';
  @Input() id: string = '';
  @Input() stringlength: string = '';
  @Output() onSave: EventEmitter<string> = new EventEmitter();
  @Output() onCancel: EventEmitter<string> = new EventEmitter();
  @Output() onEditing: EventEmitter<string> = new EventEmitter();

  private _value: any;
  private inputReqflag: boolean = false;

  onTouched: (_: any) => {};
  onChange: (_: any) => {};
  _originalValue: any;
  editing: boolean;

  constructor(element: ElementRef, private _renderer: Renderer2) {}

  writeValue(value: any): void {
    this._value = value;
  }
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: (_: any) => {}): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit(): void {}
  onSaveInput() {
    if (this.required) {
      if (
        this.inputEditorControl.nativeElement.value == null ||
        this.inputEditorControl.nativeElement.value === undefined ||
        this.inputEditorControl.nativeElement.value === ''
      ) {
        this.inputReqflag = true;
        return;
      } else {
        this.inputReqflag = false;
      }
    } else {
      this.inputReqflag = false;
    }

    if (this._originalValue != this._value) {
      this.onSave.emit('clicked save');
    }
    this.editing = false;
  }
  onCancelInput() {
    this.editing = false;
    this._value = this._originalValue;
    this.inputReqflag = false;
    this.onCancel.emit('clicked cancel');
  }
  isInputTextEmpty(): Boolean {
    return this._value === undefined || this._value == '';
  }
}
