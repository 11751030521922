<div class="row settings__data list list__invoice">
  <div class="col settings__data__box">
    <div class="settings__data__box__content">
      <h4>Payments</h4>
      <small *ngIf="!(payments$ | async)?.length">There are no payments for this order.</small>

      <table *ngIf="(payments$ | async)?.length">
        <thead>
          <tr>
            <th>Date</th>
            <th *ngIf="isAdmin">By</th>
            <th>Amount</th>
            <th *ngIf="isAdmin">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of payments$ | async">
            <td>{{ payment.createdAt | date: 'd.MM.yyyy' }}</td>
            <td *ngIf="isAdmin">{{ getUserName(payment.userId) }}</td>
            <td>{{ payment.amount | price2 }} {{ payment.currency | uppercase }}</td>
            <td *ngIf="isAdmin">
              <span
                *ngIf="payment.amount > 0 && !payment.cancelled"
                (click)="cancelPayment(payment._id)"
                class="button button--small"
                >Cancel</span
              >
            </td>
          </tr>
        </tbody>
      </table>

      <form [formGroup]="paymentForm" (ngSubmit)="submit()" *ngIf="isAdmin">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control currency-input" formControlName="amount" type="number" [min]="0" />
            <div class="input-group-append">
              <span class="input-group-text">{{ currency | uppercase }}</span>
            </div>
          </div>
          <button type="submit" [disabled]="paymentForm.invalid">Add payment</button>
        </div>
      </form>
    </div>
  </div>
</div>
