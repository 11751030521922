export type UserRole = 'admin' | 'acquirer' | 'advertiser' | 'publisher' | 'streamer';

export const UserRole: {
  Admin: UserRole;
  Acquirer: UserRole;
  Advertiser: UserRole;
  Publisher: UserRole;
  Streamer: UserRole;
} = {
  Admin: 'admin',
  Acquirer: 'acquirer',
  Advertiser: 'advertiser',
  Publisher: 'publisher',
  Streamer: 'streamer',
};
