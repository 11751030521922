import { InvoiceStatus } from './invoice-status';
import { InvoiceSubject } from './invoice-subject';
import { OrderItem } from './order-item';

export interface Invoice {
  _id?: string;
  createdAt?: Date;
  advertiserId?: string;
  publisherId?: string;
  acquirerId?: string;
  invoiceId?: string;
  paymentDue: Date;
  from?: InvoiceSubject;
  to?: InvoiceSubject;
  orderItems?: OrderItem[];
  subtotal?: number;
  tax?: number;
  total?: number;
  currency?: string;
  status?: InvoiceStatus;
  campaignId?: string;
  partnershipExcellenceTier?: number;
}
