import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ToastService, ModalService, CampaignsService, UserService } from 'common/services';
import { StaticStore, IListingScopes } from 'app/staticStore.service';
import { ScopedListing } from '../../common/classes';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
})
export class CampaignsComponent extends ScopedListing implements OnInit, OnDestroy {
  public statusesMap: string[] = [];
  public isFilled: boolean = false;
  public listingAttributeName: keyof IListingScopes = 'campaigns';
  public search: string;
  public searchInput: FormControl = new FormControl();
  private destructor: Subject<void> = new Subject<void>();

  constructor(
    private campaignsService: CampaignsService,
    private userService: UserService,
    private toast: ToastService,
    private modalService: ModalService,
    protected staticStore: StaticStore
  ) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
    this.isFilled = await this.userService.checkIfFilled();
    this.statusesMap = this.campaignsService.getStatusesMap();
    this.updateSearchInput();

    this.staticStore.clearSearch.pipe(takeUntil(this.destructor)).subscribe((changed: boolean) => {
      if (changed) {
        this.updateSearchInput();
      }
    });

    this.searchInput.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destructor))
      .subscribe((value) => {
        this.staticStore.setPage(1);
        this.staticStore.setGeneralSearch(value);
        this.updateSearchInput();
      });
  }

  public generateReport() {
    this.modalService.openReportGenerateConfirm().then((confirmed) => {
      if (confirmed) {
        this.campaignsService
          .generateActiveCampaignsReport()
          .pipe(takeUntil(this.destructor))
          .subscribe(() => {
            this.toast.success('Report ordered');
          });
      }
    });
  }

  ngOnDestroy() {
    this.destructor.next();
    this.destructor = null;
  }
  private updateSearchInput(): void {
    this.search = this.staticStore.getGeneralSearch();
    this.searchInput.setValue(this.search);
  }
}
