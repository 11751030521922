import { MeteorObservable } from 'meteor-rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type TStreamFileType = 'intro' | 'outro';
export interface IFetchStreamFilesParams {
  streamId: string;
  fileType: TStreamFileType;
  replacement: boolean;
}

@Injectable()
export class FileService {
  public removeFile(data: { _id: string; campaignId?: string }): Observable<boolean> {
    return MeteorObservable.call('file.remove', data);
  }

  public fetchStreamFiles(data: IFetchStreamFilesParams): Observable<File[]> {
    return MeteorObservable.call('file.fetchStreamFiles', data);
  }
}
