import { Component } from '@angular/core';

import { Stream } from 'common';
import { ModalComponent } from 'common/classes';

@Component({
  selector: 'app-modal-stream-approve-confirmation-duplicate',
  templateUrl: './modal-stream-approve-confirmation-duplicate.component.html',
  styleUrls: ['./modal-stream-approve-confirmation-duplicate.component.scss'],
})
export class ModalStreamApproveConfirmationDuplicateComponent extends ModalComponent {
  public stream: Stream;

  public confirm() {
    this._closeModal(true);
  }

  public close() {
    this._closeModal(false);
  }
}
