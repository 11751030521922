export interface UserNotifications {
  //advertiser
  campaignStatusChange?: boolean;
  noPlayoutsAlert?: boolean;

  //publisher
  autopromotionAdStatusChange?: boolean;
  stationStatusChange?: boolean;
  audienceMonthlyReport?: boolean;
  revenueDailyReport?: boolean;
  revenueMonthlyReport?: boolean;
  stationIntegrationProblem?: boolean;
}
