import { Pipe, PipeTransform } from '@angular/core';
import { InventoryRelationship } from 'common/models';

@Pipe({ name: 'inventoryRelationship' })
export class InventoryRelationshipPipe implements PipeTransform {
  transform(inventoryRelationship: InventoryRelationship) {
    switch (inventoryRelationship) {
      case InventoryRelationship.IndirectMultiplePublishers:
        return 'Indirect multiple';
      case InventoryRelationship.IndirectSinglePublisher:
        return 'Indirect single';
      case InventoryRelationship.OwnPublisher:
        return 'Own publisher';
      default:
        return inventoryRelationship;
    }
  }
}
