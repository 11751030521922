export interface StreamPlatform {
  id: string;
  name: string;
  formControlName?: string | string[];
}

const streamPlatforms: StreamPlatform[] = [
  { id: 'web', name: 'Web', formControlName: 'website' },
  { id: 'mobile', name: 'Mobile', formControlName: ['googlePlayUrl', 'appStoreUrl'] },
  { id: 'smart-speaker', name: 'Smart speaker' },
  { id: 'out-of-home', name: 'Out of home' },
  { id: 'other', name: 'Other' },
];

export const availableStreamPlatforms = streamPlatforms.filter(({ id }) => id !== 'other');
