import { Component } from '@angular/core';

import { getStreamIntegrationDisplay, ModalService, Stream } from 'common';
import { ModalComponent } from 'common/classes';

@Component({
  selector: 'app-modal-stream-approve-confirmation',
  templateUrl: './modal-stream-approve-confirmation.component.html',
  styleUrls: ['./modal-stream-approve-confirmation.component.scss'],
})
export class ModalStreamApproveConfirmationComponent extends ModalComponent {
  public stream: Stream;

  constructor(private modalService: ModalService) {
    super();
  }

  public confirm() {
    this._closeModal(true);
  }

  public close() {
    this._closeModal(false);
  }

  public async openStreamIntegrationStatus() {
    const isVerificationTriggered: boolean = await this.modalService.openStreamIntegrationStatus({
      streamId: this.stream._id,
      streamName: this.stream.broadcastName,
      integration: this.stream.lastCheck,
      integrationType: getStreamIntegrationDisplay(this.stream),
      platform: this.stream.platform,
    });

    if (isVerificationTriggered) {
      this.close();
    }
  }
}
