import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'empty' })
export class EmptyPipe implements PipeTransform {
  transform(value: string | string[], alt: string): string | string[] {
    if (Array.isArray(value)) {
      return value.length ? value : alt;
    }
    return value || alt;
  }
}
