import { Injectable } from '@angular/core';
import { AdvertiserTracking } from 'common/models';
import { ToastService } from 'common/services/toast.service';
import { MeteorObservable } from 'meteor-rxjs';

export interface TrackingDataDto {
  from: string;
  to: string;
  campaignIds: string[];
}
@Injectable()
export class TrackingService {
  constructor(private toast: ToastService) {}

  public generateReport(trackingData: TrackingDataDto): void {
    Meteor.call('tracking.report', trackingData);
    this.toast.success('The CSV report has been successfully requested.');
  }

  public checkAdvertiserTrackingPixel(pixelId: string): Promise<AdvertiserTracking> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await MeteorObservable.call('tracking.checkAdvertiserTrackingPixel', pixelId).toPromise();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
