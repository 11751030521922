import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService, User, Campaign, ImpressionUriEvent } from 'common';
import { Campaigns, ImpressionUri } from 'common';
import { MeteorObservable } from 'meteor-rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-impression-urls-edit',
  templateUrl: './impression-urls-edit.component.html',
  styleUrls: ['impression-urls-edit.component.scss'],
})
export class ImpressionUrlsEditComponent implements OnInit {
  public id: string;
  public campaign: Campaign;
  public formGroup: FormGroup = new FormGroup({ impressionUrls: new FormArray([]) });
  public impressionUrls: FormArray;
  public impressionUrlsEvents: ImpressionUriEvent[] = ['start', 'midpoint', 'complete'];
  public errors: any = {};
  public advertisers: User[];

  constructor(private route: ActivatedRoute, private router: Router, private toastService: ToastService) {}

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.campaign = Campaigns.findOne({ _id: this.id });
    if (!this.campaign) {
      this.toastService.error('Campaign not found!');
      this.router.navigate(['../..'], { relativeTo: this.route });
    }

    this.impressionUrls = this.formGroup.get('impressionUrls') as FormArray;

    for (const impressionUrl of this.campaign.impressionURIs || []) {
      this.addImpressionUrl(impressionUrl);
    }
  }

  public addImpressionUrl(url?: ImpressionUri): void {
    const group = new FormGroup({
      uri: new FormControl(typeof url !== 'undefined' ? url.uri : '', [Validators.required, this.validateUrl]),
      event: new FormControl(typeof url !== 'undefined' ? url.event : 'start', [Validators.required]),
    });
    this.impressionUrls.push(group);
  }

  public removeImpressionUrl(index: number): void {
    this.impressionUrls.removeAt(index);
  }

  public update(): void {
    this.campaign = { ...this.campaign, impressionURIs: this.impressionUrls.value };
    MeteorObservable.call('campaign.update', this.campaign)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.success('Campaign updated.');
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        error: () => {
          this.toastService.error('Error occurred when updating Campaing');
          this.router.navigate(['..'], { relativeTo: this.route });
        },
      });
  }

  public isInvalid(name: string) {
    const control = this.impressionUrls.controls[name];
    return control.invalid && control.touched;
  }

  private validateUrl(c: FormControl) {
    const URL_REGEXP = /^(http|https):\/\//;
    return !c.value || URL_REGEXP.test(c.value) ? null : { invalidUrl: true };
  }
}
