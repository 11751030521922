<div class="dropdown" dropdown *ngIf="user">
  <div class="name" dropdownToggle>
    <span>
      {{ user.profile && user.profile.first ? user.profile.first + ' ' + user.profile.last : user.emails[0].address }}
    </span>
    <img src="assets/images/icons/dropdown.svg" />
  </div>

  <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <a *ngIf="isAccountActive$ | async" class="dropdown-item" (click)="profile()">Profile</a>
    <a class="dropdown-item" (click)="logout()">Logout</a>
  </div>
</div>
