import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Output() datesChange: EventEmitter<Date[]> = new EventEmitter();
  @Input() dates: Date[] = [];
  @Input() dayOffset: number = null;
  @Input() startDate: moment.Moment = moment();
  @Input() single: boolean = false;

  currDate: Date = new Date();
  minDate: number = null;
  datesArray: DateItem[] = [];
  selectedArray: DateItem[] = [];
  labels = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  ngOnInit() {
    this.currDate = this.startDate.toDate();
    this.updateMinDate();
    this.datesArray = this.fillArray();
    this.selectedArray = this.dates.map((d) => this._makeDay(d));
  }

  get currMonth(): number {
    return this.currDate.getMonth();
  }

  get currYear(): number {
    return this.currDate.getFullYear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dates) {
      this.selectedArray = (changes.dates.currentValue || []).map((d: Date) => this._makeDay(d));
    }
    if (changes.dayOffset) {
      this.updateMinDate();
    }
  }

  updateMinDate() {
    if (!isNaN(this.dayOffset)) {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() + this.dayOffset);
      minDate.setHours(0, 0, 0, 0);
      this.minDate = minDate.getTime();
    }
  }

  selectDay(day: DateItem) {
    const time = day.time;
    if (this.single) {
      if (this.minDate && time < this.minDate) {
        return;
      }
      this.selectedArray = [day];
    } else {
      if (this.selectedArray.map((i) => i.time).includes(day.time) && this.selectedArray.length === 2) {
        this.selectedArray = this.selectedArray.filter((d: DateItem) => {
          return d.time !== day.time;
        });
      } else {
        if (this.minDate && time < this.minDate) {
          return;
        }
        if (this.selectedArray.length === 2) {
          this.selectedArray = [];
        }
        this.selectedArray.push(day);
      }
      this.selectedArray.sort((a: DateItem, b: DateItem) => a.time - b.time);
    }
    this.datesChange.emit(this.selectedArray.map((d) => new Date(d.date.getTime())));
    this.currDate = day.date;
  }

  changeMonth(count: number) {
    const newDate = new Date(this.currDate);
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(1);
    newDate.setMonth(newDate.getMonth() + count);
    newDate.setDate(new Date(this.minDate).getDate());
    if (newDate.getTime() < this.minDate) {
      return;
    }
    this.currDate.setMonth(this.currDate.getMonth() + count);
    this.currDate = new Date(this.currDate);
    this.datesArray = this.fillArray();
  }

  fillArray() {
    const date = new Date(this.currYear, this.currMonth, 1);

    const daysFromPreviousMonthCount = (date.getDay() + 6) % 7;
    const daysInCurrentMonthCount = getDaysInMonth(date.getMonth(), date.getFullYear());
    const daysFromNextMonthCount = (7 - ((daysFromPreviousMonthCount + daysInCurrentMonthCount) % 7)) % 7;

    const totalDaysInCalendarView = daysFromPreviousMonthCount + daysInCurrentMonthCount + daysFromNextMonthCount;

    date.setDate(date.getDate() - daysFromPreviousMonthCount);

    const days: DateItem[] = [];
    while (days.length < totalDaysInCalendarView) {
      const day: DateItem = this._makeDay(date, this.currMonth);
      days.push(day);
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  className(d: DateItem): string {
    const classes = [];
    if (this.minDate && d.time < this.minDate) {
      classes.push('disabled');
    }
    const times = [];
    if (this.selectedArray[0]) {
      times.push(this.selectedArray[0].time);
    }
    if (this.selectedArray[1]) {
      times.push(this.selectedArray[1].time);
    }
    if (times.includes(d.time)) {
      classes.push('selected');
    }
    if (this.selectedArray.length === 2) {
      const big = this.selectedArray[1].time;
      const small = this.selectedArray[0].time;
      const time = d.time;
      if (time > small && time < big) {
        classes.push('inRange');
      }
    }
    return classes.join(' ');
  }

  private _makeDay(date: Date, currMonth: number = null): DateItem {
    const dayDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    return {
      dayInMonth: dayDate.getDate(),
      month: !isNaN(currMonth) && dayDate.getMonth() - currMonth,
      time: dayDate.getTime(),
      date: dayDate,
    };
  }
}

interface DateItem {
  dayInMonth: number;
  month: number;
  date: Date;
  time: number;
}
