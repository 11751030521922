import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { UserService } from 'common/services';

@Injectable()
export class AuthorizedGuard implements CanActivate, CanLoad {
  constructor(private _userService: UserService) {}

  canLoad(): Promise<boolean> {
    return this._checkLogin();
  }

  canActivate(): Promise<boolean> {
    return this._checkLogin();
  }

  private _checkLogin(): Promise<boolean> {
    return new Promise((resolve) => {
      this._userService.currentUser$.subscribe((user) => {
        if (user !== undefined) {
          resolve(!!user);
        }
      });
    });
  }
}
