<div style="display: block;" *ngIf="data">
  <canvas
    *ngIf="data && data.length"
    baseChart
    [datasets]="data"
    [labels]="labels"
    [options]="options"
    [colors]="colors"
    [legend]="false"
    chartType="line"
  ></canvas>
</div>
