import { Injectable } from '@angular/core';
import { ExportToCsv, Options } from 'export-to-csv';

@Injectable()
export class CsvService {
  constructor() {}

  public exportAsCsvFile(json: string[][], csvFileName: string) {
    const options: Options = {
      filename: csvFileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: false,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(json);
  }
}
