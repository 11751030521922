import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'common/services';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { User } from 'common/models';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
  public user = null;
  public user$: Observable<User>;
  public isAccountActive$: BehaviorSubject<boolean>;

  private _userSub: Subscription;

  constructor(private _userService: UserService, private _cdRef: ChangeDetectorRef, private _router: Router) {
    this.isAccountActive$ = _userService.isAccountActive$;
  }

  ngOnInit() {
    this._userSub = this._userService.currentUser$.subscribe((user) => {
      this.user = user;
      this._cdRef.detectChanges();
    });
  }

  logout() {
    this._userService.logout();
  }

  profile() {
    this._router.navigateByUrl(`${this._userService.currentUser$.value.roles[0]}/profile`);
  }

  ngOnDestroy(): void {
    if (this._userSub) {
      this._userSub.unsubscribe();
    }
  }
}
