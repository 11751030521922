import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-detail-field',
  templateUrl: './detail-field.component.html',
})
export class DetailFieldComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() content: string | string[];
  @Input() subContent: string;
  @Input() emptyArrayPlaceholder: string;
  @Input() image: string;
  @Input() imageLink: string;
  @Input() capitilizeContent: boolean = true;

  public displayLength: number = 300;
  public displayLimit: number = 300;
  isArray: boolean;
  constructor() {}

  ngOnInit() {
    this.content = this.content || [];
    this.isArray = Array.isArray(this.content);
    this.displayLimit =
      this.content && this.displayLimit <= this.content.length ? this.content.length : this.displayLimit;
  }

  ngOnChanges() {
    this.isArray = Array.isArray(this.content);
    this.displayLimit =
      this.content && this.displayLimit <= this.content.length ? this.content.length : this.displayLimit;
  }

  public resize(toggler: boolean) {
    if (this.displayLimit > 300) {
      this.displayLength = toggler ? this.displayLimit : 300;
    }
  }
}
