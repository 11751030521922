import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'common/classes';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent extends ModalComponent implements OnInit {
  text: string;
  confirmed: boolean = false;
  constructor() {
    super();
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.dates = this.data.filter(d => d) || [];
    // }, 0);
  }

  confirm() {
    this._closeModal(true);
  }

  close() {
    this._closeModal(false);
  }
}
