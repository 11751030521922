import { User } from './user';

export interface Publisher extends User {
  //  Stream settings
  broadcastName?: string;
  website?: string;
  broadcastTypeId?: string;
  streamOutput?: string;
  streamSource1?: string;
  streamSource2?: string;
  streamSource3?: string;

  // TODO: add files support
}
