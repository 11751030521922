export type UserStatus = 'pending' | 'active' | 'suspended';

export const UserStatus: {
  Pending: UserStatus;
  Active: UserStatus;
  Suspended: UserStatus;
} = {
  Pending: 'pending',
  Active: 'active',
  Suspended: 'suspended',
};
