import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { addControlError, AppDetails, AppDataService, removeControlError, ControlStatus } from 'common';
import { of } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-app-urls',
  templateUrl: './mobile-app-urls.component.html',
  styleUrls: ['./mobile-app-urls.component.scss'],
})
export class MobileAppUrlsComponent implements OnInit {
  public form: FormGroup = this.formGroupDirective.control;
  public googleControlStatus: ControlStatus<AppDetails> = {
    control: this.form.controls['googlePlayUrl'],
    isLoading: true,
    isDataFetched: false,
  };
  public appleControlStatus: ControlStatus<AppDetails> = {
    control: this.form.controls['appStoreUrl'],
    isLoading: true,
    isDataFetched: false,
  };

  constructor(private formGroupDirective: FormGroupDirective, private appDataService: AppDataService) {}

  ngOnInit() {
    this.initAppControl(this.appleControlStatus);
    this.initAppControl(this.googleControlStatus);
  }

  public initAppControl(controlStatus: ControlStatus<AppDetails>): void {
    const { control } = controlStatus;

    controlStatus.details$ = control.valueChanges.pipe(
      tap(() => {
        removeControlError(control, ['extractAppDetailsFail', 'missingDeveloperUrl']);
        controlStatus.isDataFetched = false;
      }),
      filter((value) => value !== ''),
      tap(() => {
        this.switchOnLoader(controlStatus);
      }),
      debounceTime(1000),
      startWith(control.value || ''),
      switchMap((storeUrl) => (storeUrl ? this.appDataService.fetchAppDetailsByStoreUrl(storeUrl) : of(undefined))),
      tap((appDetails) => {
        this.switchOffLoader(controlStatus);
        controlStatus.isDataFetched = true;
        if (!appDetails) {
          if (control.value !== '') {
            addControlError(control, 'extractAppDetailsFail');
          }
        } else if (!appDetails.developerUrl) {
          addControlError(control, 'missingDeveloperUrl');
        }
      })
    );
  }

  public isControlInvalid(control: AbstractControl): boolean {
    return (
      control.invalid &&
      (Object.keys(control.errors).length > 1 || !control.hasError('blockSubmitingWhileLoading')) &&
      (control.dirty || control.value !== '')
    );
  }

  private switchOffLoader(controlStatus: ControlStatus<unknown>) {
    controlStatus.isLoading = false;
    removeControlError(controlStatus.control, 'blockSubmitingWhileLoading');
  }

  private switchOnLoader(controlStatus: ControlStatus<unknown>) {
    controlStatus.isLoading = true;
    addControlError(controlStatus.control, 'blockSubmitingWhileLoading');
  }
}
