<div class="container campaigns">
  <div class="row align-items-center campaigns__header">
    <h3>DSP</h3>
    <div>
      <input [formControl]="searchInput" type="text" class="form-control" placeholder="Search..." />
    </div>
    <button
      class="d-inline button button__secondary button--medium"
      (click)="openDataPicker()"
      *ngIf="startDate && endDate"
    >
      {{ startDate | amDateFormat: 'DD.MM.YYYY' }} - {{ endDate | amDateFormat: 'DD.MM.YYYY' }}
    </button>
    <button
      class="d-inline button button__secondary button--medium"
      (click)="openDataPicker()"
      *ngIf="!startDate || !endDate"
    >
      Select date range
    </button>
    <a title="Clear dates range" class="pl-2 pr-3" *ngIf="startDate || endDate" (click)="clearDates()"
      ><img src="assets/images/icons/close-gray.svg"
    /></a>
    <div class="dropdown" dropdown>
      <button class="button button__secondary button--small" dropdownToggle>
        <span>
          {{ scope | capitalize }}
        </span>
        <img src="assets/images/icons/dropdown.svg" />
      </button>
      <div *dropdownMenu class="dropdown-menu">
        <a class="dropdown-item" (click)="scope = 'all'">All</a>
        <a class="dropdown-item" *ngFor="let s of networkArray" (click)="scope = s.id">{{ s.name | capitalize }}</a>
      </div>
    </div>
  </div>
  <app-dsp-campaigns-list
    [network]="scope"
    [search]="search"
    [datesRange]="[startDate, endDate]"
  ></app-dsp-campaigns-list>
</div>
