export interface ListenerInfo {
  city: string;
  country: string;
  countryCode: string;
  device: string;
  gdprApplies: boolean;
  ip: string;
  language: string;
  os: string;
  timezone: string;
  ua: string;
}
