/**
 * Common form shape for FormGroups that allows inputting geotargeting config.
 * This is not the actual shape used by the back-end; they differ because FormGroups
 * cannot directly produce an object that would look like a Geotargeting.
 */
export interface GeotargetingConfiguration {
  latitude: number;
  longitude: number;
  radius: number;
}
