import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  forwardRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimeRange, TimeRangeDay, TagInputData, TagSource, DaysOfWeekObject } from 'common/models';
import { Subscription } from 'rxjs';
import { TimeRanges } from 'common/collections';
import { TagInputSerializer } from 'common/classes';

@Component({
  selector: 'app-play-times-input',
  templateUrl: './play-times-input.component.html',
  styleUrls: ['./play-times-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlayTimesInputComponent),
      multi: true,
    },
  ],
})
export class PlayTimesInputComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() source: TagSource;
  @Input() daysOfWeek: DaysOfWeekObject;
  @Input() playTimes: TimeRange[];
  lastDaysOfWeek: DaysOfWeekObject;
  daysMap: any = null;
  value: any[][] = [];
  private timeRanges: TimeRange[] = [];
  private timeRangesSub: Subscription;
  propagateChange = (_) => {};

  writeValue(val: any): void {
    if (val) {
      val.forEach((element: TimeRange) => {
        if (!this.value[element.day]) {
          this.value[element.day] = [];
        }
        const data = TagInputSerializer.getPlayTimeTagInputData(element);
        this.value[element.day].push(data);
      });
    }
  }

  constructor(private cdRef: ChangeDetectorRef) {
    this.daysMap = Object.keys(TimeRangeDay)
      .filter((day) => isNaN(Number(day))) // leave strings only, because enum contains both numbers and strings
      .map((k) => ({ name: k, order: TimeRangeDay[k] }));
  }

  ngOnInit() {
    this.timeRangesSub = TimeRanges.find().subscribe((data) => {
      this.timeRanges = data;
      this.cdRef.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (!this.lastDaysOfWeek || this.lastDaysOfWeek.binary !== this.daysOfWeek.binary) &&
      changes &&
      changes.daysOfWeek &&
      changes.daysOfWeek.currentValue
    ) {
      const binaryArray: ('0' | '1')[] = [...changes.daysOfWeek.currentValue.binaryArray];
      binaryArray.reverse().forEach((d, i) => {
        if (d === '1') return;
        if (!this.value[i + 1] && i !== 5) return;
        if (i === 5 && this.value[0]) {
          this.value[0] = [];
        } else {
          this.value[i + 1] = [];
        }
      });
      this.propagateValues();
      this.lastDaysOfWeek = this.daysOfWeek;
    }
  }

  onChange(val, order) {
    this.value[order] = val;
    this.propagateValues();
  }

  getAutocompleteItems(order: TimeRangeDay): TagInputData[] {
    return (this.timeRanges || [])
      .map((tr: TimeRange) => {
        return Object.assign({}, tr, { day: order });
      })
      .filter((tr) => {
        if (!this.value[order]) {
          return true;
        }

        return !this.value[order].some(({ value }) => {
          return value._id === tr._id;
        });
      })
      .map((tr) => {
        return TagInputSerializer.getPlayTimeTagInputData(tr);
      });
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  ngOnDestroy() {
    if (this.timeRangesSub) {
      this.timeRangesSub.unsubscribe();
    }
  }
  registerOnTouched() {}

  private propagateValues(): void {
    let values = [];
    this.value.forEach((v) => {
      values = values.concat(v);
    });
    this.propagateChange(values.map((item) => item.value));
  }
}
