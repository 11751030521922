export const regexEscape = (s: string): string => {
  return String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const NAME_REGEX = /^[ąćęłńóśżź\w\[\]\(\)\/\\]+(?:[_\s\-\.]+?[ąćęłńóśżź\w\[\]\(\)\/\\]+)*$/i;
// NOTE: Company names can be really varied, so we just make sure it's non-empty. Hence no start/end ^$ markers.
export const COMPANY_NAME_REGEX = /[^\s]+/;
export const POSTCODE_REGEX = /^[\w]+(?:[\-\s]+?[\w]+)*$/i;
export const PHONE_REGEX = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[A-Z])[!-~]{6,}$/;
export const URL_REGEX = /^(http|https):\/\//;
export const COMISSION_REGEX = /^-?(0|[1-9]\d*)?$/;
export const LEADING_AND_TRAILING_SPACES_REGEX = /^[\s].+|[\s]$/g;
export const ALL_EXCEPT_LETTERS_AND_NUMBERS_REGEX = /[^a-zA-Z0-9\s]/g;
export const DOMAIN_REGEX = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
export const NOT_UNKNOWN = /^((?!Unknown).)*$/;

export const APP_STORE_URL_REGEX = /https\:\/\/apps.apple.com(\/\w{1,3})?\/app\//;
export const GOOGLE_PLAY_URL_REGEX = /https\:\/\/play.google.com\/store\/apps/;
export const CATEGORY_REGEX = /^IAB\d+(?:-\d+)?$/;
