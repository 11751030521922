import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';
import { CampaignComponent } from './campaign.component';
import { CampaignActionsBarComponent } from './actions-bar.component';

@NgModule({
  declarations: [CampaignComponent, CampaignActionsBarComponent],
  exports: [CampaignComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, BsDropdownModule.forRoot()],
  providers: [],
  bootstrap: [CampaignComponent],
})
export class CampaignModule {}
