<div class="row loginFormWrapper justify-content-center align-items-center">
  <form
    [formGroup]="formGroup"
    (ngSubmit)="submitForm(formGroup.value)"
    class="col-10 col-sm-8 col-md-7 col-lg-5 loginForm"
  >
    <div class="row">
      <div class="col-12 text-left" *ngIf="!(logoFile && acquirer)">
        <img class="logo" src="assets/images/adtonos-new-full.svg" alt="" />
      </div>
      <span class="col-12 text-center" *ngIf="acquirer && logoFile">
        <div class="row">
          <div class="d-flex justify-content-left col-6">
            <img class="logo" [src]="logoFile" alt="" />
          </div>
          <div class="d-flex justify-content-center col-6">
            <img class="logo" src="assets/images/adtonos-new-full.svg" alt="" />
          </div>
        </div>
      </span>
      <div class="col-12">
        <h2 class="title" *ngIf="!isForgot && !isRegister && !isResetLink && !isResendActivation">Login</h2>
        <h2 class="title" *ngIf="isRegister">Create account</h2>
        <h2 class="title" *ngIf="isForgot">Forgot your password?</h2>
        <h2 class="title" *ngIf="isResetLink">Set a new password</h2>
        <h2 class="title" *ngIf="isResendActivation">Resend activations email</h2>
      </div>
      <div class="col-12">
        <p class="subtitle" *ngIf="isRegister && registerType === 'acquirer'">
          Register in less than 90 seconds and start growing revenue from your streaming content!
        </p>
        <h2 class="title-secondary" *ngIf="isRegister && registerType === 'advertiser'">Launch your audio campaign now!</h2>
        <p class="subtitle" *ngIf="isRegister && registerType === 'advertiser'">
           Registration takes less then 90 seconds<br> and no credit card is required.
        </p>
        <p class="subtitle" *ngIf="isRegister && registerType === 'publisher' && acquirer">
          {{ acquirer.name }} & AdTonos now give you the power to monetize your audio content! Register in less than 90
          seconds and start growing revenue!
        </p>
        <p class="subtitle" *ngIf="isRegister && registerType === 'publisher' && !acquirer">
          Register in less than 90 seconds<br> and start growing revenue from your streaming content!
        </p>
        <p class="subtitle" *ngIf="isForgot">
          We'll email you instructions on how to reset it.
        </p>
        <p class="subtitle" *ngIf="isResetLink">
          Enter a new password to recover your account.
        </p>
        <p class="subtitle" *ngIf="isResendActivation">
          Enter a email to resend your activation url.
        </p>
      </div>

      <mat-form-field class="col-11 inputWrapper" *ngIf="!isResetLink" appearance="outline">
        <input matInput placeholder="Email" [errorStateMatcher]="matcher" formControlName="email" autocomplete="email" />
        <app-tooltip [msg]="errors.email" #emailTooltip></app-tooltip>
        <img [appTooltip]="emailTooltip" class="errorIcon" src="assets/images/icons/error.svg" alt="" />
      </mat-form-field>

      <mat-form-field class="inputWrapper col-11" *ngIf="formGroup.controls.password" appearance="outline">
        <input
          matInput
          type="password"
          placeholder="Password"
          autocomplete="current-password"
          [errorStateMatcher]="matcher"
          formControlName="password"
        />
        <app-tooltip [msg]="errors.password" #passTooltip></app-tooltip>
        <img [appTooltip]="passTooltip" class="errorIcon" src="assets/images/icons/error.svg" alt="" />
      </mat-form-field>

      <ng-container *ngIf="formGroup.controls?.audienceCountry && registerType === 'publisher' && !acquirer">
        <mat-form-field class="inputWrapper col-11" appearance="outline">
          <mat-select formControlName="audienceCountry" placeholder="Audience Main Country">
            <mat-option [value]="c.code" *ngFor="let c of whitelistCountries$ | async">{{ c.name }}</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-1">
          <app-tooltip msg="The country where most of the listeners come from" #audienceCountryInfo></app-tooltip>
          <img [appTooltip]="audienceCountryInfo" src="assets/images/icons/info.svg" alt="" class="info-icon" />
        </div>
      </ng-container>

      <ng-container *ngIf="formGroup.controls?.audienceSize && registerType === 'publisher' && !acquirer">
        <mat-form-field class="inputWrapper col-11" appearance="outline">
          <mat-select formControlName="audienceSize" placeholder="Audience Size">
            <mat-option value="small">0 - 500</mat-option>
            <mat-option value="medium">500 - 5 000</mat-option>
            <mat-option value="big">5 000 - 25 000</mat-option>
            <mat-option value="huge">25 000 +</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-1">
          <app-tooltip msg="Estimated unique daily listeners" #audienceSizeInfo></app-tooltip>
          <img [appTooltip]="audienceSizeInfo" src="assets/images/icons/info.svg" alt="" class="info-icon"/>
        </div>
      </ng-container>

      <ng-container *ngIf="formGroup.controls?.preferredIntegrationType && registerType === 'publisher' && !acquirer">
        <mat-form-field class="inputWrapper col-11" appearance="outline">
          <mat-select formControlName="preferredIntegrationType" placeholder="Preferred Integration Type">
            <mat-option [value]="IntegrationTypeEnum.Replacement">Ads Replacement</mat-option>
            <mat-option [value]="IntegrationTypeEnum.OpenRTB">OpenRTB</mat-option>
            <mat-option [value]="IntegrationTypeEnum.Playlist">Playlist</mat-option>
            <mat-option [value]="IntegrationTypeEnum.Vast">VAST</mat-option>
            <mat-option [value]="IntegrationTypeEnum.Empty">Other</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-1">
          <a href="https://www.adtonos.com/docs/publisher/getting-started/" target="_blank">
            <img [appTooltip]="integrationTypeInfo" src="assets/images/icons/info.svg" alt="" class="info-icon"/>
          </a>
        </div>
      </ng-container>

      <div class="col-12" *ngIf="formGroup.controls.check">
        <div class="row rememberWrapper justify-content-beetwen-space">
          <div *ngIf="isRegister" class="col">
            <label for="switch" class="checkbox" ngClass="{'hasError': !formGroup.controls.check.valid}">
              <input type="checkbox" formControlName="check" id="switch" />
              <div class="checkbox__indicator checkbox__bgn--white"></div>
              <span class="checkbox__label checkbox_light"
                >I accept
                <a class="adtonos-link adtonos-link__underline" href="{{ getTermsOfUseLink() }}" target="_blank">Terms Of Use</a>
              </span>
            </label>
          </div>
          <div class="col-12 text-left" *ngIf="!isForgot && !isRegister">
            <a class="adtonos-link" routerLink="/forgotten-password">Forgotten password?</a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="button button__auth button--block"
          [disabled]="!formGroup.valid"
        >
          {{ btnText }}
        </button>
      </div>
      <div class="col-12 footer">
        <span *ngIf="!isForgot && !isRegister && !isResetLink && !isResendActivation"
          >Don't have an account?<br> Register as an <a class="adtonos-link adtonos-link__underline" routerLink="/register/advertiser">advertiser</a> or
          <a class="adtonos-link adtonos-link__underline" routerLink="/register/publisher">publisher</a>.</span
        >
        <span *ngIf="isRegister">
          Already have an account? <a class="adtonos-link adtonos-link__underline" routerLink="/login">Login</a>.<br />
          No activation email yet? <a class="adtonos-link adtonos-link__underline" routerLink="/resend-activation">Resend</a>.</span
        >
        <span *ngIf="isResetLink || isForgot"> Got your password? <a class="adtonos-link adtonos-link__underline" routerLink="/login">Login</a>. </span>
      </div>
    </div>
  </form>
</div>
