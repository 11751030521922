import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService, User } from 'common';

@Component({
  selector: 'app-unverified',
  templateUrl: './unverified.component.html',
  styleUrls: ['../../components/toast/toast-item.component.scss'],
})
export class UnverifiedComponent implements OnInit, OnDestroy {
  public showVerifyError: boolean = false;

  private _userSub: Subscription;

  constructor(private _userService: UserService) {}

  ngOnInit() {
    this._userSub = this._userService.currentUser$.subscribe((user: User) => {
      this.showVerifyError = user && user.emails.length && !user.emails[0].verified;
    });
  }

  ngOnDestroy() {
    this._userSub.unsubscribe();
  }
}
