import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { filesUrl } from 'common/config';
import { File } from 'common/models';
import { skipWhile, take, tap } from 'rxjs/operators';
import { FileService, ToastService } from 'common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-audio',
  template: `
    <audio controls>
      <source *ngIf="file" [src]="filesUrl + file.path" type="audio/mpeg" />
      <source *ngIf="!file && externalFile" [src]="externalFile" type="audio/mpeg" />
    </audio>
    <button
      [class.paused]="!isPlaying"
      [class.playing]="isPlaying"
      [disabled]="!canPlay"
      (click)="playStop()"
      class="button button__secondary paused"
    ></button>
    <div *ngIf="file && showInfo" class="audio__name">
      {{ file.name }}
      <small>{{ file.bitRate / 1000 }} kbps, {{ file.sampleRate }} kHz, {{ file.duration.toFixed(1) }} s</small>
    </div>
    <div *ngIf="!file && showInfo && externalFile" class="audio__name">
      {{ params?.name ? params.name : 'Original file' }}
      <small>{{ params?.message ? params.message : '' }}</small>
    </div>
    <span [hidden]="!removable" (click)="removeFile($event)" class="audio__remove">
      <span>&times;</span>
    </span>
  `,
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit, OnChanges {
  @Input() public file: File;
  @Input() public externalFile: string;
  @Input() public showInfo: boolean = true;
  @Input() public removable;
  @Input() public params: { name: string; message: string } | Record<string, any>;
  @Input() public campaignId?: string;
  @Output() public callback?: EventEmitter<boolean> = new EventEmitter<boolean>();

  public filesUrl: string = filesUrl;
  public audio: HTMLMediaElement;
  public isPlaying: boolean = false;
  public canPlay: boolean = false;

  private firstLoad = true;
  private relay: Subject<boolean> = new Subject();

  constructor(private _el: ElementRef, private fileService: FileService, private toast: ToastService) {}

  ngOnInit() {
    this.audio = this._el.nativeElement.querySelector('audio');
    this.audio.onplay = () => (this.isPlaying = true);
    this.audio.onended = () => (this.isPlaying = false);
    this.audio.onpause = () => (this.isPlaying = false);
    this.audio.oncanplay = () => (this.canPlay = true);
    this.audio.onerror = () => (this.canPlay = false);
    this.firstLoad = false;
    this.relay.pipe(skipWhile((val) => typeof val === 'undefined')).subscribe(() => {
      this.callback.emit(true);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const _thisCopy = Object.create({ ...this });
    for (const k of Object.keys(_thisCopy)) {
      if (
        !this.firstLoad &&
        changes[k.toString()] &&
        changes[k.toString()].currentValue !== changes[k.toString()].previousValue
      ) {
        this[k.toString()] = { ...changes[k.toString()].currentValue };
        if (k.toString() === 'file') {
          this.audio.src = this.filesUrl + this.file.path;
        }
      }
    }
    if (changes.removable !== undefined && changes.removable.currentValue !== undefined) {
      this.removable = changes.removable.currentValue;
    }
  }

  public playStop() {
    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.currentTime = 0;
      this.audio.play();
    }
  }

  public removeFile(event) {
    if (this.file) {
      this.fileService
        .removeFile({ _id: this.file._id })
        .pipe(take(1))
        .subscribe(
          () => this.relay.next(true),
          (err) => this.errorHandler(err)
        );
    }
    if (this.externalFile && this.campaignId) {
      const fileId = this.externalFile.replace(/.*\/.*\/([a-zA-Z0-9]+).*/, '$1');
      this.fileService
        .removeFile({ _id: fileId, campaignId: this.campaignId })
        .pipe(take(1))
        .subscribe(
          () => this.relay.next(true),
          (err) => this.errorHandler(err)
        );
    }
  }

  private errorHandler(err) {
    this.toast.error('Error occurred - cannot remove file');
  }
}
