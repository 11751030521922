<div class="play-times">
  <div class="play-times__box" *ngFor="let day of daysMap; let i = index">
    <span class="play-times__box__label">{{ day.name }}</span>
    <tag-input
      [ripple]="false"
      inputClass="input"
      [ngModel]="value[day.order]"
      (ngModelChange)="onChange($event, day.order)"
      theme="adtonos playTime"
      class="play-times__box__input"
      placeholder="Add option"
      secondaryPlaceholder="Add option"
      [animationDuration]="{ enter: '0ms', leave: '0ms' }"
      [onlyFromAutocomplete]="true"
      [disable]="daysOfWeek.binaryArray[daysOfWeek.binaryArray.length - 1 - i] !== '1'"
      #input
    >
      <ng-template let-item="item" let-index="index" class="tag-container">
        <span class="content">
          {{ item.display }}
        </span>
        <span (click)="input.removeItem(item, index)" class="remove-button">
          x
        </span>
      </ng-template>
      <tag-input-dropdown
        [showDropdownIfEmpty]="true"
        [keepOpen]="false"
        [autocompleteItems]="getAutocompleteItems(day.order)"
      >
        <ng-template let-item="item">
          {{ item.display }}
        </ng-template>
      </tag-input-dropdown>
    </tag-input>
  </div>
</div>
