import { Injectable, Injector } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Payments } from 'common/collections';
import { Order, Payment } from 'common/models';
import { ToastService } from 'common/services/toast.service';
import { MeteorObservable, ObservableCursor } from 'meteor-rxjs';

@Injectable()
export class PaymentService {
  private _toast: ToastService;

  constructor(private _i: Injector) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  public getOrderPayments(orderId: string): ObservableCursor<Payment> {
    return Payments.find({ orderId });
  }

  public addOrderPayment(orderId: string, amount: number): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      MeteorObservable.call('order.payment', orderId, amount).subscribe(
        () => {
          this._toast.success('Payment added.');
          observer.next(true);
          observer.complete();
        },
        (e) => {
          this._toast.error(e.error);
          observer.error(e.error);
        }
      );
    });
  }

  public cancelPayment(paymentId: string): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      MeteorObservable.call('order.cancelPayment', paymentId).subscribe(
        () => {
          this._toast.success('Payment cancelled.');
          observer.next(true);
          observer.complete();
        },
        (e) => {
          this._toast.error(e.error);
          observer.error(e.error);
        }
      );
    });
  }

  public removeOrder(orderId: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      MeteorObservable.call('order.remove', orderId).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        (e) => {
          this._toast.error(e.error);
          observer.error(e.error);
        }
      );
    });
  }

  public searchOrders(search: string, status: string, sort: Record<string, number>): Observable<Order[]> {
    return MeteorObservable.call<any[]>('order.searchOrders', search, status, sort);
  }
}
