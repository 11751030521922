export const PartnershipExcellenceTier = {
  Auto: 0,
  One: 1,
  Two: 2,
  Three: 3,
};

export const PartnershipExcellenceTiers = [
  { label: 'Automatic', name: 'auto', value: PartnershipExcellenceTier.Auto },
  { label: '1', name: 'One', value: PartnershipExcellenceTier.One },
  { label: '2', name: 'Two', value: PartnershipExcellenceTier.Two },
  { label: '3', name: 'Three', value: PartnershipExcellenceTier.Three },
];

export interface PublisherExcellenceTier {
  calculated: number;
  administrative: number;
  effective: number;
}
