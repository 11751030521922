import { debounceTime, distinctUntilChanged, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, Subject } from 'rxjs';
import { InvoiceService, UserService } from 'common/services';
import { Invoice, User } from 'common/models';
import { StaticStore, IListingScopes } from 'app/staticStore.service';
import { ScopedListing } from '../../common/classes';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
})
export class InvoicesComponent extends ScopedListing implements OnInit, OnDestroy {
  public isAdmin: boolean = false;
  public invoices$: Observable<Invoice[]>;
  public config: PaginationInstance = {
    id: 'pagination',
    itemsPerPage: 10,
    currentPage: 1,
  };
  public sort: 1 | -1 = -1;
  public sortField: string = 'createdAt';
  public searchInput: FormControl = new FormControl();
  public listingAttributeName: keyof IListingScopes = 'invoices';
  private destructor: Subject<void> = new Subject<void>();
  public onScopeChange = () => {
    this.loadInvoices();
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private invoiceService: InvoiceService,
    protected staticStore: StaticStore
  ) {
    super();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.staticStore.setListingRoute(this.router.url);
    this.staticStore.clearSearch.pipe(takeUntil(this.destructor)).subscribe((changed: boolean) => {
      if (changed) {
        this.searchInput.setValue(this.staticStore.getGeneralSearch());
        this.loadInvoices();
      }
    });
    this.userService.currentUser$.pipe(take(1)).subscribe((user: User) => {
      if (user) {
        this.isAdmin = user.roles.includes('admin');
      }
    });
    this.searchInput.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destructor))
      .subscribe((value) => {
        this.staticStore.setPage(1);
        this.staticStore.setGeneralSearch(value);
        this.loadInvoices();
      });
  }
  public pagination(page: number) {
    this.staticStore.setPage(page);
  }
  public changeSort(fieldName: string) {
    if (this.sortField === fieldName) {
      this.sort = this.sort === -1 ? 1 : -1;
    } else {
      this.sort = -1;
    }
    this.sortField = fieldName;
    this.staticStore.setSortLabel(fieldName);
    this.staticStore.setSortOrder(this.sort);
    this.loadInvoices();
  }

  ngOnDestroy() {
    this.destructor.next();
    this.destructor = null;
  }

  private loadInvoices() {
    this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : 'createdAt';
    this.sort = this.staticStore.getSortOrder();

    const sort = {
      [this.sortField]: this.sort,
    };

    const search: string = this.staticStore
      .getGeneralSearch()
      .trim()
      .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    if (!this.searchInput.value) {
      this.searchInput.setValue(search);
    }

    this.invoices$ = this.invoiceService.searchInvoices(search, this.scope, sort).pipe(shareReplay());
    this.config.currentPage = this.staticStore.getPage();
  }
}
