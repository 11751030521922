import { Component, OnInit } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import * as moment from 'moment';
import { ToastService, ModalService } from 'common/services';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-csv-report',
  templateUrl: './csv-report.component.html',
})
export class CsvReportComponent implements OnInit {
  public dates: Date[] = [new Date(), new Date()];
  campaignId: string;

  constructor(private toast: ToastService, private modalService: ModalService, private route: ActivatedRoute) {
    this.dates[0].setDate(this.dates[0].getDate() - 7);
  }

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.params.id;
  }

  public export(): void {
    if (this.dates && this.dates.length > 0) {
      this.modalService.openReportGenerateConfirm().then((confirmed) => {
        if (confirmed) {
          const from = moment(this.dates[0]).utc().format('YYYY-MM-DD');
          const to = moment(this.dates.length > 1 ? this.dates[1] : this.dates[0])
            .utc()
            .format('YYYY-MM-DD');

          MeteorObservable.call('campaign.csv', from, to, this.campaignId).subscribe(
            (_) => {
              this.toast.success('Report ordered');
            },
            (e: Meteor.Error) => {
              this.toast.error(e.error);
            }
          );
        }
      });
    }
  }
}
