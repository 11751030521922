import { animate, keyframes, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

export const fade: AnimationTriggerMetadata = trigger('fade', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '0.3s ease',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition('* => void', [
    animate(
      300,
      keyframes([
        style({
          opacity: 1,
        }),
        style({
          opacity: 0,
        }),
      ])
    ),
  ]),
]);
