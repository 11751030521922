export type ImpressionUriEvent = 'start' | 'midpoint' | 'complete';

export const ImpressionUriEvent: {
  Start: ImpressionUriEvent;
  Midpoint: ImpressionUriEvent;
  Complete: ImpressionUriEvent;
} = {
  Start: 'start',
  Midpoint: 'midpoint',
  Complete: 'complete',
};
