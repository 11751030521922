export enum FloorPriceType {
  Direct = 'direct',
  DSP = 'dsp',
}

export interface FloorPrice {
  type: FloorPriceType;
  price: {
    currency: string;
    amount: number;
  };
  countryCode: string;
}

export interface FloorPriceView {
  amount: number;
  countryCode: string;
}
export type FloorPricesView = Record<FloorPriceType, FloorPriceView[]>;
