import { DeviceType } from './device-type';
import { DspNetwork } from './dsp-network';

export interface DspCampaign {
  _id?: string;
  campaignId?: string; // not exists?
  networkId?: DspNetwork;
  name?: string;
  duration?: number;
  mediaFile?: string;
  createdAt?: Date;
  advertiserId?: string | null;
  isArchived?: boolean;
  archiveReportFile?: string;

  streamIds: string[];
  playouts: number;
  priority?: number;
  averageCpp?: number;
  totalCost?: number;
  lastPlayout?: Date;
  costCurrency?: string;

  deviceTypes?: DeviceType[];
  languages?: string[];
  countryCodes?: string[];
  cityIds?: string[];
  genders?: number[];
  gendersExcludeFlag?: boolean;
  ageRangeIds?: number[];
  ageRangeExcludeFlag?: boolean;
  interestIds?: number[];
  intentIds?: number[];
  brandIds?: number[];
  eventIds?: number[];
}
