import { Injectable } from '@angular/core';
import { RedeemRequest } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class RedeemRequestsService {
  constructor() {}

  public searchRequests(name: string, filter: Object, sort: Object): Observable<RedeemRequest[]> {
    return MeteorObservable.call('redeem.search', name, filter, sort);
  }

  public redeemFounds(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await MeteorObservable.call('redeem.request').toPromise();
        resolve(true);
      } catch (error) {
        reject(error.error);
      }
    });
  }

  redeemExecute(requestId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await MeteorObservable.call('redeem.execute', requestId).toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}
