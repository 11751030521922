export type IntegrationDisplay = 'MP3' | 'VAST' | 'Playlist' | 'OpenRTB';
export const IntegrationDisplay: {
  MP3: IntegrationDisplay;
  Vast: IntegrationDisplay;
  Playlist: IntegrationDisplay;
  OpenRTB: IntegrationDisplay;
} = {
  Vast: 'VAST',
  Playlist: 'Playlist',
  MP3: 'MP3',
  OpenRTB: 'OpenRTB',
};
