import { Campaign } from 'common/models/campaign';

export interface OrderItem {
  qty?: number;
  name?: string;
  period?: string;
  description?: string;
  total?: number;
  campaign: Campaign;
}
