import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StaticStore, IListingScopes } from 'app/staticStore.service';
import { ScopedListing } from '../../common/classes';
import { DspNetwork } from '../../common';
import { ModalService } from 'common/services';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dsp-campaigns',
  templateUrl: './dsp-campaigns.component.html',
})
export class DspCampaignsComponent extends ScopedListing implements OnInit, OnDestroy {
  public networkArray: { id: string; name: string }[] = Object.keys(DspNetwork).map((key) => {
    return { id: key, name: DspNetwork[key] };
  });
  public search: string;
  public searchInput: FormControl = new FormControl();
  public listingAttributeName: keyof IListingScopes = 'dspCampaigns';
  public startDate: Date;
  public endDate: Date;

  private startOfMonth: Date;
  private subscriptionDestructor: Subject<void> = new Subject<void>();

  constructor(protected staticStore: StaticStore, private customModalService: ModalService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.staticStore.setListingRoute(this.router.url);
    super.ngOnInit();
    this.getSearchStore();
    this.setInitialDates();

    this.staticStore.clearSearch.pipe(takeUntil(this.subscriptionDestructor)).subscribe((changed: boolean) => {
      if (changed) {
        this.getSearchStore();
        this.setInitialDates();
      }
    });

    this.searchInput.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.subscriptionDestructor))
      .subscribe((value) => {
        this.staticStore.setPage(1);
        this.staticStore.setGeneralSearch(value);
        this.getSearchStore();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptionDestructor.next();
    this.subscriptionDestructor = null;
  }

  public openDataPicker(): void {
    const startDate = this.staticStore.getStartDate() ? new Date(this.staticStore.getStartDate()) : this.startDate;
    const endDate = this.staticStore.getEndDate() ? new Date(this.staticStore.getEndDate()) : this.endDate;
    const displayDate: Date = startDate ? startDate : this.startOfMonth;
    this.customModalService.openDateRangePicker([startDate, endDate], -10000, moment(displayDate)).then((data) => {
      if (data) {
        this.startDate = data[0];
        this.endDate = data[1];
        this.staticStore.setStartDate(data[0]);
        this.staticStore.setEndDate(data[1]);
        this.staticStore.setDateSet();
      }
    });
  }

  public clearDates(): void {
    this.startDate = null;
    this.endDate = null;
    this.staticStore.setStartDate(null);
    this.staticStore.setEndDate(null);
    this.staticStore.setDateCleared();
  }

  private setInitialDates(): void {
    const startOfYesterday = moment.utc().subtract(1, 'days').startOf('day').toDate();
    const endOfToday = moment.utc().endOf('day').toDate();
    this.startOfMonth = startOfYesterday;
    this.startDate = this.staticStore.isDateCleared()
      ? null
      : this.staticStore.getStartDate()
      ? new Date(this.staticStore.getStartDate())
      : startOfYesterday;
    this.endDate = this.staticStore.isDateCleared()
      ? null
      : this.staticStore.getEndDate()
      ? new Date(this.staticStore.getEndDate())
      : endOfToday;
  }

  private getSearchStore(): void {
    this.search = this.staticStore.getGeneralSearch();
    this.searchInput.setValue(this.search);
  }
}
