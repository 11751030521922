import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CampaignsComponent } from './campaigns.component';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';

@NgModule({
  declarations: [CampaignsComponent],
  exports: [CampaignsComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, ReactiveFormsModule, BsDropdownModule.forRoot()],
  providers: [],
  bootstrap: [CampaignsComponent],
})
export class CampaignsModule {}
