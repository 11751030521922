import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedGuard, AdvertiserGuard, AdminGuard, PublisherGuard, AcquirerGuard } from 'common/guards';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('app/accounts/accounts.module').then((m) => m.AccountsModule),
    canLoad: [UnauthorizedGuard],
    canActivate: [UnauthorizedGuard],
  },
  {
    path: 'advertiser',
    loadChildren: () => import('app/advertiser/advertiser.module').then((m) => m.AdvertiserModule),
    canLoad: [AdvertiserGuard],
    canActivate: [AdvertiserGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  {
    path: 'publisher',
    loadChildren: () => import('app/publisher/publisher.module').then((m) => m.PublisherModule),
    canLoad: [PublisherGuard],
    canActivate: [PublisherGuard],
  },
  {
    path: 'acquirer',
    loadChildren: () => import('app/acquirer/acquirer.module').then((m) => m.AcquirerModule),
    canLoad: [AcquirerGuard],
    canActivate: [AcquirerGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
