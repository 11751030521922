import { throwError as observableThrowError, Observable, OperatorFunction, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from './toast.service';
import { AdCategory, AdCategoryRemap } from 'common/models';

@Injectable()
export class AdCategoriesService {
  public getAllAdCategories(): Observable<AdCategory[]> {
    return MeteorObservable.call('adCategory.list');
  }

  public getAdCategoriesRemap(): Observable<AdCategoryRemap[]> {
    return MeteorObservable.call('adCategoryRemap.list');
  }
  public addNewRemap(payload: Partial<AdCategoryRemap>[]): Observable<AdCategoryRemap[]> {
    return MeteorObservable.call('adCategoryRemap.add', payload);
  }
  public updateRemap(remap: AdCategoryRemap): Observable<AdCategoryRemap> {
    return MeteorObservable.call('adCategoryRemap.update', remap);
  }
  public removeRemap(remap: AdCategoryRemap): Observable<AdCategoryRemap> {
    return MeteorObservable.call('adCategoryRemap.remove', remap);
  }
}
