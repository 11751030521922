import { animate, style, transition, trigger, AnimationTriggerMetadata, state } from '@angular/animations';

export const modalBackdropFade: AnimationTriggerMetadata = trigger('modalBackdropFade', [
  state(
    'false',
    style({
      opacity: 0,
      visibility: 'hidden',
    })
  ),
  state(
    'true',
    style({
      opacity: 1,
    })
  ),
  transition('* => *', animate('300ms ease')),
]);

export const modalSlideTopFade: AnimationTriggerMetadata = trigger('modalSlideTopFade', [
  state(
    'false',
    style({
      transform: 'translate3d(0,-50px,0)',
      opacity: 0,
      visibility: 'hidden',
    })
  ),
  state(
    'true',
    style({
      transform: 'translate3d(0,0,0)',
      opacity: 1,
      visibility: 'visible',
    })
  ),
  transition('* => *', animate('300ms ease')),
]);
