export const LoginFormErrorsMsgs = {
  password: {
    required: 'Password is required',
    pattern: 'Password must have a minimum of 6 characters and at least one uppercase letter and digit',
  },
  email: {
    required: 'Email is required',
    email: 'Wrong email format',
  },
  check: {
    required: '',
  },
  country: {
    required: '',
  },
};
