export type CheckboxItem = {
  readonly id: string;
  readonly label: string;
  readonly value: string;
};

export type ToggleEvent = {
  readonly value: string;
  readonly checked: boolean;
};
