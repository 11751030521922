<div class="rate-limiter-form" [formGroup]="form" [class.has-error]="invalid">
  <div class="form-group" >
      <app-form-label
        label="{{ label }}"
        inputId="rateLimiterId"
        msg="Rate Limiter can limit number of requests sending to DSP"
      ></app-form-label>
      <label class="checkbox" id="rateLimiterId">
        <input type="checkbox" formControlName="enabled" />
        <div class="checkbox__indicator"></div>
      </label>
  </div>
  <div class="values" [hidden]="isRateLimiterDisable()" formGroupName="values">
    <div class="form-group">
      <label>Number of request</label>
      <input type="number" class="form-control" id="value" formControlName="value" />
    </div>
    <div class="form-group">
      <label>Period</label>
      <select class="form-control" id="requests" formControlName="period">
        <option [value]="item.value" *ngFor="let item of options">{{ item.label }}</option>
      </select>
    </div>
  </div>
</div>
