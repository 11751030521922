import { ContentCategory } from 'common';
import { ContentCategories } from 'common/collections/content-categories';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

/**
 * GetContentCategories
 *
 * @deprecated
 *
 * @returns ContentCategories
 */
export function GetContentCategories() {
  return ContentCategories.find().pipe(
    debounceTime(100),
    shareReplay(),
    map((contentCategories: ContentCategory[]) => {
      return contentCategories.map((element: ContentCategory) => {
        if (!element.isMainSubject) {
          element.name = `\u00A0\u00A0\u00A0${element.name}`;
        } else {
          element.name = `${element.name}`;
        }
        return element;
      });
    })
  );
}
