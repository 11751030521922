import { Stream, IntegrationDisplay, IntegrationType } from 'common/models';

export function getStreamIntegrationType(stream: Stream): IntegrationType {
  return stream.integrationType;
}

export function getStreamIntegrationDisplay(stream: Stream): IntegrationDisplay {
  const type = getStreamIntegrationType(stream);
  if (type === IntegrationType.Vast) {
    return 'VAST';
  }
  if (type === IntegrationType.Playlist) {
    return 'Playlist';
  }
  if (type === IntegrationType.OpenRTB) {
    return 'OpenRTB';
  }
  return 'MP3';
}
