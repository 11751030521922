import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from 'common';

const MAX_FILE_SIZE = 5 * Math.pow(2, 20); // 5 MB

export type UploadResult = {
  status: boolean;
  payload?: Record<string, string | number>;
  inline?: string | ArrayBuffer;
};

@Component({
  selector: 'app-file-upload',
  template: `
    <input #fileInput type="file" [accept]="acceptMedia" [disabled]="disabled" (change)="onFileChange($event)" />
    <span (click)="disabled ? '' : browse()" [class.disabled]="isLoading" class="button button__secondary">
      {{ params?.text ? params.text : 'Upload file' }}
    </span>
  `,
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() public method: string = 'file.upload';
  @Input() public methodParams: any = null;
  @Input() public params: Record<string, any>;
  @Input() public disabled?: boolean = false;
  @Input() public showSuccessToast?: boolean = true;
  @Input() public acceptMedia?: string = '.mp3,audio/*';
  @Input() public inline?: boolean = false;

  @Output() public callback?: EventEmitter<UploadResult> = new EventEmitter<UploadResult>();

  private _isLoading: boolean = false;

  constructor(private element: ElementRef, private toast: ToastService) {}

  public browse() {
    this.element.nativeElement.querySelector('input').click();
  }

  public onFileChange(event: { target: HTMLInputElement }) {
    if (this._isLoading) {
      return;
    }
    const reader = new FileReader();
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this._isLoading = true;
      const file: File = files[0];
      if (file.size > MAX_FILE_SIZE) {
        this._isLoading = false;
        event.target.value = '';
        reader.abort();
        return this.toast.error('File size exceeded. Max 5MB');
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this.inline) {
          this._isLoading = false;
          this.callback.emit({ status: true, inline: reader.result });
        } else {
          MeteorObservable.call<any>(this.method, file.name, reader.result, this.methodParams).subscribe(
            (payload) => {
              this._isLoading = false;
              if (this.showSuccessToast) {
                this.toast.success('File uploaded with success.');
              }
              this.callback.emit({ status: true, payload });
            },
            (err: Meteor.Error) => {
              this._isLoading = false;
              this.toast.error(err.error);
              this.callback.emit({ status: false });
            }
          );
        }
        this.element.nativeElement.querySelector('input').value = null;
      };
    }
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
}
