<ng-container [formGroup]="form">
  <div
    class="form-group"
    [ngClass]="{
      'has-error': isControlInvalid(googleControlStatus.control)
    }"
  >
    <ng-container *ngIf="{googleAppDetails: googleControlStatus.details$ | async} as data">
      <label for="googlePlayUrl">Google Play link</label>
      <div class="input-group flex-input">
        <input
          type="text"
          class="form-control"
          id="googlePlayUrl"
          placeholder="Application url starting with https://play.google.com/store/apps"
          formControlName="googlePlayUrl"
        />
          <div class="input-group-append" *ngIf="googleControlStatus.isLoading || (data.googleAppDetails && googleControlStatus.isDataFetched)" >
            <span class="input-group-text">
              <span *ngIf="googleControlStatus.isLoading"><img class="loader"/></span>
              <mat-icon *ngIf="(data.googleAppDetails && googleControlStatus.isDataFetched)" class="green-checkmark">done</mat-icon>
            </span>
          </div>
      </div>

      <small class="form-text text-error" *ngIf="googleControlStatus.control.errors?.pattern">
        Google Play application link should start with https://play.google.com/store/apps
      </small>
      <small class="form-text text-error" *ngIf="googleControlStatus.control.errors?.extractAppDetailsFail">
        Cannot extract application's details, make sure that link is pointing to the real Google Play app.
      </small>
      <small class="form-text text-error" *ngIf="appleControlStatus.control.errors?.missingDeveloperUrl">
        Missing Developer URL in application's details. Make sure that link is setup for the app in Google Play.
      </small>
      <app-app-details class="p-1" *ngIf="data.googleAppDetails && googleControlStatus.isDataFetched" [appDetails]="data.googleAppDetails"></app-app-details>
    </ng-container>
    </div>

  <div
    class="form-group"
    [ngClass]="{
      'has-error': isControlInvalid(appleControlStatus.control)
    }"
  >
    <ng-container *ngIf="{appleAppDetails: appleControlStatus.details$ | async} as data">
      <label for="appStoreUrl">App Store link</label>
      <div class="input-group flex-input" >
        <input
          type="text"
          class="form-control"
          id="appStoreUrl"
          placeholder="Application url starting with https://apps.apple.com/app"
          formControlName="appStoreUrl"
        />
          <div class="input-group-append" *ngIf="appleControlStatus.isLoading || (data.appleAppDetails && appleControlStatus.isDataFetched)" >
            <span class="input-group-text">
              <span *ngIf="appleControlStatus.isLoading"><img class="loader"/></span>
              <mat-icon *ngIf="data.appleAppDetails && appleControlStatus.isDataFetched" class="green-checkmark">done</mat-icon>
            </span>
          </div>
        </div>

        <small class="form-text text-error" *ngIf="appleControlStatus.control.errors?.pattern">
          App Store application link should start with https://apps.apple.com/app
        </small>
        <small class="form-text text-error" *ngIf="appleControlStatus.control.errors?.extractAppDetailsFail">
          Cannot extract application's details, make sure that link is pointing to the real AppStore app.
        </small>
        <small class="form-text text-error" *ngIf="appleControlStatus.control.errors?.missingDeveloperUrl">
          Missing Developer URL in application's details. Make sure that link is setup for the app in AppStore.
        </small>
        <app-app-details class="p-1" *ngIf="data.appleAppDetails && appleControlStatus.isDataFetched" [appDetails]="data.appleAppDetails"></app-app-details>
    </ng-container>
  </div>
  <div
    class="form-group"
    *ngIf="form.errors?.atLeastOneAppRequired"
    [ngClass]="{ 'has-error': form.errors?.atLeastOneAppRequired }"
  >
    <small class="form-text text-error">
      At least one application link field must be entered
    </small>
  </div>
  <div hidden></div>
</ng-container>
