import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, Subject } from 'rxjs';
import { Campaign, CampaignStatus, Tooltips, Pricing, User } from 'common/models';
import { CampaignsService } from 'common/services';
import { StaticStore } from 'app/staticStore.service';
import { ScopedListing } from '../../common/classes';
import { shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss'],
})
export class CampaignsListComponent extends ScopedListing implements OnInit, OnChanges, OnDestroy {
  @Input() public isAdvertiser = false;
  public sort: 1 | -1 = -1;
  public sortField: string = 'date';
  public campaigns$: Observable<Campaign[]>;
  public config: PaginationInstance = {
    id: 'pagination',
    itemsPerPage: 10,
    currentPage: 1,
  };
  public tooltips: Tooltips;

  private destructor: Subject<void> = new Subject<void>();
  private _status: string = 'active';
  private _search: string = '';

  constructor(private campaignsService: CampaignsService, private router: Router, protected staticStore: StaticStore) {
    super();
  }

  @Input() public set status(value: string) {
    if (this._status !== value) {
      this._status = value;
    }
  }

  @Input() public set search(value: string) {
    if (this._search !== value) {
      this._search = value;
    }
  }

  public get status(): string {
    return this._status;
  }

  public get search(): string {
    return this._search;
  }

  ngOnInit() {
    this.staticStore.setListingRoute(this.router.url);
    this.staticStore.clearSearch.pipe(takeUntil(this.destructor)).subscribe((changed: boolean) => {
      if (changed) {
        this.matchSortHandle();
      }
    });
    this.matchSortHandle();
    this.tooltips = this.campaignsService.getTooltips();
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.destructor.next();
    this.destructor = null;
    super.ngOnDestroy();
  }

  ngOnChanges() {
    this.matchSortHandle();
  }

  pagination(page: number) {
    this.staticStore.setPage(page);
  }

  userTitle(user: User): string {
    if (user.profile) {
      return `${user.profile.first} ${user.profile.last}`;
    } else if (user.billingName) {
      return user.billingName;
    } else if (user.accountId) {
      return user.accountId;
    } else if (user.emails && user.emails.length && user.emails[0].address) {
      return user.emails[0].address;
    } else {
      return user._id;
    }
  }

  public changeSort(fieldName: string) {
    if (this.sortField === fieldName) {
      this.sort = this.sort === -1 ? 1 : -1;
    } else {
      this.sort = -1;
    }
    this.sortField = fieldName;
    this.staticStore.setSortLabel(fieldName);
    this.staticStore.setSortOrder(this.sort);
    this.matchSortHandle();
  }

  public changeStatus(campaign: Campaign, status: CampaignStatus) {
    campaign.status = status;
    try {
      this.campaignsService.update(campaign);
    } catch (error) {}
  }

  public calculateBudget(playoutCommission: Pricing, normalizedTotalBudget: number) {
    if (!playoutCommission || !playoutCommission.value) {
      return 0;
    }
    return normalizedTotalBudget / playoutCommission.value;
  }

  private matchSortHandle(): void {
    this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : 'date';
    this.sort = this.staticStore.getSortOrder();

    const search: string = this.staticStore
      .getGeneralSearch()
      .trim()
      .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    const sort = {
      [this.sortField]: this.sort,
    };

    this.campaigns$ = this.campaignsService.searchCampaigns(search, this.status, sort).pipe(shareReplay());
    this.config.currentPage = this.staticStore.getPage();
  }
}
