
<div class="dropdown" dropdown *ngIf="ui.actions.visible">
  <button class="button button__secondary button--small" dropdownToggle title="Status">
    <span>
      Action
    </span>
    <img src="assets/images/icons/dropdown.svg" />
  </button>
  <div *dropdownMenu class="dropdown-menu">
      <a
        class="dropdown-item"
        [ngClass]="{ active: action.status === campaign.status }"
        (click)="setStatus(action.status)"
        *ngFor="let action  of actions"
      >
      {{ action.label }}
      </a>
  </div>
</div>

<div *ngIf="!ui.statuses.visible" [appTooltip]="currentStatusTooltip">
  <div [appTooltip]="currentStatusTooltipArchived">
    <button
      class="button button__secondary button--small"
      [disabled]="ui.statuses.disabled"
    >
      {{ campaign.status | capitalize }}
    </button>
    <app-tooltip
      [hidden]="!ui.statuses.reactivate"
      msg="To reactivate this campaign, please change the date range to the correct one"
      #currentStatusTooltip></app-tooltip>
    <app-tooltip
      [hidden]="!ui.statuses.archived"
      msg="Archived campaigns can't be activated"
      #currentStatusTooltipArchived></app-tooltip>
  </div>
</div>

<div class="dropdown" dropdown *ngIf="ui.statuses.visible">
  <button
    class="button button__secondary button--small"
    dropdownToggle
    title="Status"
  >
    <span>
      {{ campaign.status | capitalize }}
    </span>
    <img src="assets/images/icons/dropdown.svg" />
  </button>
  <div *dropdownMenu class="dropdown-menu">
    <a
      class="dropdown-item"
      [ngClass]="{ active: s === campaign.status }"
      (click)="setStatus(s)"
      *ngFor="let s of statuses"
    >
      {{ s | capitalize }}
    </a>
  </div>
</div>

<div *ngIf="ui.edit.visible" [appTooltip]="editButtonTooltip">
  <button
    routerLink="./edit"
    [disabled]="ui.edit.disabled"
    class="button button__primary button--small"
  >
    Edit
  </button>
  <app-tooltip
    [hidden]="!ui.edit.disabled"
    msg="Archived campaigns can't be modified"
    #editButtonTooltip
  ></app-tooltip>
</div>

<button
  routerLink="./impression-urls-edit"
  [disabled]="ui.impression.disabled"
  class="button button__primary button--small"
>
  Impression URLs
</button>

<button
  *ngIf="ui.copy.visible"
  (click)="copyCampaign()"
  class="button button__primary button--small"
>
  Copy
</button>

<button (click)="routeToOrder()"
  *ngIf="ui.previewOrder.visible"
  class="button button__primary button--small"
>
  Show Order
</button>

<button
  (click)="withdraw()"
  *ngIf="ui.withdraw.visible"
  class="button button__primary button--small"
>
  Withdraw
</button>

<button
  *ngIf="ui.csvReport.visible"
  routerLink="./csv"
  [disabled]="ui.csvReport.disabled"
  class="button button__primary button--small"
>
  CSV report
</button>

<a
  *ngIf="ui.csvReportUrl.visible"
  [href]="filesUrl + campaign.archiveReportFile"
  class="button button__primary button--small"
>
  CSV report
</a>

<button
  routerLink="../new/{{ campaign._id }}"
  *ngIf="ui.order.visible"
  class="button button__primary button--small"
>
  Order
</button>

<button
  *ngIf="ui.archive.visible"
  (click)="archive()"
  class="button button__primary button--small"
>
  Archive
</button>


<button (click)="handleBackBtn()" class="button button__secondary button--small">
  Back
</button>
