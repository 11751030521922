import { Injectable } from '@angular/core';
import { AppDetails, WebsiteStatus } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class WebsiteDataService {
  getWebsiteStatus(websiteUrl: string): Observable<WebsiteStatus> {
    return MeteorObservable.call('websiteData.getWebsiteStatus', websiteUrl);
  }
}
