<mat-card class="flex">
    <div class="half">
        <app-detail-field
                label="App name"
                [content]="appDetails.appName | googleTrimAppName"
                >
        </app-detail-field>
    </div>
    <div class="half">
        <app-detail-field
                label="Developer URL"
                [content]="appDetails.developerUrl"
                [capitilizeContent]="false"
                >
        </app-detail-field>
    </div>
</mat-card>
