import 'meteor-client-side';
import 'accounts-base-client-side';
import 'accounts-password-client-side';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// if (environment.sentryDsn) {
//   Sentry.init({
//     dsn: environment.sentryDsn,
//   });
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
