import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { DspCampaign } from 'common/models/dsp-campaign';
import { MeteorObservable } from 'meteor-rxjs';
import { StaticStore } from '../../app/staticStore.service';
import { ScopedListing } from '../../common/classes';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-dsp-campaigns-list',
  templateUrl: './dsp-campaigns-list.component.html',
})
export class DspCampaignsListComponent extends ScopedListing implements OnChanges, OnInit, OnDestroy {
  public sort: 1 | -1 = -1;
  public sortField: string = 'createdAt';
  public dspCampaigns$: Observable<DspCampaign[]>;
  public config: PaginationInstance = {
    id: 'pagination',
    itemsPerPage: 10,
    currentPage: 1,
  };
  private networkVal: string = 'all';
  private searchVal: string = '';
  private dateRangeValues: Date[] = [];
  private subscriptions = new Subscription();

  constructor(protected staticStore: StaticStore) {
    super();
  }

  @Input() public set network(value: string) {
    if (this.networkVal !== value) {
      this.networkVal = value;
    }
  }

  @Input() public set search(value: string) {
    if (this.searchVal !== value) {
      this.searchVal = value;
    }
  }

  @Input() public set datesRange(ranges: Date[]) {
    if (ranges.length === 2) {
      this.dateRangeValues = [...ranges];
    } else {
      this.dateRangeValues = [];
    }
  }

  public get network(): string {
    return this.networkVal;
  }

  public get search(): string {
    return this.searchVal;
  }

  ngOnChanges() {
    this.loadCampaigns();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public pagination(page: number) {
    this.staticStore.setPage(page);
  }

  public changeSort(fieldName: string): void {
    if (this.sortField === fieldName) {
      this.sort = this.sort === -1 ? 1 : -1;
    } else {
      this.sort = -1;
    }
    this.sortField = fieldName;
    this.staticStore.setSortLabel(fieldName);
    this.staticStore.setSortOrder(this.sort);
    this.loadCampaigns();
  }

  private loadCampaigns(): void {
    this.sortField = this.staticStore.getSortLabel() !== '' ? this.staticStore.getSortLabel() : this.sortField;
    const filter: any =
      this.network !== 'all'
        ? {
            networkId: this.network,
          }
        : {};
    const customSortKeys = {
      totalCostPerPlay: 'averageCpp',
    };
    const normalizedSortField = customSortKeys[this.sortField] || this.sortField;
    const sortOptions = {
      [normalizedSortField]: this.sort,
    };
    this.dspCampaigns$ = MeteorObservable.call<DspCampaign[]>(
      'dspCampaigns.search',
      this.search,
      filter,
      sortOptions,
      this.dateRangeValues.map((date) => date.toUTCString())
    ).pipe(shareReplay());
    this.subscriptions.add(
      this.dspCampaigns$.pipe(debounceTime(100)).subscribe(() => {
        this.config.currentPage = this.staticStore.getPage();
      })
    );
  }
}
