import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { UserService } from 'common/services';

@Injectable()
export class FilledGuard implements CanActivate, CanLoad {
  constructor(private _userService: UserService) {}

  canLoad(): Promise<boolean> {
    return this._userService.checkIfFilled();
  }

  canActivate(): Promise<boolean> {
    return this._userService.checkIfFilled();
  }
}
