export interface StreamDuplicate {
  type: string;
  entity: string;
  stream: {
    id: string;
    name: string;
  };
  publisher: {
    id: string;
    name: string;
  };
}
