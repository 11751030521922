import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { CheckboxItem } from '.';

@Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
})
export class CheckboxGroupComponent implements OnChanges {
  @Input() options = Array<CheckboxItem>();
  @Input() selectedValues: string[];

  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() focusOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges() {
    if (this.selectedValues) {
      this.selectedValues.forEach((value) => {
        const element = this.options.find((x) => x.value === value);
        if (element) {
          element.checked = true;
        }
      });
    }
  }

  onToggle(event: Event, value: string) {
    const checkbox = event.target as HTMLInputElement;
    this.setCheckboxes(value && checkbox.checked ? value : '');
    this.selectedValues = checkbox.checked ? [value] : [];
    this.toggle.emit(this.selectedValues);
  }

  onFocusOut() {
    this.focusOut.emit(true);
  }

  private setCheckboxes(checkbox: string) {
    this.options.forEach((option) => {
      option.checked = checkbox === option.value;
    });
  }
}
