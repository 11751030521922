<div class="modal__header">
  <h4 class="modal__header__title">Verify Audiopixel</h4>
  <button type="button" class="modal__header__close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal__body">
  <h6>Steps:</h6>
  <ol>
    <li class="mb-2">
      Open <a href="{{ url }}" target="_blank" rel="noopener">{{ url }}</a><br>
      and listen to the radio for at least 1 second.
    </li>
    <li class="mb-2">
      In the same browser open a website where Audiopixel is embedded. Wait until a page is fully loaded.
    </li>
    <li>Click the "Verify Now" button below.</li>
  </ol>
</div>
<div class="modal__footer">
  <button type="button" class="button button__secondary button--small" (click)="close()" *ngIf="!advTracking?._id && !failed">Cancel</button>
  <button
    type="button"
    [ngClass]="{
      'button button__primary button--small': !advTracking?._id && !failed,
      'info-btn info-btn-pass': advTracking?._id,
      'info-btn info-btn-fail': failed
    }"
    [disabled]="advTracking || failed"
    (click)="check()"
  >
    <span *ngIf="!advTracking?._id && !failed">Verify now</span>
    <span *ngIf="advTracking?._id">PASS</span>
    <span *ngIf="failed">FAIL</span>
  </button>
  <p *ngIf="advTracking?._id" class="text-center mt-2">
    Google Analytics integration <span *ngIf="!checkExtId(advTracking?.extId)">not</span> enabled.
  </p>
</div>
