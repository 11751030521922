import { Injectable } from '@angular/core';
import { MobileSdk } from 'common/models/mobile-sdk';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class MobileSdkService {
  constructor() {}

  public getMobileSdk(): Observable<MobileSdk[]> {
    return MeteorObservable.call('mobile-sdk.getMobileSdk');
  }
}
