import { Component, OnInit } from '@angular/core';
import { TrackingService } from 'common/services/tracking.service';
import { ModalComponent } from 'common/classes';
import { environment } from '../../environments/environment';
import { AdvertiserTracking } from 'common';

@Component({
  selector: 'app-modal-check-pixel',
  styleUrls: ['./modal-check-pixel.component.scss'],
  templateUrl: './modal-check-pixel.component.html',
})
export class ModalCheckPixelComponent extends ModalComponent implements OnInit {
  public pixelId: string;
  public advTracking: AdvertiserTracking;
  public failed = false;
  public url = environment.demoPlayer;

  constructor(private trackingService: TrackingService) {
    super();
  }

  ngOnInit() {}

  public async check() {
    try {
      this.advTracking = await this.trackingService.checkAdvertiserTrackingPixel(this.pixelId);
      if (!this.advTracking) {
        this.failed = true;
      }
    } catch (err) {
      this.close();
    }
  }

  public checkExtId(extId: string) {
    return /\d+\.{1}\d+/g.test(extId);
  }

  close() {
    this._closeModal(false);
  }
}
