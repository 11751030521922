import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'common/classes';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-date-range',
  templateUrl: './modal-date-range.component.html',
})
export class ModalDateRangeComponent extends ModalComponent implements OnInit {
  dayOffset: any = 1;
  data: any;
  dates: Date[] = [];
  startDate: moment.Moment = moment();
  single: boolean = false;

  ngOnInit() {
    setTimeout(() => {
      this.dates = this.data.filter((d: any) => d) || [];
    }, 0);
  }

  pickDates(dates: Date[]) {
    this.dates = dates;
  }
  isValid() {
    return this.dates.length === (this.single ? 1 : 2);
  }
  save() {
    this._closeModal(this.dates);
  }

  close() {
    this._closeModal(false);
  }
}
