export interface Playout {
  _id?: string;
  playedAt: Date;
  campaignId: string;
  listenerId: string;
  listenerInfo?: any;
  publisherId: string;
  advertiserId: string;
  cost: number;
  position: number;
}
