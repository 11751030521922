import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

import { filesUrl } from 'common/config';
import { Campaign, UserRole, CampaignStatus } from 'common/models';
import { StaticStore } from 'app/staticStore.service';
import { CampaignTransaction, CampaignsService, ModalService, PaymentService, ToastService } from 'common/services';

type TAction = {
  label: string;
  status: string;
};

interface UIElementProps {
  visible?: boolean;
  disabled?: boolean;
  [propName: string]: boolean;
}

export interface UI {
  [propName: string]: UIElementProps;
}

interface IContext {
  isAdmin: boolean;
  campaign: Campaign;
  statuses: CampaignStatus[];
}

type UIElements = Record<string, (ctx: IContext) => UIElementProps>;

@Component({
  selector: 'app-campaign-actions-bar',
  templateUrl: './actions-bar.component.html',
})
export class CampaignActionsBarComponent implements OnInit, OnChanges {
  @Input() campaign: Campaign;

  isAdmin: boolean = false;
  isAdvertiser: boolean = false;
  statuses: CampaignStatus[] = [];
  actions: TAction[] = [];
  filesUrl: string = filesUrl;

  ui: UI = {};

  private uiElements: UIElements = CampaignActionsBarComponent.createUIElements();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staticStore: StaticStore,
    private campaignService: CampaignsService,
    private paymentService: PaymentService,
    private toast: ToastService,
    private modalService: ModalService
  ) {}

  public ngOnInit() {}
  public ngOnChanges(event: SimpleChanges): void {
    this.updateState(event.campaign.currentValue as Campaign);
  }

  public withdraw() {
    const campaignClone: Campaign = { ...this.campaign };
    campaignClone.status = CampaignStatus.Draft;
    this.campaignService.update(campaignClone).then((res) => {
      if (res) {
        this.paymentService
          .removeOrder(this.campaign._id)
          .pipe(take(1))
          .subscribe(
            (isRemoved: boolean) => {
              if (!!isRemoved) {
                this.toast.success('Withdraw success');
                this.campaign.status = CampaignStatus.Draft;
              }
            },
            (err) => {
              this.toast.error('Withdraw failed');
            }
          );
      } else {
        this.toast.error('Withdraw failed');
      }
    });
  }

  public copyCampaign() {
    this.campaignService
      .copy(this.campaign)
      .pipe(take(1))
      .subscribe(
        (data: CampaignTransaction) => {
          this.toast.success('The campaign has been copied.');
          this.router.navigate([`advertiser/campaigns/${data.campaignId}/edit`]);
        },
        (err) => {
          this.meteorErrorHandler(err);
        }
      );
  }

  public async archive() {
    try {
      const confirmed = await this.modalService.openConfirmModal({
        text: `Do you want archive campaign: ${this.campaign.name}?`,
      });
      if (confirmed) {
        await this.setStatus(CampaignStatus.Archived, false);
        this.toast.success('The campaign has been archived.');
      }
    } catch (err) {
      this.meteorErrorHandler(err);
    }
  }

  public handleBackBtn() {
    const backRoute = this.staticStore.getListingRoute();
    this.router.navigate([backRoute !== '' ? backRoute : '../'], { relativeTo: this.route });
  }

  public async setStatus(status: CampaignStatus, addUpdateToast: boolean = true): Promise<void> {
    const campaign = Object.assign({}, this.campaign, { status });
    try {
      await this.campaignService.update(campaign, addUpdateToast);
      this.campaign = campaign;
      this.updateState(campaign);
    } catch (error) {}
  }

  public routeToOrder() {
    this.router.navigate([`${this.router.url.match(/\w+/)[0]}/orders/${this.campaign._id}`]);
  }

  private updateState(campaign: Campaign): void {
    const user: any = Meteor.user();
    const isAdmin = user.roles.includes(UserRole.Admin);

    this.actions = this.getCurrentActions(campaign);
    this.statuses = this.getCurrentStatuses(campaign, isAdmin);

    this.ui = this.updateUIState(campaign, this.statuses, isAdmin);
  }

  private getCurrentStatuses(campaign: Campaign, isAdmin: boolean): CampaignStatus[] {
    switch (campaign.status) {
      case CampaignStatus.Active:
        return [CampaignStatus.Closed, CampaignStatus.Paused];
      case CampaignStatus.Paused:
        return [CampaignStatus.Closed, CampaignStatus.Active];
      case CampaignStatus.Closed:
        if (isAdmin && moment().isBefore(campaign.endDate)) {
          return [CampaignStatus.Active];
        }
    }
    return [];
  }

  private getCurrentActions(campaign: Campaign): TAction[] {
    switch (campaign.status) {
      case CampaignStatus.Pending:
        return [
          {
            status: CampaignStatus.Active,
            label: 'Approve',
          },
          {
            status: CampaignStatus.Rejected,
            label: 'Reject',
          },
        ];
      case CampaignStatus.Rejected:
        return [
          {
            status: CampaignStatus.Pending,
            label: 'Pending',
          },
        ];
    }
    return [];
  }
  private updateUIState(campaign: Campaign, statuses: CampaignStatus[], isAdmin: boolean): UI {
    const ctx = {
      campaign,
      statuses,
      isAdmin,
    };
    const DEFAULT_PROPS = {
      visible: false,
      disabled: false,
    };
    return Object.entries(this.uiElements).reduce((acc, [name, handler]) => {
      return { ...acc, [name]: { ...DEFAULT_PROPS, ...handler(ctx) } };
    }, {});
  }

  private meteorErrorHandler(error: Meteor.Error): void {
    if (error.reason) {
      this.toast.error(error.reason);
    }
    if (error.error) {
      if (typeof error.error === 'string') {
        this.toast.error(error.error);
      } else {
        Object.keys(error.error).forEach((k) => {
          this.toast.error(error.error[k]);
        });
      }
    }
  }

  private static createUIElements(): UIElements {
    return {
      actions: ({ isAdmin, campaign: { status } }: IContext): UIElementProps => {
        if (isAdmin) {
          const showActions = [CampaignStatus.Pending, CampaignStatus.Rejected].includes(status);
          if (showActions) {
            return { visible: true };
          }
        }
      },
      statuses: ({ isAdmin, campaign: { status, endDate }, statuses }: IContext): UIElementProps => {
        const isArchived = status == CampaignStatus.Archived;
        const props: UIElementProps = {};
        if (statuses.length) {
          props.visible = true;
        }

        if (isAdmin && !statuses.length) {
          const canReactivate = moment(endDate).isBefore(moment());
          props.reactivate = canReactivate && !isArchived;
          props.disabled = canReactivate || isArchived;
        }

        if (isArchived) {
          props.archived = true;
        }
        return props;
      },
      edit: ({ isAdmin, campaign: { status } }: IContext): UIElementProps => ({
        visible:
          isAdmin || ![CampaignStatus.Closed, CampaignStatus.Rejected, CampaignStatus.Processing].includes(status),
        disabled: status === CampaignStatus.Archived,
      }),
      impression: ({ campaign: { status } }: IContext): UIElementProps => ({
        disabled: status === CampaignStatus.Archived,
      }),
      copy: ({ isAdmin, campaign: { status } }: IContext): UIElementProps => ({
        visible: !isAdmin,
      }),
      previewOrder: ({ campaign: { status } }: IContext): UIElementProps => ({
        visible: status === CampaignStatus.Pending,
      }),
      withdraw: ({ campaign: { status } }: IContext): UIElementProps => ({
        visible: status !== CampaignStatus.Archived,
      }),
      csvReport: ({ campaign: { status, archiveReportFile } }: IContext): UIElementProps => {
        if (status === CampaignStatus.Archived) {
          return {
            visible: !archiveReportFile,
            disabled: !archiveReportFile,
          };
        }
        return {
          visible: true,
        };
      },
      csvReportUrl: ({ campaign: { status, archiveReportFile } }: IContext): UIElementProps => ({
        visible: status === CampaignStatus.Archived && !!archiveReportFile,
      }),
      order: ({ isAdmin, campaign: { status } }: IContext): UIElementProps => ({
        visible: !isAdmin && status === CampaignStatus.Draft,
      }),
      archive: ({ isAdmin, campaign: { status } }: IContext): UIElementProps => ({
        visible: isAdmin && [CampaignStatus.Closed].includes(status),
      }),
    };
  }
}
