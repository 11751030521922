import { Component, OnInit, Input } from '@angular/core';
import { User } from 'common/models';
import { Countries } from 'common/collections';

@Component({
  selector: 'app-company-field',
  templateUrl: './company-field.component.html',
  styleUrls: ['./company-field.component.scss'],
})
export class CompanyFieldComponent implements OnInit {
  @Input() user: User;
  country: string;
  constructor() {}

  ngOnInit() {
    const countryCode = this.user.countryCode || null;
    const country = Countries.findOne({ code: this.user.countryCode });
    this.country = countryCode && country ? country.name : null;
  }
}
