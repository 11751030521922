import { switchMap, take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService, CampaignsService, User, UserRole, UserStatus, DspCampaign } from 'common';
import { filesUrl } from 'common/config';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-dsp-campaign-edit',
  templateUrl: './dsp-campaign-edit.component.html',
  styleUrls: ['./dsp-campaign-edit.component.scss'],
})
export class DspCampaignEditComponent implements OnInit {
  public dspCampaign: DspCampaign;
  public filesUrl = `${filesUrl}/`;
  public dspCampaignDuration: string;
  public autosuggestItems;
  public formGroup: FormGroup;
  public errors: any = {};
  public advertisers: User[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private campaignsService: CampaignsService
  ) {}

  ngOnInit() {
    this.autosuggestItems = this.campaignsService.getAutosuggestItems();
    const campaign$ = this.route.params.pipe(
      switchMap((params) => {
        return MeteorObservable.call('dspCampaigns.get', params.id) as Observable<DspCampaign>;
      })
    );

    {
      // Isolate user varriable to remove with GC
      const user: User = (Meteor.user() as any) as User; // HACK! Cast Meteor.user to correct interface.
      if (user.roles.includes(UserRole.Admin)) {
        this.advertisers = (Meteor.users
          .find({
            roles: UserRole.Advertiser,
            status: UserStatus.Active,
          })
          .fetch() as any) as User[];
      }
    }

    campaign$.pipe(take(1)).subscribe({
      next: (dsp: DspCampaign) => {
        if (dsp) {
          this.formGroup = new FormBuilder().group({
            advertiserId: [dsp.advertiserId || 'null'],
            genders: [dsp.genders || []],
            gendersExcludeFlag: [dsp.gendersExcludeFlag || false],
            ageRangeIds: [dsp.ageRangeIds || []],
            ageRangeExcludeFlag: [dsp.ageRangeExcludeFlag || false],
            interestIds: [dsp.interestIds || []],
            intentIds: [dsp.intentIds || []],
            brandIds: [dsp.brandIds || []],
            eventIds: [dsp.eventIds || []],
          });
          this.dspCampaign = dsp;
        }
      },
      error: () => {
        this.toastService.error('DSP Campaign not found!');
        this.router.navigate(['../..'], { relativeTo: this.route });
      },
    });
  }

  update() {
    this.dspCampaign = { ...this.dspCampaign, ...this.formGroup.value };
    // Overwrite string null to null. HTML Forms only returns strings.
    if (this.dspCampaign.advertiserId === 'null') {
      this.dspCampaign.advertiserId = null;
    }
    MeteorObservable.call('dspCampaigns.update', this.dspCampaign)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.success('DSP Campaign updated.');
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        error: () => {
          this.toastService.error('Error occurred when updating DSP Campaing');
          this.router.navigate(['..'], { relativeTo: this.route });
        },
      });
  }
}
