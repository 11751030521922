<ng-container [formGroup]="form">
    <div
        class="form-group"
        [ngClass]="{ 'has-error': isControlInvalid(websiteControlStatus.control) }"
        >
        <app-form-label
        label="Website"
        msg="Website containing stream player or app. For mobile applications as the website, please provide exactly the same website address as included on the developer webpage in the Apple App Store or Android Google Play Store."
        inputId="website"
        ></app-form-label>


        <div class="input-group flex-input" >
            <input
            type="text"
            class="form-control"
            id="website"
            placeholder="Website url starting with http:// or https://"
            formControlName="website"
        />


        <ng-container *ngIf="{ websiteStatus: websiteControlStatus.details$ | async } as data">
            <div class="input-group-append" *ngIf="websiteControlStatus.isLoading || isWebsiteStatusValid(data.websiteStatus)" >
              <span class="input-group-text">
                <span *ngIf="websiteControlStatus.isLoading"><img class="loader"/></span>
                <mat-icon *ngIf="isWebsiteStatusValid(data.websiteStatus)" class="green-checkmark">done</mat-icon>
              </span>
            </div>
        </ng-container>
        </div>
        
        
            <small class="form-text text-error" *ngIf="websiteControlStatus.control.errors?.pattern">
                Wrong URL format, link should start with http:// or https://
            </small>
            <small class="form-text text-error" *ngIf="websiteControlStatus.control.errors?.required">
                Website URL is required
            </small>
            <small class="form-text text-error" *ngIf="websiteControlStatus.control.errors?.websiteStatusFail">
                Website is unreachable or forbidden to access
            </small>
    </div>
    <div></div>
</ng-container>
