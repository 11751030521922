import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Streams, AutopromoteAds } from 'common/collections';
import { TimeRangeDay, TimeRange, UserRole, AutopromoteAdStatus, AutopromoteAd, ISOLanguage } from 'common/models';
import { AutopromoteAdsService } from 'common/services';
import { filesUrl } from 'common/config';
import { StaticStore } from 'app/staticStore.service';

@Component({
  selector: 'app-autopromote-ad',
  templateUrl: './autopromote-ad.component.html',
  styleUrls: ['./autopromote-ad.component.scss'],
})
export class AutopromoteAdComponent implements OnInit, OnDestroy {
  isAdmin = false;
  showActions = false;
  ad: AutopromoteAd;
  adSub: Subscription;
  statuses: AutopromoteAdStatus[] = [];
  id: string;
  filesUrl: string = `${filesUrl}`;
  backRoute: string = '';
  languages: string[] = ['N/A'];

  constructor(
    private _route: ActivatedRoute,
    private _cdRef: ChangeDetectorRef,
    private _router: Router,
    private _autopromoteAdsService: AutopromoteAdsService,
    private staticStore: StaticStore
  ) {}

  ngOnInit() {
    this.backRoute = this.staticStore.getListingRoute();
    this._route.params.subscribe((params) => {
      this.id = params.id;
      this.adSub = AutopromoteAds.find({ _id: this.id }).subscribe((data) => {
        if (data && data.length) {
          this.ad = data[0];
          const user: any = Meteor.user();
          const availableStatuses: AutopromoteAdStatus[] = [AutopromoteAdStatus.Active, AutopromoteAdStatus.Paused];
          const availableToChangeStatus = availableStatuses.includes(this.ad.status);
          if (availableToChangeStatus) {
            switch (this.ad.status) {
              case AutopromoteAdStatus.Active:
                this.statuses = [AutopromoteAdStatus.Closed, AutopromoteAdStatus.Paused];
                break;
              case AutopromoteAdStatus.Paused:
                this.statuses = [AutopromoteAdStatus.Closed, AutopromoteAdStatus.Active];
                break;
            }
          }
          if (user.roles.includes(UserRole.Admin)) {
            this.isAdmin = true;
            this.showActions = this.ad.status === AutopromoteAdStatus.Pending;
          } else if (user.roles.includes(UserRole.Publisher)) {
            this.isAdmin = false;
          }
          this.initLanguagesList(this.ad.languages || []);
          this._cdRef.detectChanges();
        } else {
          this._router.navigate(['../../']);
        }
      });
    });
  }
  getStream(id: string): string {
    return Streams.findOne(id).broadcastName;
  }
  getPlayTimes(tr: TimeRange[]) {
    const daysMap: string[] = [];
    Object.keys(TimeRangeDay)
      .filter((day) => isNaN(Number(day))) // leave strings only, because enum contains both numbers and strings
      .forEach((k, i) => {
        daysMap[TimeRangeDay[k]] = k;
      });
    let timeRanges: any[] = [];
    tr.forEach((v) => {
      if (!timeRanges[v.day]) {
        timeRanges[v.day] = {
          label: daysMap[v.day],
          day: v.day,
          values: [v],
        };
      } else {
        timeRanges[v.day].values.push(v);
      }
    });

    timeRanges = timeRanges.filter((v) => v);

    timeRanges.forEach((v, i) => {
      timeRanges[i].values.sort((a, b) => a.from - b.from);
    });
    timeRanges.sort((a, b) => (a.day === 0 ? 1000 : a.day - b.day));
    return timeRanges;
  }
  getPlayTimeLabel(tr: TimeRange) {
    return `${tr.from < 10 ? '0' + tr.from : tr.from}:00 - ${tr.to < 10 ? '0' + tr.to : tr.to}:00`;
  }
  async setStatus(status: AutopromoteAdStatus) {
    const ad = Object.assign({}, this.ad, { status });
    try {
      await this._autopromoteAdsService.update(ad);
    } catch (error) {}
  }
  private initLanguagesList(codes = []) {
    if (codes.length) {
      this.staticStore.getLanguageList().subscribe((languages: ISOLanguage[]) => {
        this.languages = languages.reduce((acc, language) => {
          if (codes.includes(language.ISO639_1)) {
            acc.push(language.name);
          }
          return acc;
        }, []);
      });
    }
  }
  ngOnDestroy() {
    if (this.adSub) {
      this.adSub.unsubscribe();
    }
  }
}
