import { IListingScopes, StaticStore } from '../../app/staticStore.service';
import { Subscription } from 'rxjs';
import { OnInit, OnDestroy, Directive } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Directive()
export class ScopedListing implements OnInit, OnDestroy {
  public listingAttributeName: keyof IListingScopes;
  protected staticStore: StaticStore;
  public listingScope: IListingScopes;
  public listingScopesSub: Subscription;
  public config: PaginationInstance;
  public onScopeChange: () => void;

  get scope(): IListingScopes[keyof IListingScopes] {
    if (!this.listingScope || Object.keys(this.listingScope).length <= 0) {
      return 'all';
    }
    return this.listingScope[this.listingAttributeName];
  }

  set scope(value: IListingScopes[keyof IListingScopes]) {
    this.staticStore.setPage(1);
    this.staticStore.listingScopes.next({ ...this.listingScope, [this.listingAttributeName]: value });
  }

  public ngOnDestroy() {
    this.listingScopesSub.unsubscribe();
  }

  public ngOnInit() {
    this.listingScopesSub = this.staticStore.listingScopes.subscribe((scopes) => {
      this.listingScope = scopes;
      if (this.config && this.config.currentPage) {
        this.config.currentPage = this.staticStore.getPage();
      }
      if (this.onScopeChange) {
        this.onScopeChange();
      }
    });
  }
}
