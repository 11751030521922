import { AutopromoteAdStatus } from './autopromote-ad-status';
import { TimeRange } from './time-range';

export interface AutopromoteAd {
  _id?: string;
  createdAt?: Date;
  publisherId?: string;
  streamId?: string;
  name?: string;
  languages?: string[];
  startDate?: Date;
  endDate?: Date;
  fileId?: string;
  status?: AutopromoteAdStatus;
  playTimes?: TimeRange[];
  files?: { [attr: string]: string };
  averagePosition?: number;
  uniqueListeners?: number;
}
