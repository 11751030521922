export interface Statistics {
  _id?: string;
  date: Date;
  publishers: number;
  advertisers: number;
  campaigns: number;
  avgDailyListeners: number;
  avgCPP: number;
  totalPlayouts: number;
  revenue: number;
  payout: number;
  profit: number;
  amillionads: number;
  adform: number;
  xaxis: number;
  smartads: number;
  triton: number;
  totalAvailablePlayouts: number;
  availablePlayoutsByCountry: QuantitativeEntity[];
  totalSoldPlayouts: number;
  soldPlayouts: QuantitativeEntity[];
  integratedStreams?: number;
}

export interface QuantitativeEntity {
  _id?: string;
  dspName?: string;
  countryCode: string;
  counted: number;
}
