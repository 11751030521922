<div class="datepicker">
  <div class="datepicker__header">
    <span class="datepicker__header__arrow datepicker__header__arrow--left" (click)="changeMonth(-1)">
      <img src="assets/images/icons/dropdown.svg" alt="" />
    </span>
    <span class="datepicker__header__title">{{ currDate | amDateFormat: 'MMMM YYYY' }}</span>
    <span class="datepicker__header__arrow datepicker__header__arrow--right" (click)="changeMonth(1)">
      <img src="assets/images/icons/dropdown.svg" alt="" />
    </span>
  </div>
  <div class="datepicker__content">
    <div class="datepicker__content__item label" *ngFor="let label of labels">
      <span>{{ label }}</span>
    </div>
    <div
      class="datepicker__content__item"
      [ngClass]="[item.month !== 0 ? 'muted' : '', className(item)]"
      *ngFor="let item of datesArray"
      (click)="selectDay(item)"
    >
      <span>{{ item.dayInMonth }}</span>
    </div>
  </div>
</div>
