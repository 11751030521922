<div class="detail-field">
  <p class="detail-field__label">
    {{ label | capitalize }}
  </p>
  <div class="detail-field__value" *ngIf="isArray && content.length">
    <span class="detail-field__value__tag" *ngFor="let item of content">
      {{ item | capitalize }}
    </span>
  </div>
  <div class="detail-field__value" *ngIf="isArray && !content.length">
    {{ emptyArrayPlaceholder | capitalize }}
  </div>
  <div class="detail-field__value" *ngIf="label === 'Email'">{{ content }}</div>
  <div class="detail-field__value" *ngIf="label !== 'Email' && !isArray" (mouseenter)="resize(true)" (mouseleave)="resize(false)">
    {{ (capitilizeContent ? (content | capitalize) : content) | truncate: [displayLength, ''] }}
    <p *ngIf="displayLength == 300 && displayLimit > 300">...</p>
  </div>
  <div class="detail-field__image" *ngIf="image">
    <a *ngIf="imageLink" [href]="imageLink" target="_blank">
      <img [src]="image" />
    </a>
    <img *ngIf="!imageLink" [src]="image" />
  </div>
  <div class="detail-field__value detail-field__value--small" *ngIf="subContent">
    {{ subContent | capitalize }}
  </div>
</div>
