import { Pipe, PipeTransform } from '@angular/core';

/*
 * Sorts any entity of field having numerical value
 *
 * Usage:
 *   values | sortByValue:field:sortOrder
 * Example:
 *   {{ streams | sortByValue: 'totalListeningTime' : -1 }}
 *   sorted by field according to sortOrder where -1 is descending and 1 is ascending
 */

@Pipe({ name: 'sortByValue' })
export class SortByValue implements PipeTransform {
  transform(array: any[], field: string, sortOrder?: number): any[] {
    if (!array || !array.length) {
      return [];
    }
    array.sort((a: any, b: any): number => {
      if (a[field] < b[field]) {
        return !!sortOrder ? -sortOrder : -1;
      } else if (a[field] > b[field]) {
        return !!sortOrder ? sortOrder : 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
