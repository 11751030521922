import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { formatOutput } from 'common/utils';
import { Subscription } from 'rxjs';

type TRowValues = Record<string, string | number>;

@Component({
  selector: 'app-input-with-select',
  templateUrl: './input-with-select.component.html',
  styleUrls: ['./input-with-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithSelectComponent),
      multi: true,
    },
  ],
})
export class InputWithSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() inputType: string = 'text';
  @Input() inputTypeText: string = undefined;
  @Input() inputKey: string = '';
  @Input() inputPlaceholder: string = '';
  @Input() inputDefaultValue: any = undefined;
  @Input() inputRequiredErrorMsg: any = undefined;
  @Input() inputValidationErrorMsg: any = undefined;
  @Input() inputMinValue: number = 0.01;
  @Input() inputMaxValue: number = 100000; // high value to avoid problems with Indonesian Rupiah's

  @Input() optionKey: string = '';
  @Input() selectDefaultOption: string = 'Default Option';
  @Input() selectRequiredErrorMsg: any = undefined;
  @Input() selectOptions: Record<string, string>[] = [];
  @Input() removeOptionIfSelected: boolean = false;

  @Input() displayFormat: string = '';
  @Input() displayKey: string = '';
  @Input() valueKey: string = '';

  @Input() sortFn: (a: Record<string, string>, b: Record<string, string>) => number;

  public options: Record<string, string>[][] = [];
  public allSelectedOptions: string[] = [];

  public formGroup: FormGroup = new FormGroup({ formInputPairArray: new FormArray([]) });
  public formInputPairArray: FormArray;
  public sub: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.formInputPairArray = this.formGroup.get('formInputPairArray') as FormArray;
    this.sub = this.formInputPairArray.valueChanges.subscribe((els) => {
      this.allSelectedOptions = Array.from(new Set(els.map((el) => el[this.optionKey]).filter(Boolean)));
      this._onChange(els);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  _onChange = (_: any) => {};
  _onTouchedFn = () => {};

  writeValue(data: TRowValues[]) {
    if (data.length) {
      data.forEach((el) => this.addPairToFormGroup(el));
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouchedFn = fn;
  }

  public addPairToFormGroup(data?: TRowValues) {
    const values = this.getDefaultRowValues(data);
    this.options.push(this.selectOptions.slice().filter(({ code }) => !this.allSelectedOptions.includes(code)));
    const inputValue = values[this.inputKey];
    const optionValue = values[this.optionKey];
    const group = new FormGroup({
      [this.inputKey]: new FormControl(
        inputValue,
        this.inputType === 'number'
          ? [
              Validators.required,
              Validators.min(Number(this.inputMinValue)),
              Validators.max(Number(this.inputMaxValue)),
            ]
          : []
      ),
      [this.optionKey]: new FormControl(optionValue, [Validators.required]),
    });
    this.formInputPairArray.push(group);
  }

  public removePairFromFormGroup(index: number): void {
    this.formInputPairArray.removeAt(index);
    const removed = this.options.splice(index, 1)[0];
    if (this.removeOptionIfSelected) {
      // fill else options with removed values
      if (removed?.length) {
        for (let i = 0; i < this.options.length; i++) {
          this.options[i] = Array.from(new Set(this.options[i].concat(removed)));
          if (this.sortFn) {
            this.options[i].sort(this.sortFn);
          }
        }
      }
    }
  }

  public updateOptions(value: string | number, idx: number) {
    if (this.removeOptionIfSelected && value !== '') {
      const elementsToMerge = this.selectOptions.slice().filter((el) => !this.allSelectedOptions.includes(el.code));
      for (let i = 0; i < this.options.length; i++) {
        if (i !== idx) {
          const elIdx = this.options[i].findIndex(
            (els) => (typeof value === 'number' ? value.toString() : value) === els.code
          );
          if (elIdx !== -1) {
            this.options[i].splice(elIdx, 1);
          }
          this.options[i] = Array.from(new Set(this.options[i].concat(elementsToMerge)));
          if (this.sortFn) {
            this.options[i].sort(this.sortFn);
          }
        }
      }
    }
  }

  public isInvalid(idx: number, name: string) {
    // @ts-ignore - because angular doesn't see nested controls
    const control = this.formInputPairArray.controls[idx].controls[name];
    return control.invalid && control.touched;
  }

  public formatOutput(item: Record<string, any>): string | undefined {
    return formatOutput(item, this.displayFormat);
  }

  public copyToAll() {
    const numberOfGroups = this.formInputPairArray.length;
    const firstInputValue = this.getInputValueFromFirstGroup(this.inputKey);
    if (numberOfGroups === 1) {
      this.removePairFromFormGroup(0);
      this.selectOptions.forEach(({ code }, idx) => {
        this.addPairToFormGroup({
          [this.inputKey]: firstInputValue,
          [this.optionKey]: code,
        });
        this.updateOptions(code, idx);
      });
    } else {
      this.formInputPairArray.controls.forEach((group) => {
        group.patchValue({
          [this.inputKey]: firstInputValue,
        });
      });
    }
  }
  public canCopyToAll(): boolean {
    return this.formInputPairArray.controls.length > 0;
  }
  public canAddNextGroup(): boolean {
    return this.formInputPairArray.length < this.selectOptions.length;
  }

  private getDefaultRowValues(data?: TRowValues): TRowValues {
    const nextOption = this.selectOptions.find(({ code }) => !this.allSelectedOptions.includes(code));
    const defaultOption = nextOption ? nextOption.code : '';
    const inputValue = typeof data !== 'undefined' ? data[this.inputKey] : this.inputDefaultValue;
    const optionValue = typeof data !== 'undefined' ? data[this.optionKey] : defaultOption;
    return {
      [this.inputKey]: inputValue,
      [this.optionKey]: optionValue,
    };
  }

  private getInputValueFromFirstGroup(name: string): string {
    if (this.formInputPairArray.controls.length) {
      return this.formInputPairArray.controls[0].value[name] || '';
    }
    throw new Error('Not found any group');
  }
}
