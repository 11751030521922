export interface Setting {
  _id?: string;
  value?: any;
  billingName?: string;
  vatNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postcode?: string;
  countryCode?: string;
  vatRate?: number;
  bankAccountGbp?: string;
  bankAccountUsd?: string;
  bankAccountEur?: string;
  bankAccountPln?: string;
}
