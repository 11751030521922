import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AudienceService, MeasureAudienceData, ToastService } from 'common';
import { Campaign } from 'common/models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-calculate-audience',
  templateUrl: './calculate-audience.component.html',
})
export class CalculateAudienceComponent implements OnInit {
  public costs: Record<string, any> = null;
  public costPerPlay: number = 0;
  public audience: number = null;
  public audiencePartial: number = null;
  public audienceLoading: boolean = false;
  public showReloadBtn: boolean = true;
  public showCalculations: boolean = true;
  public calcAudienceCondition: boolean = true;

  @Output() public tickEvent = new EventEmitter<boolean>();

  @Input() public campaign: Campaign;

  @Input() public set showEstExpAudineceCalc(condition: boolean) {
    this.showCalculations = condition;
  }

  @Input() public set showRecalculeteBtn(condition: boolean) {
    this.showReloadBtn = condition;
  }

  @Input() public set targetingCosts(costs: Record<string, any>) {
    this.costs = costs;
  }

  @Input() public set formCostPerPlay(cost: number) {
    this.costPerPlay = cost;
  }

  public get showEstExpAudineceCalc() {
    return this.showCalculations;
  }

  public get showRecalculeteBtn() {
    return this.showReloadBtn;
  }

  public get targetingCosts() {
    return this.costs;
  }

  public get formCostPerPlay() {
    return this.costPerPlay;
  }

  constructor(private audienceService: AudienceService, private toast: ToastService) {}

  ngOnInit() {
    this.calculateAudience(this.showCalculations);
  }

  public calculateAudience(isForced: boolean) {
    if (!isForced) {
      return;
    }
    this.tickEvent.emit(true);
    this.audienceLoading = true;
    this.audienceService
      .measureAudience(this.campaign)
      .pipe(take(1))
      .subscribe({
        next: (audienceData: MeasureAudienceData) => {
          this.audienceLoading = false;
          this.audience = Math.floor(audienceData.exact) || 0;
          this.audiencePartial = Math.floor(audienceData.partial) || 0;
        },
        error: (err: Error) => {
          this.audienceLoading = false;
          this.toast.error('Error has occurred');
        },
      });
  }

  public calculateCPP() {
    const cpp = this.formCostPerPlay ? this.formCostPerPlay : 0;
    return (
      (this.costs && this.costs.totalTargetingCost ? this.costs.totalTargetingCost : 0) +
      (parseFloat(('' + cpp).replace(',', '.')) || 0)
    );
  }
}
