import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: ` <span>{{ msg }}</span> `,
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() msg: string;

  constructor(private _el: ElementRef) {}
}
