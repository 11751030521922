import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { IntegrationCheck } from 'common';
import { ModalComponent } from 'common/classes';
import { StreamService, ToastService } from 'common/services';

@Component({
  selector: 'app-modal-integrations-status',
  templateUrl: './modal-integrations-status.component.html',
  styleUrls: ['./modal-integrations-status.component.scss'],
})
export class ModalIntegrationsStatusComponent extends ModalComponent implements OnInit {
  public streamId: string;
  public streamName: string;
  public integrationType: string;
  public integration: IntegrationCheck;
  public integrationDate: string;
  public integrationTests: { key: string; value: boolean }[];
  public platform: string[];

  constructor(private toast: ToastService, private streamService: StreamService) {
    super();
  }
  ngOnInit() {
    if (this.integration) {
      this.integrationDate = moment(this.integration.createdAt).format('MMM DD YYYY HH:mm');
      const integrationClone = { ...this.integration };
      for (const name of [
        '_id',
        'streamId',
        'createdAt',
        'status',
        'AdsTxtPassGroups',
        'appleTxtPassGroups',
        'googleTxtPassGroups',
      ]) {
        delete integrationClone[name];
      }
      Object.keys(integrationClone).forEach((key) => integrationClone[key] == null && delete integrationClone[key]);
      const integrationArray = Object.keys(integrationClone).map((key) => ({ key, value: integrationClone[key] }));
      this.integrationTests = integrationArray;
    }
  }

  close() {
    this._closeModal(false);
  }
  public labelToDescription(input: string): string {
    const labels = {
      PlayoutPass: 'At least one playout in last 7 days (optional)',
      RejectedPlayoutsPass: 'No playouts were sent too early',
      TrackingCodePass: 'AdTonos JS Tracking code is in the source code of website (optional)',
      StreamingUrlPass: 'AdTonos streaming URL is in the source code of website (optional)',
      AdsTxtPass: 'ads.txt file contains AdTonos entries',
      AdsTxtDspsEntriesPass: 'ads.txt file contains DSP partners entries (optional)',
      PublisherTrackingsPass: 'AdTonos JS Tracking send data in last 7 days (optional)',
      AudiencePass: 'At least one listener connected to the stream in last 7 days',
      AdBreakPass: 'At least one ad break detected to the stream in last 7 days',
      PlaylistPass: 'At least one playlist request in last 7 days',
      VastPass: 'At least one VAST call in last 7 days (optional)',
      GaidPass: 'At least one call with GAID parameter in last 7 days',
      IdfaPass: 'At least one call with IDFA parameter in last 7 days',
      googleTxtPass: 'app-ads.txt file from google play contains AdTonos entries',
      googleTxtDspsEntriesPass: 'app-ads.txt file from google play contains DSP partners entries (optional)',
      appleTxtPass: 'app-ads.txt file from apple store contains AdTonos entries',
      appleTxtDspsEntriesPass: 'app-ads.txt file from apple store contains DSP partners entries (optional)',
      ListenerRetentionRatioPass: 'Listener retention ratio is above threshold',
    };

    return labels[input] || '';
  }
  public async integrationTest() {
    try {
      await this.streamService.integrationCheck(this.streamId, this.integrationType, this.platform);
      this.toast.success('Verification has been requested');
    } catch (err) {
      this.toast.error('Verification request failed');
    }
    this.closeWithVerificationRequested();
  }

  private closeWithVerificationRequested() {
    this._closeModal(true);
  }
}
