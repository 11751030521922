export const POLICY_ACCEPT = 'ACCEPT';
export const POLICY_REJECT = 'REJECT';
export const POLIEC_NAMES = [POLICY_ACCEPT, POLICY_REJECT];

export const RULES_NAMES = ['audioChannelType', 'distributionChannel'];

export enum FilterType {
  PUBLISHER = 'publisher',
  STREAM = 'stream',
  BUNDLE = 'bundle',
  DOMAIN = 'domain',
  COUNTRY = 'country',
}

export const DYNAMIC_FILTERS = [FilterType.PUBLISHER, FilterType.STREAM, FilterType.COUNTRY];

export const MANUAL_FILTERS = [FilterType.BUNDLE, FilterType.DOMAIN];

export const ALL_FILTERS = [...DYNAMIC_FILTERS, ...MANUAL_FILTERS];

export const DYNAMIC_PREVIEW_LIMIT = 4;
