<div class="container settings" *ngIf="ad">
  <div class="row align-items-center settings__header">
    <h3>
      Autopromotion ad
      <span class="muted" *ngIf="id">#{{ ad.autopromoteAdId }}</span>
    </h3>
    <div class="dropdown" dropdown *ngIf="showActions">
      <button class="button button__secondary button--small" dropdownToggle title="Status">
        <span>
          Action
        </span>
        <img src="assets/images/icons/dropdown.svg" />
      </button>
      <div *dropdownMenu class="dropdown-menu">
        <a class="dropdown-item" (click)="setStatus('active')">Approve</a>
        <a class="dropdown-item" (click)="setStatus('rejected')">Reject</a>
      </div>
    </div>
    <button class="button button__secondary button--small" *ngIf="!statuses.length" disabled>
      {{ ad.status | capitalize }}
    </button>
    <div class="dropdown" dropdown *ngIf="statuses.length">
      <button class="button button__secondary button--small" dropdownToggle title="Status">
        <span>
          {{ ad.status | capitalize }}
        </span>
        <img src="assets/images/icons/dropdown.svg" />
      </button>
      <div *dropdownMenu class="dropdown-menu">
        <a
          class="dropdown-item"
          [ngClass]="{ active: s === ad.status }"
          (click)="setStatus(s)"
          *ngFor="let s of statuses"
          >{{ s | capitalize }}</a
        >
      </div>
    </div>
    <button [routerLink]="backRoute !== '' ? backRoute : '../'" class="button button__secondary button--small">
      Back
    </button>
  </div>
  <div class="row settings__data">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field label="Name" [content]="ad.name"></app-detail-field>
        <app-detail-field label="Language" [content]="languages"></app-detail-field>
        <div class="detail-field">
          <label class="detail-field__label">Audio file</label>
          <div class="detail-field__value">
            <audio controls *ngIf="ad.files">
              <source [src]="filesUrl + (ad?.files)['128_44100_s']" type="audio/mpeg" />
            </audio>
            <div *ngIf="!ad.files">Waiting for audio file</div>
          </div>
        </div>
        <app-detail-field label="Station" [content]="getStream(ad.streamId)"></app-detail-field>
        <app-detail-field
          label="Daily capping"
          content="{{ ad.playoutCapping ? ad.playoutCapping : 'unlimited' }}"
        ></app-detail-field>
        <app-detail-field
          label="Date range"
          content="{{ ad.startDate | amDateFormat: 'DD.MM.YYYY' }} - {{ ad.endDate | amDateFormat: 'DD.MM.YYYY' }}"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!ad.playTimes || !ad.playTimes.length"
          label="Play times"
          content="All week"
        ></app-detail-field>
      </div>
    </div>
  </div>
  <div class="row settings__data" *ngIf="ad.playTimes && ad.playTimes.length">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <p class="detail-field__label text-center">
          Play Times
        </p>
        <div class="row justify-content-center">
          <div
            class="col-sm-6 col-md-2 detail-field__value detail-field--play-time"
            *ngFor="let playTime of getPlayTimes(ad.playTimes)"
          >
            <p class="detail-field__value__label">{{ playTime.label }}</p>
            <span class="detail-field__value__tag" *ngFor="let time of playTime.values">
              {{ getPlayTimeLabel(time) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
