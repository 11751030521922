export type AutopromoteAdStatus = 'pending' | 'active' | 'paused' | 'closed' | 'rejected';

export const AutopromoteAdStatus: {
  Pending: AutopromoteAdStatus;
  Active: AutopromoteAdStatus;
  Paused: AutopromoteAdStatus;
  Closed: AutopromoteAdStatus;
  Rejected: AutopromoteAdStatus;
} = {
  Pending: 'pending',
  Active: 'active',
  Paused: 'paused',
  Closed: 'closed',
  Rejected: 'rejected',
};
