import { CampaignLength } from './campaign-length';
import { CampaignStatus } from './campaign-status';
import { DeviceType } from './device-type';
import { GenderType } from './gender';
import { TimeRange } from './time-range';
import { ImpressionUri } from './impression-uri';
import { BudgetType } from './budget-types';
import { Geotargeting } from './geotargeting';
import { AudioProductType } from './audio-product-type';

export interface CampaignBannerImage {
  name: string;
  reference: string;
  createdAt: string;
  publicURL: string;
  metadata: Record<string, string | number>;
}

export interface CampaignBanner {
  clickThroughUrl: string;
  image: CampaignBannerImage;
}

export interface Campaign {
  _id?: string;
  campaignId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  costPerPlay?: number;
  advertiserId?: string;
  name?: string;
  networkId?: string;
  length?: CampaignLength;
  deviceTypes?: DeviceType[];
  languages?: string[];
  countryCodes?: string[];
  cityIds?: string[];
  geotargeting?: Geotargeting;
  genders?: GenderType[];
  ageRangeIds?: string[];
  interestIds?: string[];
  intentIds?: string[];
  brandIds?: string[];
  eventIds?: string[];
  streamIds?: string[];
  startDate?: Date;
  endDate?: Date;
  fileId?: string;
  files?: Record<string, string>;
  callToActionFilesAlexa?: Record<string, string>;
  callToActionFilesGoogleHome?: Record<string, string>;
  status?: CampaignStatus;
  playTimes?: TimeRange[];
  playoutCapping?: number;
  totalTargetingCost?: number;
  totalBudget?: number;
  totalCost?: number;
  normalizedTotalCost?: number;
  totalPlayout?: number;
  invoicedAmount?: number;
  expectedAudience?: number;
  currency?: string;
  totalCostPerPlay?: number;
  priority?: number;
  budgetType?: BudgetType;
  manualBudget?: number;
  isBudgetManual?: boolean;
  regionsNames?: string[];
  remarketing?: boolean;
  productType?: string;
  exactTargeting?: string[];
  lastPlayout?: Date;
  gendersExcludeFlag?: boolean;
  ageRangeExcludeFlag?: boolean;
  adCategory?: string;
  impressionURIs?: ImpressionUri[];
  domain?: string;
  dealId?: string;
  buyerSeat?: string;
  adLanguage?: string;
  contentLanguage?: string[];
  contentCategory?: number[];
  mobileSegment?: string[];
  nielsenSegment?: string[];
  archiveReportFile?: string;
  banner?: CampaignBanner;
  audioProductTypes?: AudioProductType[];
}
