export enum PricingAttr {
  BaseFirst = 'base_first',
  BaseInBlock = 'base_in_block',
  Brands = 'brands',
  Events = 'events',
  Device = 'device',
  Language = 'language',
  Country = 'country',
  City = 'city',
  Geotargeting = 'geotargeting',
  Gender = 'gender',
  Age = 'age',
  Interests = 'interests',
  Intents = 'intents',
  Publisher = 'publisher',
  PlaytimeSelected = 'playtime_selected',
  PlayoutCommission = 'playout_commission',
  Stream = 'stream',
  Region = 'region',
  Remarketing = 'remarketing',
  ContentCategory = 'contentCategory',
  ContentLanguage = 'contentLanguage',
  MobileSegment = 'mobile_segment',
  NielsenSegment = 'nielsen_segment',
  AudioProductType = 'audio_product_type',
}
