export type AnalysisStatus = 'scheduled' | 'recording' | 'analyzing' | 'canceled' | 'failed' | 'finished';

export const AnalysisStatus: {
  Scheduled: AnalysisStatus;
  Recording: AnalysisStatus;
  Analyzing: AnalysisStatus;
  Canceled: AnalysisStatus;
  Failed: AnalysisStatus;
  Finished: AnalysisStatus;
} = {
  Scheduled: 'scheduled',
  Recording: 'recording',
  Analyzing: 'analyzing',
  Canceled: 'canceled',
  Failed: 'failed',
  Finished: 'finished',
};
