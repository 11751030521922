import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_FORMAT = 'MMM DD YYYY HH:mm';
const SHORT_FORMAT = 'MMM DD YYYY';
const LONG_FORMAT = 'MMM DD YYYY HH:mm:ss';

/*
 * Format date
 *
 * Usage:
 *   <Date> | sdate  # default app format
 *   <Date> | sdate:"<momentjs format>"
 *   <Date> | sdate:"short"
 *   <Date> | sdate:"long"
 */
@Pipe({ name: 'sdate' })
export class DatePipe implements PipeTransform {
  transform(value: Date, format: string = DEFAULT_FORMAT): string {
    if (format === 'long') {
      format = LONG_FORMAT;
    } else if (format === 'short') {
      format = SHORT_FORMAT;
    }
    return moment(value).format(format);
  }
}
