import { OrderItem } from './order-item';
import { OrderStatus } from './order-status';
import { OrderSubject } from './order-subject';

export interface Order {
  _id?: string; // same as represented campaign's ID
  createdAt?: Date;
  orderId?: string;
  advertiserId: string;
  from?: OrderSubject;
  to?: OrderSubject;
  orderItems?: (OrderItem)[];
  subtotal?: number;
  tax?: number;
  total?: number;
  currency?: string;
  status?: OrderStatus;
  pdf?: string;
  campaignId?: string;
}
