import { StreamStatus } from './stream-status';
import { MobileData } from './mobile-data';
import { IntegrationCheck } from './integration-checks';
import { FloorPrice, FloorPriceType } from './floor-price';
import { AudioProductType } from './audio-product-type';
import { IntegrationType } from './integration-type';

export interface Stream {
  _id?: string;
  createdAt?: Date;
  publisherId?: string;
  broadcastName?: string;
  website?: string;
  broadcastTypeId?: string;
  status?: StreamStatus;
  streamOutput?: string;
  streamSource1?: string;
  streamSource2?: string;
  streamSource3?: string;
  onAir?: boolean;
  hasJingles?: boolean;
  adsReplacement?: boolean;
  hasPreroll?: boolean;
  prerollLength?: number;
  onlyPreroll?: boolean;
  disabledCampaignIds?: string[];
  vastIntegration?: boolean;
  advancedOptions?: boolean;
  analysisId?: string;
  platform?: string[];
  googlePlayUrl?: string;
  appStoreUrl?: string;
  analysisStatus?: string;
  autotuneId?: string;
  reautotune?: boolean;
  autotuneStatus?: string;
  contentCategory?: string[];
  contentLanguage?: string;
  googlePlayData?: MobileData;
  appStoreData?: MobileData;
  streamer?: string;
  maxAdBreakLength?: number;
  lowpass?: number;
  highpass?: number;
  step?: number;
  stepsUpdates?: Record<string, Date>;
  stepsErrors?: Record<string, any>;
  lastCheck?: IntegrationCheck;
  floorPrices?: FloorPrice[];
  audioProductType?: AudioProductType;
  integrationType?: IntegrationType;
}
