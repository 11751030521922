<div class="container campaigns">
  <div class="row align-items-center campaigns__header" [ngClass]="{ 'campaigns__header--without-btns': !isAdmin }">
    <h3>Invoices</h3>
    <div>
      <input [formControl]="searchInput" type="text" class="form-control" placeholder="Search..." />
    </div>
    <div class="dropdown" dropdown *ngIf="isAdmin">
      <button class="button button__secondary button--small" dropdownToggle>
        <span>
          {{ scope | capitalize }}
        </span>
        <img src="assets/images/icons/dropdown.svg" />
      </button>
      <div *dropdownMenu class="dropdown-menu">
        <a class="dropdown-item" (click)="scope = 'all'">All</a>
        <a class="dropdown-item" (click)="scope = 'publisher'">Publishers</a>
        <a class="dropdown-item" (click)="scope = 'advertiser'">Advertiser</a>
        <a class="dropdown-item" (click)="scope = 'acquirer'">Acquirer</a>
      </div>
    </div>
  </div>

  <div class="row list">
    <table>
      <thead>
        <tr>
          <th class="sort" (click)="changeSort('createdAt')">
            <app-table-title [sort]="sort" [selected]="sortField === 'createdAt'">
              Created at #
            </app-table-title>
          </th>
          <th class="sort" (click)="changeSort('invoiceId')">
            <app-table-title [sort]="sort" [selected]="sortField === 'invoiceId'">
              Invoice #
            </app-table-title>
          </th>
          <th *ngIf="isAdmin">
            <app-table-title>
              User type
            </app-table-title>
          </th>
          <th *ngIf="isAdmin" class="sort" (click)="changeSort('userSort')">
            <app-table-title [sort]="sort" [selected]="sortField === 'userSort'">
              User
            </app-table-title>
          </th>
          <th *ngIf="isAdmin" class="sort" (click)="changeSort('countrySort')">
            <app-table-title [sort]="sort" [selected]="sortField === 'countrySort'">
              Country
            </app-table-title>
          </th>
          <th class="sort" (click)="changeSort('paymentDue')">
            <app-table-title [sort]="sort" [selected]="sortField === 'paymentDue'">
              payment due
            </app-table-title>
          </th>
          <th class="text-right sort" (click)="changeSort('subtotal')">
            <app-table-title [sort]="sort" [selected]="sortField === 'subtotal'" [alignRight]="true">
              total net
            </app-table-title>
          </th>
          <th class="text-right sort" (click)="changeSort('tax')">
            <app-table-title [sort]="sort" [selected]="sortField === 'tax'" [alignRight]="true">
              tax
            </app-table-title>
          </th>
          <th class="text-right sort" (click)="changeSort('total')">
            <app-table-title [sort]="sort" [selected]="sortField === 'total'" [alignRight]="true">
              Total gross
            </app-table-title>
          </th>
          <th class="text-left sort" (click)="changeSort('status')">
            <app-table-title [sort]="sort" [selected]="sortField === 'status'">
              Status
            </app-table-title>
          </th>
          <th class="text-center" >
            <app-table-title >
              Tier
            </app-table-title>
          </th>
        </tr>
      </thead>

      <ng-container *ngIf="invoices$ | async as invoices; else loading">
        <tbody *ngIf="invoices.length; else noInvoices">
          <tr *ngFor="let i of invoices | paginate: config">
            <td>
              {{ i.createdAt | amFromUtc | amDateFormat: 'DD.MM.YYYY' }}
            </td>
            <td>
              <a [routerLink]="i._id" [title]="i.invoiceId">{{ i.invoiceId }}</a>
            </td>
            <td *ngIf="isAdmin">{{ i.user.roles[0] | capitalize }}</td>
            <td *ngIf="isAdmin">
              <a [routerLink]="['../', 'users', i.user._id]">
                {{ i.user.profile.first != undefined ? (i.user.profile.first + ' ' + i.user.profile.last) : i.user.billingName }}
              </a>
            </td>
            <td *ngIf="isAdmin && i.user.roles[0] === 'advertiser'">{{ i.to.countryCode | uppercase }}</td>
            <td *ngIf="isAdmin && (i.user.roles[0] === 'publisher' || i.user.roles[0] === 'acquirer')">
              {{ i.from.countryCode | uppercase }}
            </td>
            <td>
              {{ i.paymentDue | amFromUtc | amDateFormat: 'DD.MM.YYYY' }}
            </td>
            <td class="text-right">{{ i.subtotal | price2 }} {{ i.currency | uppercase }}</td>
            <td class="text-right">{{ i.tax | price2 }} {{ i.currency | uppercase }}</td>
            <td class="text-right">{{ i.total | price2 }} {{ i.currency | uppercase }}</td>
            <td class="text-left">
              {{ i.status | capitalize }}
            </td>
            <td class="text-center"  *ngIf="i.user.roles[0] === 'publisher' || isAdmin">
              <span *ngIf="i.partnershipExcellenceTier; else noPartnershipExcellenceTier"> {{ i.partnershipExcellenceTier }} </span>
              <ng-template #noPartnershipExcellenceTier>
                0
              </ng-template>
            </td>
          </tr>
        </tbody>
      </ng-container>

      <ng-template #loading>
        <tbody>
          <tr>
            <td [attr.colspan]="isAdmin ? 12 : 7">
              <div class="list__empty list__empty_table">
                <div class="list__empty__inner">
                  <img src="/assets/images/loader.svg" width="50" />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-template>

      <ng-template #noInvoices>
        <tbody>
          <tr>
            <td [attr.colspan]="isAdmin ? 12 : 7">
              <div class="list__empty list__empty_table">
                <div class="list__empty__inner">
                  <img src="assets/images/icons/empty_state.svg" />
                  <p>There are no invoices that meet the criteria.</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
  <div class="row list__pagination justify-content-center" *ngIf="(invoices$ | async)?.length > config.itemsPerPage">
    <pagination-template
      #p="paginationApi"
      (click)="pagination(config.currentPage)"
      [id]="config.id"
      (pageChange)="config.currentPage = $event"
    >
      <div class="app-pagination">
        <div class="app-pagination__previous" [class.disabled]="p.isFirstPage()">
          <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
            <img src="assets/images/icons/dropdown.svg" />
          </a>
        </div>
        <div *ngFor="let page of p.pages" class="app-pagination__page" [class.current]="p.getCurrent() === page.value">
          <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
            <span>{{ page.label }}</span>
          </a>
          <div *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </div>
        </div>
        <div class="app-pagination__next" [class.disabled]="p.isLastPage()">
          <a *ngIf="!p.isLastPage()" (click)="p.next()">
            <img src="assets/images/icons/dropdown.svg" />
          </a>
        </div>
      </div>
    </pagination-template>
  </div>
</div>
