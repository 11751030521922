import { Injectable } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class PublisherExcellenceService {
  constructor() {}

  public getPublisherExcellenceTier(publisherId: string): Observable<any> {
    return MeteorObservable.call('publisher-excellence.getPublisherTier', publisherId);
  }

  public getPublishersExcellenceTiers(publishersIds: string[]): Observable<any> {
    return MeteorObservable.call('publisher-excellence.getPublishersTiers', publishersIds);
  }
}
