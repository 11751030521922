<div class="container settings" *ngIf="item">
  <div class="row settings__header settings__header--padded">
    <h3>
      {{ isOrder ? 'Order' : 'Invoice' }}
      <span class="muted">#{{ isOrder ? item.orderId : item.invoiceId }}</span>
    </h3>
      <button [routerLink]="backRoute !== '' ? backRoute : '../'" class="button button__secondary button--small">
        Back
      </button>
      <a
        *ngIf="item.pdf"
        target="_blank"
        [href]="item.pdf"
        title="Save as PDF"
        class="button button__primary button--small"
      >
        PDF
      </a>
      <button class="button button__primary button--small" *ngIf="!item.pdf" disabled>PDF</button>
      <button
        *ngIf="isAdmin && !isOrder"
        (click)="regeneratePdf()"
        class="button button__primary button--small"
      >
        Regenerate PDF
      </button>
  </div>
  <div class="row settings__data">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <h4>From</h4>
        <app-company-field [user]="item.from"></app-company-field>
      </div>
    </div>
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <h4>To</h4>
        <app-company-field [user]="item.to"></app-company-field>
      </div>
    </div>
  </div>
  <div class="row settings__data list list__invoice">
    <div class="col settings__data__box">
      <table>
        <thead>
          <tr>
            <th>Qty</th>
            <th>Product</th>
            <th>Description</th>
            <th>Subtotal Net</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let orderItem of item.orderItems">
            <td>{{ orderItem.qty }}</td>
            <td>{{ orderItem.name }}</td>
            <td>{{ orderItem.description }}</td>
            <td>{{ orderItem.total | price2 }} {{ item.currency | uppercase }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row settings__data" *ngIf="!isOrder && item.orderItems.length > 1">
    <div class="col settings__data__box">
      <p class="settings__data__box__info">
        This invoice was issued for multiple different audio advertising campaigns broadcasted online by number of radio
        stations and published by AdTonos platform. Thanks - you rock!
      </p>
    </div>
  </div>
  <div class="row settings__data">
    <div class="col settings__data__box" *ngIf="!isOrder">
      <div class="settings__data__box__content">
        <app-detail-field
          label="Payment Due"
          content="{{ item.paymentDue | amDateFormat: 'DD.MM.YYYY' }}"
        ></app-detail-field>
        <app-detail-field label="Account ID" [content]="accountId"></app-detail-field>
        <app-detail-field label="Status"> </app-detail-field>
        <select (change)="changeStatus()" [(ngModel)]="paymentStatus">
          <option [ngValue]="s" *ngFor="let s of statusArr">{{ s.value | capitalize }}</option>
        </select>
      </div>
    </div>
    <div class="col settings__data__box" *ngIf="isOrder && !isMediaHouse">
      <div class="settings__data__box__content" *ngIf="isOrder">
        <app-detail-field label="Bank Account" [content]="bankAccount"></app-detail-field>
        <app-detail-field label="Reference" [content]="item.orderId"></app-detail-field>
        <app-detail-field label="Status" content="{{ item.status | capitalize }}"></app-detail-field>
      </div>
      <div class="settings__data__box__footer text-left" *ngIf="isOrder">
        <small class="info-text">
          <img src="assets/images/icons/info.svg" alt="" /> Please be advised that payment processing may take up to 5
          workdays.</small
        >
      </div>
    </div>

    <div class="col settings__data__box list list__invoice">
      <div class="settings__data__box__content">
        <table>
          <tbody>
            <tr>
              <td>
                <b>Subtotal:</b>
              </td>
              <td>{{ item.subtotal | price2 }} {{ item.currency | uppercase }}</td>
            </tr>
            <tr>
              <td>
                <b>Tax:</b>
              </td>
              <td>{{ item.tax | price2 }} {{ item.currency | uppercase }}</td>
            </tr>
            <tr>
              <td>
                <b>Total:</b>
              </td>
              <td>{{ item.total | price2 }} {{ item.currency | uppercase }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <app-order-payments
    *ngIf="isOrder && !isMediaHouse"
    [orderId]="item?._id"
    [currency]="item?.currency"
  ></app-order-payments>

  <div *ngIf="campaigns.length">
    <div class="row settings__header settings__header--padded">
      <h3>Details</h3>
    </div>
    <div class="row settings__data">
      <div class="settings__data__box" *ngFor="let campaign of campaigns">
        <div class="settings__data__box__content">
          <app-order-item-details [campaign]="campaign" [showDetailButton]=true class="list list__invoice"></app-order-item-details>
        </div>
      </div>
    </div>
  </div>
</div>
