import { Component, ElementRef, Input } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';

import { ToastService } from 'common';
import { take } from 'rxjs/operators';
import { filesUrl } from '../../common/config';

@Component({
  selector: 'app-image-upload',
  template: `
    <img style="height: auto;width: 250px;" *ngIf="!isLoading && !!logoFile" [src]="logoFile" />
    <input #imageInput type="file" accept="image/png" (change)="onFileChange($event)" />
    <span (click)="browse()" [class.disabled]="isLoading" class="button button__secondary">Upload image</span>
  `,
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  @Input() public method: string = 'account.logoUpload';
  @Input() public params: any = null;
  @Input() public logoFile: string;
  @Input() public userId: string = Meteor.userId();
  private _isLoading: boolean = false;
  constructor(private element: ElementRef, private toast: ToastService) {}

  public browse() {
    this.element.nativeElement.querySelector('input').click();
  }

  public onFileChange(event) {
    if (this._isLoading) {
      return;
    }
    const reader = new FileReader();
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      this._isLoading = true;
      const file: File = files[0];
      if (file.name.split('.').pop() !== 'png') {
        this._isLoading = false;
        this.toast.error('File is not png');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        MeteorObservable.call(this.method, reader.result, this.userId)
          .pipe(take(1))
          .subscribe(
            () => {
              this._isLoading = false;
              this.toast.success('File uploaded with success.');
              // Update image after 1 sec
              setTimeout(() => (this.logoFile = `${filesUrl}${this.userId}.png?ts=${Date.now()}`), 10e2);
            },
            (err: Meteor.Error) => {
              this._isLoading = false;
              this.toast.error(err.error);
            }
          );
        this.element.nativeElement.querySelector('input').value = null;
      };
    }
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
}
