import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'common/classes';

@Component({
  selector: 'app-modal-integrations-report',
  templateUrl: './modal-integrations-report.component.html',
})
export class ModalIntegrationsReportComponent extends ModalComponent implements OnInit {
  dayOffset: any = null;
  data: any;
  dates: Date[] = [];
  constructor() {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.dates = this.data.filter((d) => d) || [];
    }, 0);
  }

  pickDates(dates: Date[]) {
    this.dates = dates;
  }

  save() {
    this._closeModal(this.dates);
  }

  close() {
    this._closeModal(false);
  }
}
