import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toast, ToastType } from 'common/models/toast';

@Injectable()
export class ToastService {
  private _toast$: BehaviorSubject<Toast[]> = new BehaviorSubject([]);

  public get toast$(): Observable<Toast[]> {
    return this._toast$;
  }

  constructor() {}

  error(message: string | number) {
    this._createToast(ToastType.Error, message);
  }
  warning(message: string | number) {
    this._createToast(ToastType.Warning, message);
  }
  info(message: string | number) {
    this._createToast(ToastType.Info, message);
  }
  success(message: string | number) {
    this._createToast(ToastType.Success, message);
  }
  close(id: number) {
    this._toast$.next(this._toast$.value.filter((i) => i._id !== id));
  }
  private _createToast(type: ToastType, message: string | number) {
    const toast: Toast = {
      _id: new Date().getMilliseconds(),
      type,
      message,
    };
    this._toast$.next([...this._toast$.value, toast]);
  }
}
