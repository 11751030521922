export interface DashboardStats {
  avgPlayoutFillThisMonth: number;
  avgPlayoutFillLastMonth: number;
  adsPlayedThisMonth: number;
  adsPlayedCorrSpanLastMonth: number;
  bestAudience?: {
    date: Date;
    listeners: number;
  };
  currentEarnings: number;
  bestAdPlayoutAllTime?: {
    campaignName: string;
    playouts: number;
  };
  bestAdPlayoutLast30Days?: {
    campaignName: string;
    playouts: number;
  };
  cpmLast: number;
  cpmCurrent: number;
  lastMonthUniqListeners?: number;
}
