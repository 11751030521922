import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject, of, Subject } from 'rxjs';

import {
  Campaigns,
  Networks,
  Countries,
  AgeRanges,
  Interests,
  Streams,
  Languages,
  Publishers,
  Intents,
  Brands,
  Events,
  Genders,
  AdCategories,
} from 'common/collections';
import {
  Campaign,
  CampaignStatus,
  TimeRangeDay,
  TimeRange,
  UserRole,
  Pricing,
  Tooltips,
  City,
  NielsenSegment,
} from 'common/models';
import { CampaignsService } from 'common/services';
import { filesUrl } from 'common/config';
import { StaticStore } from 'app/staticStore.service';
import { GetContentCategories } from 'common/utils';
import { AudioProductTypeData } from 'common/models/audio-product-type';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit, OnDestroy {
  campaign: Campaign;
  tooltips: Tooltips;
  id: string;
  filesUrl: string = `${filesUrl}`;
  isFilesProcessing: boolean = false;
  gendersExactTargeting: boolean = false;
  ageExactTargeting: boolean = false;
  interestExactTargeting: boolean = false;
  intentsExactTargeting: boolean = false;
  brandsExactTargeting: boolean = false;
  eventsExactTargeting: boolean = false;

  campaignSub: Subscription;
  cities$: BehaviorSubject<string> = new BehaviorSubject('Loading...');
  language$: BehaviorSubject<string> = new BehaviorSubject('Loading...');
  contentCategories$: BehaviorSubject<string> = new BehaviorSubject('Loading...');
  nielsenSegments$: BehaviorSubject<string> = new BehaviorSubject('Loading...');
  regions$: BehaviorSubject<string | string[]> = new BehaviorSubject('Loading...');

  destructor: Subject<any> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private campaignService: CampaignsService,
    private staticStore: StaticStore
  ) {}

  ngOnInit() {
    this.tooltips = this.campaignService.getTooltips();
    this.route.params.subscribe((params) => {
      this.id = params.id;
      Campaigns.find({ _id: this.id }).pipe(take(1)).subscribe(this.initComponentData.bind(this));
    });
  }

  ngOnDestroy() {
    this.destructor.next(true);
    this.destructor = null;
  }

  public get geotargetingDescription(): string {
    if (this.campaign.geotargeting) {
      const geotargeting = this.campaign.geotargeting;
      return `Center at
        (${geotargeting.point.coordinates[1].toFixed(6)},
        ${geotargeting.point.coordinates[0].toFixed(6)}),
        radius ${geotargeting.radius} meters`;
    } else {
      return 'No';
    }
  }

  public getCampaignType(campaignLength): string {
    return {
      8: 'First in a row',
      15: 'First in a row',
      20: 'Bid in block',
      30: 'Bid in block',
    }[campaignLength];
  }

  public getNetworkName(id: string): string {
    return (Networks.findOne({ _id: id }) || {}).name;
  }

  public getAdCategoryName(id: string): string {
    return (AdCategories.findOne({ _id: id }) || {}).value;
  }

  public getCountries(codes: string[]): string[] {
    return Countries.find({
      code: {
        $in: codes || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getLanguages(ids: string[]) {
    return Languages.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getAgeRanges(ids: string[]): string[] {
    return AgeRanges.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getGenders(ids: string[]): string[] {
    return Genders.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((g) => g.name);
  }

  public getInterests(ids: string[]): string[] {
    return Interests.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getIntents(ids: string[]): string[] {
    return Intents.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getBrands(ids: string[]): string[] {
    return Brands.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getEvents(ids: string[]): string[] {
    return Events.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((c) => c.name);
  }

  public getStreams(ids: string[]): string[] {
    return Streams.find({
      _id: {
        $in: ids || [],
      },
    })
      .fetch()
      .map((stream: any) => stream.broadcastName);
  }

  public getAudioProductTypes(ids: string[]): string {
    return (
      this.campaignService
        .fetchAudioProductTypeDataSync(ids)
        .map((data) => data.name)
        .join(', ') || 'All'
    );
  }

  public getDailyBudget(): string {
    return this.campaign.isBudgetManual
      ? `Manual (${this.campaign.manualBudget} ${this.campaign.currency.toUpperCase()} per day)`
      : this.campaign.budgetType.substring(0, 1).toUpperCase() + this.campaign.budgetType.substring(1);
  }

  public getPlayTimes(tr: TimeRange[]): string[] {
    const daysMap: string[] = [];
    Object.keys(TimeRangeDay)
      .filter((day) => isNaN(Number(day))) // leave strings only, because enum contains both numbers and strings
      .forEach((k, i) => {
        daysMap[TimeRangeDay[k]] = k;
      });
    let timeRanges: any[] = [];
    tr.forEach((v) => {
      if (!timeRanges[v.day]) {
        timeRanges[v.day] = {
          label: daysMap[v.day],
          day: v.day,
          values: [v],
        };
      } else {
        timeRanges[v.day].values.push(v);
      }
    });
    timeRanges = timeRanges.filter((v) => v);
    timeRanges.forEach((v, i) => {
      timeRanges[i].values.sort((a, b) => a.from - b.from);
    });
    timeRanges.sort((a, b) => (a.day === 0 ? 1000 : a.day - b.day));
    return timeRanges;
  }

  public getPlayTimeLabel(tr: TimeRange): string {
    return `${tr.from < 10 ? '0' + tr.from : tr.from}:00 - ${tr.to < 10 ? '0' + tr.to : tr.to}:00`;
  }

  public isPublisherAsAdvertiser(): boolean {
    const user = Publishers.findOne(this.campaign.advertiserId);
    return user && user.roles.indexOf(UserRole.Publisher) >= 0;
  }

  /**
   * This method is no longer used
   *
   * @deprecated
   */
  public calculateAdvertiserBudget(playoutCommission: Pricing, normalizedTotalBudget: number): number {
    if (!playoutCommission || !playoutCommission.value) {
      return 0;
    }
    return normalizedTotalBudget / playoutCommission.value;
  }

  private fetchSupplementaryData(campaign: Campaign): void {
    this.campaignService
      .fetchCitiesByIds(campaign.cityIds || [])
      .pipe(
        takeUntil(this.destructor),
        switchMap((cities: City[]) => {
          return of(cities.length ? cities.map((city) => city.title).join(', ') : 'All');
        })
      )
      .subscribe(this.cities$);

    GetContentCategories()
      .pipe(
        takeUntil(this.destructor),
        switchMap((contentCategories) => {
          const filteredCategories = contentCategories
            .filter((cat) => (campaign.contentCategory || []).includes(cat.uniqueId))
            .map((cat) => cat.name.trim());
          return of(filteredCategories.length ? filteredCategories.join(', ') : 'All');
        })
      )
      .subscribe(this.contentCategories$);

    this.staticStore
      .getLanguageList()
      .pipe(
        takeUntil(this.destructor),
        switchMap((langs) => {
          const filteredLangs = langs
            .filter((lng) => (campaign.contentLanguage || []).includes(lng.ISO639_1))
            .map((lng) => `[${lng.ISO639_1.toUpperCase()}] ${lng.name}`);
          return of(filteredLangs.length ? filteredLangs.join(', ') : 'All');
        })
      )
      .subscribe(this.language$);

    if (campaign.nielsenSegment?.length) {
      this.campaignService
        .getNielsenSegmentsByIds(campaign.nielsenSegment)
        .pipe(
          takeUntil(this.destructor),
          switchMap((segments: NielsenSegment[]) => [segments.map((segment) => segment.name).join(', ')])
        )
        .subscribe(this.nielsenSegments$);
    } else {
      this.nielsenSegments$.next('All');
    }

    this.campaignService
      .getRegions()
      .fetchNames(this.campaign.regionsNames)
      .pipe(takeUntil(this.destructor))
      .subscribe(this.regions$);
  }

  private initComponentData(data: Campaign[]) {
    if (data && data.length) {
      this.campaign = data[0];
      this.isFilesProcessing = this.campaign.status === CampaignStatus.Processing;
      this.fetchSupplementaryData(this.campaign);
      this.cdRef.detectChanges();
    } else {
      this.router.navigate(['../../']);
    }
    this.setExactTargeting();
  }

  private setExactTargeting() {
    if (!!this.campaign.exactTargeting) {
      this.campaign.exactTargeting.forEach((tracker: string) => {
        this[tracker] = true;
      });
    }
  }
}
