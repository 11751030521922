<div class="modal__header">
  <h4 class="modal__header__title">Select date range</h4>
  <button type="button" class="modal__header__close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal__body">
  <app-date-picker *ngIf="dates" [(dates)]="dates" [dayOffset]="dayOffset" [startDate]="startDate" [single]="single"></app-date-picker>
</div>
<div class="modal__footer">
  <button type="button" class="button button__secondary button--small" (click)="close()">Cancel</button>
  <button type="button" class="button button__primary button--small" [disabled]="!isValid()" (click)="save()">
    Apply date
  </button>
</div>
