import { Injectable, Injector } from '@angular/core';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from './toast.service';

@Injectable()
export class StreamAnalysisService {
  private _toast: ToastService;

  constructor(private _i: Injector) {
    setTimeout(() => (this._toast = this._i.get(ToastService)));
  }

  public runAutotune(streamId: string) {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('stream.runAutotuneDetection', streamId).subscribe(
        (_) => {
          this._toast.success('Autotune is running');
          resolve(true);
        },
        (error: Meteor.Error) => {
          reject(error.error);
        }
      );
    });
  }

  public scheduleAnalysis(streamId: string) {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('stream.scheduleAnalysis', streamId).subscribe(
        (_) => {
          this._toast.success('Analysis is scheduled');
          resolve(true);
        },
        (error: Meteor.Error) => {
          if (error.error) {
            this._toast.error(error.error);
          }
          reject(error.error);
        }
      );
    });
  }

  public cancelAnalysis(streamId: string) {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('stream.cancelAnalysis', streamId).subscribe(
        (_) => {
          this._toast.success('Analysis was canceled.');
          resolve(true);
        },
        (error: Meteor.Error) => {
          if (error.error) {
            this._toast.error(error.error);
          }
          reject(error.error);
        }
      );
    });
  }
}
