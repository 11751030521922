import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {
  public sources = [
    '/assets/images/backgrounds/login-01.png',
    '/assets/images/backgrounds/login-02.png',
    '/assets/images/backgrounds/login-03.png',
    '/assets/images/backgrounds/login-04.png',
    '/assets/images/backgrounds/login-05.png',
    '/assets/images/backgrounds/login-06.png',
    '/assets/images/backgrounds/login-07.png',
    '/assets/images/backgrounds/login-08.png',
    '/assets/images/backgrounds/login-09.png',
    '/assets/images/backgrounds/login-10.png',
  ];
  public loaded = [];
  public isLoaded: boolean = false;

  ngOnInit(): void {
    this.loadImgs(this.sources, (src) => {
      this.isLoaded = true;
      this.loaded.unshift(src);
    });
  }

  loadImgs(sources: string[], callback: (src: string) => void): void {
    const sourcesCopy = sources.concat();
    (function next() {
      if (sourcesCopy.length) {
        const img = new Image();
        img.onload = () => {
          callback(img.src);
          next();
        };
        img.src = sourcesCopy.shift();
      }
    })();
  }
}
