import { throwError as observableThrowError, Observable, OperatorFunction, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MeteorObservable } from 'meteor-rxjs';
import { ToastService } from './toast.service';
import {
  CampaignStatus,
  Campaign,
  DeviceType,
  CampaignLength,
  AutosuggestItems,
  Tooltips,
  Pricing,
  PricingAttr,
  City,
  AdCategory,
  MobileSegment,
  NielsenSegment,
  Region,
  AdCategoryRemap,
} from 'common/models';
import {
  Countries,
  Languages,
  Networks,
  Streams,
  AgeRanges,
  Interests,
  Pricings,
  Brands,
  Events,
  Genders,
} from 'common/collections';
import { Intents } from 'common/collections/intents';
import { tap, catchError, map, shareReplay } from 'rxjs/operators';
import { StreamStatus } from '../models/stream-status';
import { CampaignRegionsService } from './campaigns-regions.service';
import { AudioProductType, AudioProductTypeData } from 'common/models/audio-product-type';

export interface CampaignTransaction {
  isValid: boolean;
  campaignId: string | number;
  orderId: string | number;
}

@Injectable()
export class CampaignsService {
  private _toast: ToastService;
  private readonly audioProductTypeData: AudioProductTypeData[];
  constructor(private _i: Injector, private _router: Router) {
    setTimeout(() => {
      this._toast = this._i.get(ToastService);
    });
    this.audioProductTypeData = Object.values(AudioProductType).map((type) => {
      return {
        id: type,
        name: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
      };
    });
  }

  public validate(campaign: Campaign): Observable<any> {
    return MeteorObservable.call('campaign.validate', campaign);
  }

  public copy(campaign: Campaign): Observable<any> {
    return MeteorObservable.call('campaign.copy', campaign);
  }

  public create(campaign: Campaign, draft: boolean = false): Observable<CampaignTransaction> {
    return MeteorObservable.call('campaign.create', campaign, draft).pipe(
      tap((data: CampaignTransaction) => {
        this._toast.success('Campaign created');
        if (!draft) {
          const orderPath = this._router.url.indexOf('advertiser') > 0 ? '/advertiser/orders' : '/publisher/orders';
          this._router.navigate([orderPath, data.orderId]);
        } else {
          const campaignsPath =
            this._router.url.indexOf('advertiser') > 0 ? '/advertiser/campaigns' : '/publisher/campaigns';
          this._router.navigate([campaignsPath]);
        }
      }),
      catchError((_error: Meteor.Error, _caught: Observable<CampaignTransaction>) =>
        observableThrowError((error: Meteor.Error) => this.meteorErrorHandler(error))
      )
    );
  }

  public orderDraft(campaign: Campaign): Observable<void | Error> {
    return MeteorObservable.call('campaign.order_draft', campaign).pipe(
      tap((orderId: string) => {
        this._toast.success('Campaign created from draft');
        const orderPath = this._router.url.indexOf('advertiser') > 0 ? '/advertiser/orders' : '/publisher/orders';
        this._router.navigate([orderPath, orderId]);
      }),
      catchError((_error: Error, _caught: Observable<Error>) =>
        observableThrowError((error: Meteor.Error) => this.meteorErrorHandler(error))
      ) as OperatorFunction<any, Error>
    );
  }

  public generatePdf(campaign: Campaign): Observable<any> {
    return MeteorObservable.call('campaign.generate_pdf', campaign).pipe(
      catchError((_error: Meteor.Error, _caught: Observable<any>) =>
        observableThrowError((error: Meteor.Error) => this.meteorErrorHandler(error))
      )
    );
  }

  public generateActiveCampaignsReport(): Observable<any> {
    return MeteorObservable.call('campaign.generate.active.list.report').pipe(
      catchError((_error: Meteor.Error, _caught: Observable<any>) =>
        observableThrowError((error: Meteor.Error) => this.meteorErrorHandler(error))
      )
    );
  }

  /**
   * @deprecated contains side effects and also change response data
   */
  public update(campaign: Campaign, addUpdateToast: boolean = true): Promise<{ isProcessing: boolean }> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('campaign.update', campaign).subscribe(
        (response: { isProcessing: boolean }) => {
          if (addUpdateToast) {
            this._toast.success('Campaign updated');
          }
          resolve(response);
        },
        (error: Meteor.Error) => {
          if (error.reason) {
            this._toast.error(error.reason);
          }
          if (error.error) {
            if (typeof error.error === 'string') {
              this._toast.error(error.error);
            } else {
              Object.keys(error.error).forEach((k) => {
                this._toast.error(error.error[k]);
              });
            }
          }
          reject(false);
        }
      );
    });
  }

  /**
   * updateV2
   * Replaces the deprecated 'update' method
   */
  public updateV2(campaign: Campaign): Observable<{ isProcessing: boolean }> {
    return MeteorObservable.call('campaign.update', campaign);
  }

  public getStatusesMap(): string[] {
    return Object.keys(CampaignStatus).map((k) => CampaignStatus[k]);
  }

  public getTooltips(): Tooltips {
    const tooltips: any = {};
    Object.keys(PricingAttr).forEach((k) => {
      tooltips[PricingAttr[k] + '$'] = Pricings.find({ _id: PricingAttr[k] }).pipe(
        map((pricing: Pricing[]) => pricing[0] || {})
      );
    });
    return tooltips;
  }

  public getAutosuggestItems(): AutosuggestItems {
    return {
      countries$: Countries.find(),
      languages$: Languages.find(),
      networks$: Networks.find(),
      streams$: Streams.find(),
      playTime: ['selected'],
      deviceTypes: Object.keys(DeviceType).map((d) => DeviceType[d]),
      lengths: Object.keys(CampaignLength).map((d) => CampaignLength[d]),
      radii: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000, 150000, 200000],
      ageRanges$: AgeRanges.find(),
      interests$: Interests.find(),
      intents$: Intents.find(),
      brands$: Brands.find(),
      events$: Events.find(),
      genders$: Genders.find(),
    };
  }

  public generateCsvArray(campaigns: any[], csvData: string[][]): string[][] {
    csvData = csvData || [];
    if (csvData.length === 0) {
      csvData.push(['Ad', 'Network', 'Dates', 'Status', 'Playouts', 'Income']);
    }
    campaigns.forEach((campaign) => {
      const streams = Streams.find({ status: StreamStatus.Active }).fetch();
      if (campaign.totalPlayout !== undefined) {
        csvData.push([
          campaign.name,
          'adtonos',
          `${campaign.startDate.toLocaleDateString()} - ${campaign.endDate.toLocaleDateString()}`,
          campaign.enabledStreamsCount === streams.length
            ? 'active'
            : campaign.enabledStreamsCount > 0 && campaign.enabledStreamsCount !== streams.length
            ? 'partially blocked'
            : 'blocked',
          campaign.totalPlayout.toString(),
          '' + campaign.totalIncome.toFixed(6),
        ]);
      } else {
        csvData.push([
          campaign.name,
          campaign.networkId,
          ' --- ',
          campaign.enabledStreamsCount === campaign.streams.length
            ? 'active'
            : campaign.enabledStreamsCount > 0 && campaign.enabledStreamsCount !== campaign.streams.length
            ? 'partially blocked'
            : 'blocked',
          campaign.playouts.toString(),
          '' + campaign.income.toFixed(6),
        ]);
      }
    });

    return csvData;
  }

  public getPlayouts(campaignsIds: string[]): Promise<string[]> {
    return new Promise((resolve, reject) => {
      MeteorObservable.call('campaign.getPlayouts', campaignsIds).subscribe(
        (_data) => {
          resolve(['a']);
        },
        (e) => {
          this._toast.error(e);
          reject(e);
        }
      );
    });
  }

  public searchCampaigns(search: string, status: string, sort: Record<string, number>): Observable<Campaign[]> {
    return MeteorObservable.call<any[]>('campaign.searchList', search, status, sort);
  }

  public getCities = (text: string, countryCodes: string[], skip?: number): Observable<City[]> => {
    return MeteorObservable.call('city.autosuggest', text, countryCodes, skip);
  };

  public fetchCitiesByIds = (data: string[]): Observable<City[]> => {
    return MeteorObservable.call<Record<string, string>[]>('city.getByIds', data);
  };

  public getRegions = (): CampaignRegionsService => {
    return new CampaignRegionsService(
      MeteorObservable.call<Region[]>('regions.all').pipe(shareReplay({ bufferSize: 1, refCount: true }))
    );
  };

  public getAdCategories(text: string): Observable<AdCategory> {
    return MeteorObservable.call('adCategory.autosuggest', text);
  }

  private _getObservableItem(_id: string): Observable<Pricing> {
    return Pricings.find({ _id }).pipe(map((pricing: Pricing[]) => pricing[0] || {}));
  }

  private meteorErrorHandler(error: Meteor.Error): void {
    if (error.reason) {
      this._toast.error(error.reason);
    }
    if (error.error) {
      if (typeof error.error === 'string') {
        this._toast.error(error.error);
      } else {
        Object.keys(error.error).forEach((k) => {
          this._toast.error(error.error[k]);
        });
      }
    }
  }

  public fetchMobileSegments(): Observable<MobileSegment[]> {
    return MeteorObservable.call('campaign.fetchMobileSegments');
  }

  public searchNielsenSegments(countries: string[] | null, phrase: string, skip: number): Observable<NielsenSegment[]> {
    return MeteorObservable.call('campaign.searchNielsenSegments', phrase, skip, countries);
  }
  public getNielsenSegmentsByIds(data: string[]): Observable<NielsenSegment[]> {
    return MeteorObservable.call('campaign.getNielsenSegments', data);
  }

  public fetchAudioProductTypeDataSync(typeIds: string[]): AudioProductTypeData[] {
    return this.audioProductTypeData.filter((type) => typeIds.some((id) => id === type.id));
  }

  public fetchAudioProductTypeData(typeIds: string[]): Observable<AudioProductTypeData[]> {
    return of(this.fetchAudioProductTypeDataSync(typeIds));
  }

  public searchAudioProductTypeData(text: string | null) {
    if (!text) {
      return of(this.audioProductTypeData);
    }
    const filteredProductTypes: AudioProductTypeData[] = this.audioProductTypeData.filter((data) =>
      data.id.startsWith(text.toLowerCase())
    );
    return of(filteredProductTypes);
  }
}
