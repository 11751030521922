import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from 'common';
import { ComponentsModule } from 'components';
import { ProfileModule } from './profile';
import { CampaignModule } from './campaign';
import { CampaignsModule } from './campaigns';
import { InvoicesModule } from './invoices';
import { AutopromoteAdModule } from './autopromote-ad';
import { StaticStore } from './staticStore.service';
import { DspCampaignsModule } from './dsp-campaigns';
import { DspCampaignEditModule } from './dsp-campign-edit';
import { DspCampaignModule } from './dsp-campaign';
import { DecimalPipe } from '@angular/common';
import { ImpressionUrlsEditModule } from './impression-urls-edit';
// import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    // const eventId = Sentry.captureException(error.originalError || error);
    // if (!environment.production) {
    //   Sentry.showReportDialog({ eventId });
    // }
    console.error(error);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule.forRoot(),
    ComponentsModule.forRoot(),
    ProfileModule,
    CampaignModule,
    InvoicesModule,
    AutopromoteAdModule,
    CampaignsModule,
    DspCampaignsModule,
    DspCampaignModule,
    DspCampaignEditModule,
    ImpressionUrlsEditModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    StaticStore,
    DecimalPipe,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
