import { SmartSpeakerType } from './smart-speaker-type';

export interface File {
  _id?: string;
  name?: string;
  userId?: string;
  campaignId?: string;
  streamId?: string;
  intro?: boolean;
  outro?: boolean;
  path?: string;
  duration?: number;
  bitRate?: number;
  sampleRate?: number;
  fingerPrint?: string;
  callToAction?: SmartSpeakerType;
  files?: Record<string, string>;
  fpWindowLength?: number;
  fpFps?: number;
  fpMask?: string;
  fpMatchThreshold?: number;
  melBanksCount?: number;
  customDelay?: number;
  lowpass?: number;
  highpass?: number;
  type?: string;
}
