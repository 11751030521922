import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { ComponentsModule } from 'components';
import { ProfileComponent } from './profile.component';
import { UnverifiedComponent } from './unverified.component';

@NgModule({
  declarations: [ProfileComponent, UnverifiedComponent],
  exports: [UnverifiedComponent],
  imports: [CommonModule, MatInputModule, FormsModule, ReactiveFormsModule, ComponentsModule],
  providers: [],
  bootstrap: [ProfileComponent],
})
export class ProfileModule {}
