<tag-input
  [disable]="inputDisabled"
  [ripple]="false"
  [inputClass]="inputClass"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  theme="adtonos"
  class="form-control"
  [placeholder]="placeholder"
  [secondaryPlaceholder]="secondaryPlaceholder"
  [animationDuration]="{ enter: '0ms', leave: '0ms' }"
  [onlyFromAutocomplete]="true"
  (keypress)="handleKeypress($event)"
  (keyup)="handleKeyup($event)"
  #input
>
  <ng-template let-item="item" let-index="index" class="tag-container">
    <span class="content">
      {{ item.display | capitalize }}
    </span>
    <span (click)="input.removeItem(item, index)" class="remove-button">
      x
    </span>
  </ng-template>
  <tag-input-dropdown
    *ngIf="!requestAutocompleteItems"
    [showDropdownIfEmpty]="true"
    [keepOpen]="false"
    [autocompleteItems]="serializedAutocompleteItems()"
    [minimumTextLength]="0"
    [dynamicUpdate]="false"
  >
    <ng-template let-item="item">
      {{ item.display | capitalize }}
    </ng-template>
  </tag-input-dropdown>
  <tag-input-dropdown
    *ngIf="requestAutocompleteItems"
    [showDropdownIfEmpty]="true"
    [keepOpen]="false"
    [autocompleteObservable]="autocompleteObservable"
    [minimumTextLength]="0"
    [dynamicUpdate]="false"
  >
    <ng-template let-item="item">
      {{ item.display | capitalize }}
    </ng-template>
  </tag-input-dropdown>
</tag-input>
<small class="error" *ngIf="showError && errorMsg">{{ errorMsg }}</small>
