<div class="modal-integration-status">
  <div class="modal__header">
    <h4 class="modal__header__title">
        {{ streamName }}
        <span *ngIf="integration?.status" class="global-status">
          [<span [ngClass]="{
            'text-secondary': integration.status === 'None',
            'text-success': integration.status === 'Pass',
            'text-warning': integration.status === 'Partial',
            'text-primary': integration.status === 'Ready',
            'text-danger': integration.status === 'Fail'
          }"> {{integration.status}} </span>]
        </span>
    </h4>
    <button type="button" class="modal__header__close" (click)="close()" aria-label="Close"></button>
  </div>
  <div *ngIf="integration" class="modal__body">
      <div *ngFor="let test of integrationTests">
        {{ labelToDescription(test.key) }}:
        <span class="pass" *ngIf="test.value">Pass</span>
        <span class="fail" *ngIf="!test.value">Fail</span>
      </div>
  </div>
  <div class="modal__footer">
    <p *ngIf="!integration">Start the verification process using the button below.</p>
    <div class="actions">
      <button (click)="integrationTest()">Verify integration</button>
      <span *ngIf="integration">Last verification: {{ integrationDate }}</span>
    </div>
  </div>
</div>
