import { Component, Input } from '@angular/core';
import { AppDetails } from 'common';

@Component({
  templateUrl: './app-details.component.html',
  styleUrls: ['app-details.component.scss'],
  selector: 'app-app-details',
})
export class AppDetailsComponent {
  @Input() appDetails: AppDetails;
  constructor() {}
}
