<div class="company-field">
  <p class="company-field__value">
    <b>{{ user.billingName }}</b>
  </p>
  <p class="company-field__value">
    <b>VAT {{ user.vatNumber }}</b>
  </p>
  <p class="company-field__value">{{ user.addressLine1 }}</p>
  <p class="company-field__value" *ngIf="user.addressLine2">{{ user.addressLine2 }}</p>
  <p class="company-field__value">{{ user.city }}, {{ user.postcode }}</p>
  <p class="company-field__value">{{ country }}</p>
</div>
