import { Component } from '@angular/core';
import { fade } from '../animations';
import { Input } from '@angular/core';

@Component({
  selector: 'app-loader-minimal',
  template: `
    <div @fade class="loader-bg">
      <div class="app-loader"></div>
      <div class="app-loader__message">{{ message }}</div>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
  animations: [fade],
})
export class LoaderMinimalComponent {
  @Input() message: string;
  constructor() {}
}
