import { Component, Input, OnInit } from '@angular/core';
import { QuantitativeEntity } from 'common/models';

@Component({
  selector: 'app-foldable-list',
  templateUrl: './foldable-list.component.html',
})
export class FoldableListComponent implements OnInit {
  @Input() data: QuantitativeEntity[];
  @Input() initialLimit: number = 5;
  public displayLimit: number = 5;
  public isUnfordable: boolean = true;
  protected maxNumberOfRows: number = this.displayLimit; // remember to set according to corresponding Input length
  public message: string = 'More +';

  constructor() {}

  ngOnInit() {
    if (this.data.length > this.initialLimit) {
      this.maxNumberOfRows = this.data.length - 1;
      this.isUnfordable = false;
    }
    this.displayLimit = this.initialLimit;
  }

  get sortedData(): QuantitativeEntity[] {
    return this.data.sort((a, b) => b.counted - a.counted);
  }

  public toggleFold() {
    this.displayLimit = this.displayLimit === this.initialLimit ? this.data.length - 1 : this.initialLimit;
    this.message = this.message === 'More +' ? 'Hide -' : 'More +';
  }
}
