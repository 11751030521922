<div class="row list">
  <table>
    <thead>
      <tr>
        <th class="sort" (click)="changeSort('createdAt')">
          <app-table-title [sort]="sort" [selected]="sortField === 'createdAt'">
            Created at
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('lastPlayedAt')">
          <app-table-title [sort]="sort" [selected]="sortField === 'lastPlayedAt'">
            Last<br />played at
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('name')">
          <app-table-title [sort]="sort" [selected]="sortField === 'name'">
            Campaign
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('networkId')">
          <app-table-title [sort]="sort" [selected]="sortField === 'networkId'">
            Network
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('playouts')">
          <app-table-title [sort]="sort" [selected]="sortField === 'playouts'">
            playouts
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('totalCostPerPlay')">
          <app-table-title [sort]="sort" [selected]="sortField === 'totalCostPerPlay'">
            total cpp
          </app-table-title>
        </th>
        <th class="sort" (click)="changeSort('totalCost')">
          <app-table-title [sort]="sort" [selected]="sortField === 'totalCost'">
            cost
          </app-table-title>
        </th>
      </tr>
    </thead>
    <ng-container *ngIf="dspCampaigns$ | async as dspCampaigns; else loading">
      <tbody *ngIf="dspCampaigns.length; else noCampaigns">
        <tr *ngFor="let c of dspCampaigns | paginate: config">
          <td>
            {{ c.createdAt | amDateFormat: 'DD.MM.YYYY' }}
          </td>
          <td>
            {{ c.lastPlayedAt ? (c.lastPlayedAt | amDateFormat: 'DD.MM.YYYY') : 'No playouts' }}
          </td>
          <td>
            <a [routerLink]="[c._id]">
              {{ c.name }}
            </a>
          </td>
          <td>{{ c.networkId | titlecase }}</td>
          <td>{{ c.playouts ? c.playouts : 0 }}</td>
          <td>{{ c.averageCpp | price6 }} {{ c.costCurrency ? (c.costCurrency | uppercase) : 'GBP' }}</td>
          <td>{{ c.totalCost | price6 }} {{ c.costCurrency ? (c.costCurrency | uppercase) : 'GBP' }}</td>
        </tr>
      </tbody>
    </ng-container>

    <ng-template #loading>
      <tbody>
        <tr>
          <td colspan="11">
            <div class="list__empty list__empty_table">
              <div class="list__empty__inner">
                <img src="/assets/images/loader.svg" width="50" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-template>

    <ng-template #noCampaigns>
      <tbody>
        <tr>
          <td colspan="11">
            <div class="list__empty list__empty_table">
              <div class="list__empty__inner">
                <img src="assets/images/icons/empty_state.svg" />
                <p>There are no campaigns that meet the criteria.</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-template>
  </table>
  <div
    class="row list__pagination justify-content-center"
    *ngIf="(dspCampaigns$ | async)?.length > config.itemsPerPage"
  >
    <pagination-template
      #p="paginationApi"
      (click)="pagination(config.currentPage)"
      [id]="config.id"
      (pageChange)="config.currentPage = $event"
    >
      <div class="app-pagination">
        <div class="app-pagination__previous" [class.disabled]="p.isFirstPage()">
          <a *ngIf="!p.isFirstPage()" (click)="p.previous()">
            <img src="assets/images/icons/dropdown.svg" />
          </a>
        </div>
        <div *ngFor="let page of p.pages" class="app-pagination__page" [class.current]="p.getCurrent() === page.value">
          <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
            <span>{{ page.label }}</span>
          </a>
          <div *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </div>
        </div>
        <div class="app-pagination__next" [class.disabled]="p.isLastPage()">
          <a *ngIf="!p.isLastPage()" (click)="p.next()">
            <img src="assets/images/icons/dropdown.svg" />
          </a>
        </div>
      </div>
    </pagination-template>
  </div>
</div>
