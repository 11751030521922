import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ImpressionUrlsEditComponent } from './impression-urls-edit.component';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ImpressionUrlsEditComponent],
  exports: [ImpressionUrlsEditComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, BsDropdownModule.forRoot(), FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [ImpressionUrlsEditComponent],
})
export class ImpressionUrlsEditModule {}
