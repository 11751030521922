import { MongoObservable } from 'meteor-rxjs';
import { TagSource, TagInputData, TimeRange, City, Region, AdCategory } from 'common/models';
import {
  AgeRanges,
  Countries,
  Campaigns,
  Interests,
  Publishers,
  Streams,
  Languages,
  Intents,
  Brands,
  Events,
  Genders,
  Streamers,
  ContentCategories,
} from 'common/collections';
import { UserEmail } from 'common/models';

interface SerializationData extends TimeRange {
  profile?: Profile;
  name: string;
  emails: UserEmail[];
  value?: string;
}

interface Profile {
  last?: string;
  first?: string;
}

export class TagInputSerializer {
  private _collection: MongoObservable.Collection<any>;
  private _keyName: string;
  static getPlayTimeTagInputData(tr: TimeRange): TagInputData {
    const display = `${tr.from < 10 ? '0' + tr.from : tr.from}:00 - ${tr.to < 10 ? '0' + tr.to : tr.to}:00`;
    return TagInputSerializer.getTagInputData(display, tr);
  }

  static getTagInputData(display: string, value: string | TimeRange): TagInputData {
    return { display, value };
  }

  static getCity(cityId: string): Promise<City> {
    return new Promise((resolve) => {
      Meteor.call('city.getByIds', [cityId], (err: Error, cities: City[]) => resolve(cities[0]));
    });
  }

  static getAdCategories(categoryId: string): Promise<AdCategory> {
    return new Promise((resolve) => {
      Meteor.call('adCategory.getByIds', [categoryId], (err: Error, categories: AdCategory[]) =>
        resolve(categories[0])
      );
    });
  }

  constructor(private _scope: TagSource) {
    switch (this._scope) {
      case TagSource.AgeRanges:
        this._collection = AgeRanges;
        this._keyName = '_id';
        break;
      case TagSource.Brands:
        this._collection = Brands;
        this._keyName = '_id';
        break;
      case TagSource.Events:
        this._collection = Events;
        this._keyName = '_id';
        break;
      case TagSource.Countries:
        this._collection = Countries;
        this._keyName = 'code';
        break;
      case TagSource.Interests:
        this._collection = Interests;
        this._keyName = '_id';
        break;
      case TagSource.Intents:
        this._collection = Intents;
        this._keyName = '_id';
        break;
      case TagSource.Cities:
      case TagSource.PlayTimes:
      case TagSource.AdCategories:
      case TagSource.Simple:
        break;
      case TagSource.Streams:
        this._collection = Streams;
        this._keyName = '_id';
        break;
      case TagSource.Publishers:
        this._collection = Publishers;
        this._keyName = '_id';
        break;
      case TagSource.Languages:
        this._collection = Languages;
        this._keyName = '_id';
        break;
      case TagSource.Genders:
        this._collection = Genders;
        this._keyName = '_id';
        break;
      case TagSource.ContentCategories:
        this._collection = ContentCategories;
        this._keyName = 'uniqueId';
        break;
      case TagSource.Streamers:
        this._collection = Streamers;
        this._keyName = 'name';
        break;
      case TagSource.Campaigns:
        this._collection = Campaigns;
        this._keyName = '_id';
        break;
      default:
        throw new Error(`Source ${this._scope} is invalid. You must provide correct source.`);
    }
  }

  public async serialize(data: TimeRange | string, keyName?: string): Promise<TagInputData> {
    let document: SerializationData;

    const runQuery = () => {
      const query = {};
      query[keyName ? keyName : this._keyName] = data;
      document = this._collection.findOne(query);
    };

    switch (this._scope) {
      case TagSource.Simple:
        return TagInputSerializer.getTagInputData(<string>data, <TimeRange>data);
      case TagSource.PlayTimes:
        return TagInputSerializer.getPlayTimeTagInputData(<TimeRange>data);
      case TagSource.Cities:
        const city = await TagInputSerializer.getCity(<string>data);
        const cityName = !!city && !!city.title ? city.title : '';
        const cityId = !!city && !!city._id ? city._id : '';
        return TagInputSerializer.getTagInputData(cityName, cityId);
      case TagSource.AdCategories:
        const adCategory = await TagInputSerializer.getAdCategories(<string>data);
        const adCategoryName = !!adCategory && !!adCategory.value ? adCategory.value : '';
        const adCategoryId = !!adCategory && !!adCategory._id ? adCategory._id : '';
        return TagInputSerializer.getTagInputData(adCategoryName, adCategoryId);
      case TagSource.Streams:
        runQuery();
        return TagInputSerializer.getTagInputData(
          document['broadcastName'],
          document[keyName ? keyName : this._keyName]
        );
      case TagSource.Campaigns:
        runQuery();
        return TagInputSerializer.getTagInputData(document['name'], document[keyName ? keyName : this._keyName]);
      case TagSource.Publishers:
        runQuery();
        let name = '';
        if (!document.profile || (!document.profile.first && !document.profile.last)) {
          name = document.emails[0].address;
        } else {
          name = [document.profile.first, document.profile.last].filter((v) => v).join(' ');
        }
        return TagInputSerializer.getTagInputData(name, document[keyName ? keyName : this._keyName]);
      case TagSource.Streamers:
        runQuery();
        return TagInputSerializer.getTagInputData(document['name'], document['name']);
      default:
        runQuery();
        if (!document) {
          return { display: 'Attribute removed', value: false };
        }
        return TagInputSerializer.getTagInputData(document.name, document[keyName ? keyName : this._keyName]);
    }
  }
}
