import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TagInputData, TagSource } from 'common/models';
import { TagInputSerializer } from 'common/classes';
import { ViewChild } from '@angular/core';
import { TagInputDropdown } from 'ngx-chips';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagComponent),
      multi: true,
    },
  ],
})
export class TagComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild(TagInputDropdown) inputDropdownRef: TagInputDropdown;
  @Input() autocompleteItems: any[] = null;
  @Input() displayKey: string = null;
  @Input() valueKey: string = null;
  @Input() keyName: string;
  @Input() placeholder: string = null;
  @Input() secondaryPlaceholder: string = null;
  @Input() maxItems: number = null;
  @Input() mainCategoryKey: string = null;
  @Input() requestAutocompleteItems: (text: string) => Observable<Record<string, string>[]> = null;
  @Input() source: TagSource;
  @Input() filter: string;
  @Input() inputDisabled: boolean = false;
  @Input() defaultValue: any[] = null;
  @Input() showError: boolean = false;
  @Input() errorMsg: string = null;
  autocompleteSub: Subscription;
  inputClass = '';
  value: any;
  handleKeyup: (event: Event) => void;
  propagateChange = (_: any) => {};

  async writeValue(val: any): Promise<void> {
    if (this.source && val) {
      const serializer = new TagInputSerializer(this.source);
      /** This is hack for serializing values asynchronously (Cities need to be downloaded) */
      this.value = await Promise.all(val.map((v) => serializer.serialize(v, this.keyName)));
    }
  }

  constructor() {}

  ngOnInit() {
    this.handleKeyup = (ev) => this.inputDropdownRef.show();
    if (!!this.defaultValue && this.defaultValue.length > 0) {
      this.setDefaultValues();
    }
  }

  onChange(val: any) {
    const maxItems = this.maxItems ? this.maxItems : null;
    if (maxItems && val.length && val.length > maxItems) {
      val.shift();
    }
    this.propagateChange(val.map((item: any) => item.value));
  }

  handleKeypress(event: KeyboardEvent) {
    if (!new RegExp(this.filter).test(String.fromCharCode(event.charCode))) {
      event.preventDefault();
    }
  }

  _serializeData(item: string | Record<string, string>): TagInputData {
    if (typeof item === 'string') {
      return { value: item, display: item };
    }
    if (!this.displayKey || !this.valueKey) {
      throw new Error(`If autosuggest is array of objects you must declare keys name for display and value.
      Please fill "displayKey" and "valueKey" props`);
    }
    const isTab = !item[this.mainCategoryKey] || false;
    return {
      value: item[this.valueKey],
      display: isTab ? `\u00A0\u00A0\u00A0${item[this.displayKey]}` : item[this.displayKey],
    };
  }

  serializedAutocompleteItems() {
    if (!this.autocompleteItems) {
      return [];
    }
    return this.autocompleteItems.map((item) => this._serializeData(item));
  }

  autocompleteObservable = (text: string): Observable<TagInputData[]> => {
    return this.requestAutocompleteItems(text).pipe(
      map((items: Record<string, string>[]) => {
        return items.map((item: Record<string, string>) => this._serializeData(item));
      })
    );
  };

  setDefaultValues() {
    this.value = this.defaultValue.map((item: any) => this._serializeData(item));
  }

  registerOnChange(fn: () => {}) {
    this.propagateChange = fn;
  }

  ngOnDestroy() {
    if (this.autocompleteSub) {
      this.autocompleteSub.unsubscribe();
    }
  }
  registerOnTouched() {}
}
