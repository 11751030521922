import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

export const slideTopFade: AnimationTriggerMetadata = trigger('slideTopFade', [
  transition(':enter', [
    style({
      transform: 'translate3d(0,-50px,0)',
    }),
    animate(
      '0.3s ease',
      style({
        transform: 'translate3d(0,0,0)',
        opacity: 1,
      })
    ),
  ]),
  transition('* => void', [
    animate(
      300,
      keyframes([
        style({
          transform: 'translate3d(0,0,0)',
          opacity: 1,
        }),
        style({
          transform: 'translate3d(0,15px,0)',
          opacity: 1,
        }),
        style({
          transform: 'translate3d(0,-50px,0)',
          opacity: 0,
        }),
      ])
    ),
  ]),
]);
