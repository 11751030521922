import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ToastService } from 'common/services';
import { Toast } from 'common/models/toast';

const TOAST_CLOSE_TIMEOUT = 10 * 1000; // 10sek

@Component({
  selector: 'app-toast-item',
  templateUrl: './toast-item.component.html',
  styleUrls: ['./toast-item.component.scss'],
})
export class ToastItemComponent implements OnDestroy, OnInit {
  @Input() toast: Toast;

  private _closeTimeout: any;

  constructor(private _toastService: ToastService) {}

  ngOnInit(): void {
    if (this.toast) {
      this._closeAfter(TOAST_CLOSE_TIMEOUT);
    }
  }

  ngOnDestroy(): void {
    if (this._closeTimeout) {
      clearTimeout(this._closeTimeout);
    }
  }

  close() {
    if (this._closeTimeout) {
      clearTimeout(this._closeTimeout);
      this._closeTimeout = null;
    }
    this._toastService.close(this.toast._id);
  }

  _closeAfter(ms = 2000) {
    if (this._closeTimeout) {
      clearTimeout(this._closeTimeout);
    }
    this._closeTimeout = setTimeout(() => {
      this._toastService.close(this.toast._id);
      this._closeTimeout = null;
    }, ms);
  }
}
