import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from 'common/classes/error-state-matcher';
import { LoginFormErrorsMsgs } from 'common/config';
import { Referral, User, Country, IntegrationType } from 'common/models';
import { filesUrl } from 'common/config';
import { Countries } from '../../common';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-accounts-form',
  templateUrl: './accounts-form.component.html',
  styleUrls: ['./accounts-form.component.scss'],
})
export class AccountsFormComponent implements OnInit {
  @Input() isRegister: boolean;
  @Input() isForgot: boolean;
  @Input() isResetLink: boolean;
  @Input() isResendActivation: boolean;
  @Input() registerType: string;
  @Input() referral: Referral;
  @Input() acquirer: User;
  public filesUrl: string = filesUrl;
  public logoFile: string;
  public whitelistCountries$: Observable<Country[]>;
  public btnText: string = 'Login';
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  IntegrationTypeEnum = IntegrationType;

  formGroup: any;
  matcher: ErrorStateMatcher;

  errors = {
    password: '',
    email: '',
    check: '',
    audienceCountry: '',
  };

  constructor() {}

  ngOnInit() {
    this.btnText = this.getBtnText();
    if (this.registerType === 'publisher' && this.acquirer && this.acquirer.hasLogo) {
      this.logoFile = `${filesUrl}${this.acquirer._id}.png`;
    }
    if (this.registerType === 'publisher' && !this.acquirer) {
      // filtering is on the backend side
      this.whitelistCountries$ = Countries.find({});
    }
    const formVariants = {
      login: {
        email: ['', [Validators.required]],
        password: ['', [Validators.required]],
        check: [''],
      },
      register: {
        email: [this.referral ? this.referral.email : '', [Validators.email, Validators.required]],
        password: ['', [Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$`), Validators.required]],
        check: ['', [this._validateCheck]],
      },
      common: {
        email: ['', [Validators.required]],
      },
      resetLink: {
        password: ['', [Validators.pattern(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$`), Validators.required]],
      },
    };

    let form: object = formVariants.login;
    form = this.isResetLink ? formVariants.resetLink : form;
    form = this.isResendActivation ? formVariants.common : form;
    form = this.isRegister ? formVariants.register : form;
    form = this.isForgot ? formVariants.common : form;

    if (this.registerType === 'publisher' && !this.acquirer) {
      form['audienceCountry'] = ['', [Validators.required]];
      form['audienceSize'] = ['', [Validators.required]];
      form['preferredIntegrationType'] = ['', [Validators.required]];
    }

    this.formGroup = new FormBuilder().group(form);
    this.formGroup.valueChanges.subscribe((data) => this._onValueChanged(data));

    this._onValueChanged();
    this.matcher = new ErrorStateMatcher();
  }

  submitForm(val) {
    if (this.formGroup.valid) {
      this.submit.emit(val);
    }
  }

  getTermsOfUseLink() {
    if (this.registerType === 'publisher') {
      return 'https://www.adtonos.com/publisher-terms-of-use';
    }
    return `https://www.adtonos.com/${this.registerType}-terms`;
  }

  private _onValueChanged(data?: any) {
    if (!this.formGroup) {
      return;
    }
    const form = this.formGroup;

    for (const field in this.errors) {
      if (field) {
        const control = form.get(field) || {};
        this.errors[field] = '';
        const messages = LoginFormErrorsMsgs[field];
        for (const key in control.errors) {
          if (key) {
            this.errors[field] = messages[key];
          }
        }
      }
    }
  }

  private _validateCheck(c: FormControl) {
    return c.value
      ? null
      : {
          required: {
            valid: false,
          },
        };
  }

  private getBtnText(): string {
    if (this.isRegister) {
      return 'Create account';
    } else if (this.isForgot) {
      return 'Reset password';
    } else if (this.isResetLink) {
      return 'Change password';
    } else if (this.isResendActivation) {
      return 'Resend';
    } else {
      return 'Login';
    }
  }
}
