<div class="container campaigns">
  <div class="row align-items-center campaigns__header">
    <h3>Campaigns</h3>
    <div>
      <input [formControl]="searchInput" type="text" class="form-control" placeholder="Search..." />
    </div>
    <div class="dropdown" dropdown>
      <button class="button button__secondary button--small" dropdownToggle>
        <span>
          {{ scope | capitalize }}
        </span>
        <img src="assets/images/icons/dropdown.svg" />
      </button>
      <div *dropdownMenu class="dropdown-menu">
        <a class="dropdown-item" (click)="scope = 'all'">All</a>
        <a class="dropdown-item" *ngFor="let s of statusesMap" (click)="scope = s">{{ s | capitalize }}</a>
      </div>
    </div>
    <button
      title="Generate report with all campaigns"
      class="button button__secondary button--small"
      (click)="generateReport()"
    >
      Report
    </button>
    <button
      routerLink="new"
      [disabled]="!isFilled"
      title="{{ isFilled ? 'Add new campaign' : 'To add new campaign you need fill your company and payments info' }}"
      class="button button__primary button--small"
    >
      New campaign
    </button>
  </div>
  <app-campaigns-list [status]="scope" [search]="search" [isAdvertiser]="true"></app-campaigns-list>
</div>
