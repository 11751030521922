import { Observable } from 'rxjs';
import { Pricing } from './pricing';

export interface Tooltips {
  base_first$: Observable<Pricing>;
  base_in_block$: Observable<Pricing>;
  device$: Observable<Pricing>;
  language$: Observable<Pricing>;
  country$: Observable<Pricing>;
  city$: Observable<Pricing>;
  gender$: Observable<Pricing>;
  age$: Observable<Pricing>;
  interests$: Observable<Pricing>;
  intents$: Observable<Pricing>;
  brands$: Observable<Pricing>;
  events$: Observable<Pricing>;
  stream$: Observable<Pricing>;
  playtime_selected$: Observable<Pricing>;
  region$: Observable<Pricing>;
  remarketing$: Observable<Pricing>;
  mobile_segment$: Observable<Pricing>;
}
