import { Injectable } from '@angular/core';
import { Invoice, InvoiceStatus } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class InvoiceService {
  constructor() {}

  public searchInvoices(name: string, filter: Object, sort: Object): Observable<Invoice[]> {
    return MeteorObservable.call('invoice.searchInvoices', name, filter, sort);
  }

  public updateInvoice(id: string, status: InvoiceStatus): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        await MeteorObservable.call('invoice.update', { _id: id, status: status }).toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public createPublisherInvoice(publisherId: string) {
    console.log('calling invoice.createPublisherInvoice');
    return MeteorObservable.call('invoice.createPublisherInvoice', publisherId);
  }

  public regenerateInvoice(id: string) {
    return MeteorObservable.call('invoice.regenerate', { _id: id });
  }
}
