export interface SupplyPackageMatch {
  supplierId?: string[];
  revenueStreamId?: string[];
  country?: string[];
  bundle?: string[];
  domain?: string[];
  contentCategory?: string[];
  packageNames?: string[];
}

export interface SupplyPackage {
  _id: string;
  name: string;
  tag: string;
  exchangeId?: string;
  include: SupplyPackageMatch;
  exclude: SupplyPackageMatch;
  active: boolean;
}
