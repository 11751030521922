import { IntegrationStatus } from './integration-status';

export class IntegrationCheck {
  _id?: string;
  createdAt: Date;
  streamId: string;
  status: IntegrationStatus;
  AudiencePass: boolean;
  AdBreakPass: boolean;
  VastPass: boolean;
  PlaylistPass: boolean;
  PublisherTrackingsPass: boolean;
  TrackingCodePass: boolean;
  StreamingUrlPass: boolean;
  AdsTxtPass: boolean;
  PlayoutPass: boolean;
  GaidPass: boolean;
  IdfaPass: boolean;
  googleTxtPass: boolean;
  appleTxtPass: boolean;
  ListenerRetentionRatioPass: boolean;
}

export const getIntegrationType = (integration: IntegrationCheck): string => {
  const integrationType = integration ? integration.status : 'None';
  if (integrationType in IntegrationStatus) {
    return integrationType;
  }
  return 'None';
};
