export type SettingAttr =
  | 'billing_name'
  | 'vat_number'
  | 'address_line_1'
  | 'address_line_2'
  | 'city'
  | 'postcode'
  | 'country_code'
  | 'vat_rate'
  | 'contact_email'
  | 'bank_account_gbp'
  | 'bank_account_usd'
  | 'bank_account_eur'
  | 'bank_account_pln';

export const SettingAttr: {
  BillingName: SettingAttr;
  VatNumber: SettingAttr;
  AddressLine1: SettingAttr;
  AddressLine2: SettingAttr;
  City: SettingAttr;
  Postcode: SettingAttr;
  CountryCode: SettingAttr;
  VatRate: SettingAttr;
  ContactEmail: SettingAttr;
  BankAccountGBP: SettingAttr;
  BankAccountUSD: SettingAttr;
  BankAccountEUR: SettingAttr;
  BankAccountPLN: SettingAttr;
} = {
  BillingName: 'billing_name',
  VatNumber: 'vat_number',
  AddressLine1: 'address_line_1',
  AddressLine2: 'address_line_2',
  City: 'city',
  Postcode: 'postcode',
  CountryCode: 'country_code',
  VatRate: 'vat_rate',
  ContactEmail: 'contact_email',
  BankAccountGBP: 'bank_account_gbp',
  BankAccountUSD: 'bank_account_usd',
  BankAccountEUR: 'bank_account_eur',
  BankAccountPLN: 'bank_account_pln',
};
