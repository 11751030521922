import { City } from 'common';

export function citiesWithRegionsFormat(cities: City[], withRegions: boolean = false) {
  const precede = withRegions ? 'All cities belonging to chosen regions' : '';
  const unite = withRegions && cities.length ? 'and: ' : '';
  const label = cities
    .map((city) => city.title)
    .sort()
    .join(', ');
  return cities.length || precede.length ? `${precede} ${unite}${label}` : 'All';
}
