import { Injectable } from '@angular/core';
import { AdvertiserPixelDto, Country } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService {
  constructor() {}

  public fetchFloorPriceCountries(): Observable<string[]> {
    return MeteorObservable.call('country.fetchFloorPriceCountries');
  }

  public getCountriesList(): Observable<Country[]> {
    return MeteorObservable.call<Country[]>('country.getCountries');
  }

  public filterSelectedCountries(countryCodes: string[]): Observable<Country[]> {
    return MeteorObservable.call<Country[]>('country.filterSelectedCountries', countryCodes);
  }
}
