import { Component, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxItem } from './types';
import { createCheckBoxItem } from './utils';

const ALL_KEY = '$all$';

@Component({
  selector: 'checkbox-group-form',
  templateUrl: './checkbox-group-form.component.html',
  styleUrls: ['./checkbox-group-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxGroupFormComponent,
    },
  ],
})
export class CheckboxGroupFormComponent implements OnChanges {
  @Input() label: string;
  @Input() options: CheckboxItem[] = [];
  @Input() inline: boolean = false;
  @Input() required: boolean = false;
  @Input() allLabel: string = '';

  private allChecked: boolean = false;
  private disabled: boolean;
  private selectedValues: string[];
  public localOptions: CheckboxItem[] = [];

  ngOnChanges(changes) {
    if (changes.options) {
      const allLabel = changes.allLabel?.currentValue || this.allLabel;
      if (allLabel) {
        this.localOptions = [createCheckBoxItem(ALL_KEY, allLabel), ...changes.options.currentValue];
      } else {
        this.localOptions = [...changes.options.currentValue];
      }
    }
  }

  onChangeListener: (value: any) => {};
  onTouchedListener: () => {};

  writeValue(values: string[]) {
    this.selectedValues = values;
    this.allChecked = values.length && values.length === this.options.length;
  }
  registerOnChange(onChangeListener: (value: any) => {}) {
    this.onChangeListener = onChangeListener;
  }

  registerOnTouched(onTouchedListener: () => {}) {
    this.onTouchedListener = onTouchedListener;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  isInvalid() {
    return this.required && this.selectedValues.length === 0;
  }
  onToggle(event: Event) {
    this.onTouchedListener();
    if (!this.disabled) {
      const checkbox = event.target as HTMLInputElement;
      const value = checkbox.value;
      if (value === ALL_KEY) {
        this.selectedValues.length = 0;
        this.allChecked = false;
        if (checkbox.checked) {
          for (const item of this.options) {
            if (item.value !== ALL_KEY) {
              this.selectedValues.push(item.value);
            }
          }
          this.allChecked = true;
        }
      } else if (checkbox.checked) {
        this.selectedValues.push(value);
        this.allChecked = this.selectedValues.length === this.options.length;
      } else {
        this.selectedValues.splice(this.selectedValues.indexOf(value), 1);
        this.allChecked = false;
      }
    }

    this.onChangeListener(this.selectedValues);
  }

  isChecked(value: string) {
    if (value === ALL_KEY) {
      return this.allChecked;
    }
    return this.selectedValues.includes(value);
  }
}
