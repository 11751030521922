<div class="modal_wrapper">
  <div>
    <div class="modal_inner">
      <div>
        <h4>Basic integration:</h4>
        <pre><code>{{ text1 }}</code></pre>
      </div>

      <div>
        <h4>Google Tag Manager / Google Analytics integration:</h4>
        <mat-tab-group>
          <mat-tab label="Universal Analytics">
            <pre><code>{{ universalCode }}</code></pre>
          </mat-tab>
          <mat-tab label="GA4 Configuration">
            <mat-form-field class="ga4input" appearance="fill">
              <mat-label>Measurement ID</mat-label>
              <input matInput id="ga4id" [(ngModel)]="ga4.id" (ngModelChange)="changeGa4Id($event)" placeholder="G-XXXXXXXXXX">
            </mat-form-field>
            <pre><code>{{ ga4.code }}</code></pre>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="modal_footer">
    <button type="button" class="button button__secondary button--small" (click)="close()">
      OK
    </button>
  </div>
</div>
