import { switchMap, mergeMap, debounceTime, scan, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DspCampaign } from 'common/models/dsp-campaign';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ModalService,
  ToastService,
  Genders,
  AgeRanges,
  Interests,
  Intents,
  Brands,
  Events,
  User,
  UserRole,
} from 'common';
import { filesUrl } from 'common/config';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable, of, merge, combineLatest, Subscription } from 'rxjs';
import * as moment from 'moment';

const ARCHIVE_MONTHS = 3;

interface Targeting {
  _id?: string;
  attributeId: string;
  name: string;
}
interface TargetingObject {
  [x: string]: Targeting;
}

interface UIElementProps {
  visible?: boolean;
  disabled?: boolean;
}

export interface UI {
  [propName: string]: UIElementProps;
}

interface UIContext {
  isAdmin: boolean;
  campaign: DspCampaign;
}

type UIElements = Record<string, (ctx: UIContext) => UIElementProps>;

interface DspCampaignWithProperTargeting extends DspCampaign {
  genderNames?: string[];
  ageRanges?: string[];
  interests?: string[];
  intents?: string[];
  brands?: string[];
  events?: string[];
  lastPlayedAt?: string;
  lastPlayed?: string;
}

@Component({
  selector: 'app-dsp-campaign',
  templateUrl: './dsp-campaign.component.html',
  styleUrls: ['./dsp-campaign.component.scss'],
})
export class DspCampaignComponent implements OnInit, OnDestroy {
  public dspCampaign: DspCampaignWithProperTargeting;
  public dspCampaignDuration: string;
  public adUrl: string;
  public isAdmin: boolean;
  public advertiser: User;
  public filesUrl: string = filesUrl;
  public ui: UI;

  private combinetStreams: Subscription;
  private uiElements: UIElements = DspCampaignComponent.createUIElements();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService
  ) {}

  ngOnDestroy() {
    this.combinetStreams.unsubscribe();
  }

  ngOnInit() {
    {
      // Isolate user varriable to remove with GC
      const user = Meteor.user() as any as User; // HACK! Cast Meteor.user to correct interface.
      if (user.roles.includes(UserRole.Admin)) {
        this.isAdmin = true;
      }
    }
    /**
     *  Debounce is needed becouse Angular rerendering DOM in every data catch.
     */
    const targetings$ = merge(
      Genders.find().pipe(debounceTime(100)),
      AgeRanges.find().pipe(debounceTime(100)),
      Interests.find().pipe(debounceTime(100)),
      Intents.find().pipe(debounceTime(100)),
      Brands.find().pipe(debounceTime(100)),
      Events.find().pipe(debounceTime(100))
    ).pipe(
      mergeMap((results: Targeting[]) => of(...results)),
      scan((targetings, result) => ({ ...targetings, [result._id]: result }), {} as TargetingObject),
      debounceTime(100)
    ); // Split results.

    const campaign$ = this.route.params.pipe(
      switchMap((params) => {
        return MeteorObservable.call('dspCampaigns.get', params.id) as Observable<DspCampaign>;
      })
    );

    this.combinetStreams = combineLatest([campaign$, targetings$]).subscribe({
      next: ([dsp, targetings]) => {
        if (dsp && targetings) {
          const dspCampaign: DspCampaignWithProperTargeting = dsp as any;

          if (dspCampaign.genders) {
            dspCampaign.genderNames = dspCampaign.genders.map((id) => targetings[id].name);
          } else {
            dspCampaign.genderNames = [];
          }
          if (dspCampaign.ageRangeIds) {
            dspCampaign.ageRanges = dspCampaign.ageRangeIds.map((id) => targetings[id].name);
          } else {
            dspCampaign.ageRanges = [];
          }

          if (dspCampaign.interestIds) {
            dspCampaign.interests = dspCampaign.interestIds.map((id) => targetings[id].name);
          } else {
            dspCampaign.interests = [];
          }

          if (dspCampaign.intentIds) {
            dspCampaign.intents = dspCampaign.intentIds.map((id) => targetings[id].name);
          } else {
            dspCampaign.intents = [];
          }

          if (dspCampaign.brandIds) {
            dspCampaign.brands = dspCampaign.brandIds.map((id) => targetings[id].name);
          } else {
            dspCampaign.brands = [];
          }

          if (dspCampaign.eventIds) {
            dspCampaign.events = dspCampaign.eventIds.map((id) => targetings[id].name);
          } else {
            dspCampaign.events = [];
          }
          if (dspCampaign.lastPlayedAt) {
            dspCampaign.lastPlayed = moment(dspCampaign.lastPlayedAt).format('HH:mm:SS DD-MM-YYYY');
          }
          this.dspCampaign = dspCampaign;
          this.adUrl = new URL(dspCampaign.mediaFile, filesUrl).toString();
          this.advertiser = Meteor.users.findOne({ _id: this.dspCampaign.advertiserId }) as any as User;
          this.ui = this.updateUIState();
        }
      },
      error: () => {
        this.toastService.error('DSP Campaign not found!');
        this.router.navigate(['../'], { relativeTo: this.route });
      },
    });
  }

  public async archive(): Promise<void> {
    if (!this.dspCampaign) {
      return;
    }
    try {
      const confirmed = await this.modalService.openConfirmModal({
        text: `Do you want archive campaign: ${this.dspCampaign.name}?`,
      });
      if (confirmed) {
        MeteorObservable.call('dspCampaigns.archive', this.dspCampaign)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.dspCampaign = { ...this.dspCampaign, isArchived: true };
              this.ui = this.updateUIState();
              this.toastService.success('DSP Campaign archived.');
            },
            error: (err) => {
              this.archiveErrorHandler(err);
            },
          });
      }
    } catch (err) {
      this.archiveErrorHandler(err);
    }
  }

  private updateUIState(): UI {
    const ctx = {
      campaign: this.dspCampaign,
      isAdmin: this.isAdmin,
    };
    const DEFAULT_PROPS = {
      visible: false,
      disabled: false,
    };

    return Object.entries(this.uiElements).reduce((acc, [name, handler]) => {
      return { ...acc, [name]: { ...DEFAULT_PROPS, ...handler(ctx) } };
    }, {});
  }

  private static createUIElements(): UIElements {
    return {
      edit: ({ campaign: { isArchived } }: UIContext): UIElementProps => ({
        disabled: isArchived,
      }),
      csvReport: ({ campaign: { isArchived, archiveReportFile } }: UIContext): UIElementProps => {
        if (isArchived) {
          return {
            visible: !archiveReportFile,
            disabled: !archiveReportFile,
          };
        }
        return {
          visible: true,
        };
      },
      csvReportUrl: ({ campaign: { isArchived, archiveReportFile } }: UIContext): UIElementProps => ({
        visible: isArchived && !!archiveReportFile,
      }),
      archive: ({ isAdmin, campaign: { isArchived, lastPlayout, createdAt } }: UIContext): UIElementProps => {
        const lastActivityTime = lastPlayout || createdAt;
        const isOldEnoughToArchive = lastActivityTime
          ? moment().diff(lastActivityTime, 'months') >= ARCHIVE_MONTHS
          : true;
        return {
          visible: (isAdmin && isArchived) || isOldEnoughToArchive,
          disabled: !!isArchived,
        };
      },
    };
  }

  private archiveErrorHandler(error: Meteor.Error): void {
    const defaultError = 'Error occurred when archived DSP Campaing';
    const reason = error.reason || error.error || defaultError;
    this.toastService.error(reason);
  }
}
