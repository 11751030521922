import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AllowedRegisterTypes } from 'common/config';

@Injectable()
export class RegisterGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const type = next.params.type;
    const allow = AllowedRegisterTypes.indexOf(type) > -1;
    if (!allow) {
      this._router.navigateByUrl('/login');
    } else {
      return allow;
    }
  }
}
