import { ObservableCursor } from 'meteor-rxjs';
import { AgeRange, Country, Gender, Interest, Language, Network, Stream, Intent, Brand, Event } from 'common/models';

export interface AutosuggestItems {
  countries$: ObservableCursor<Country>;
  languages$: ObservableCursor<Language>;
  networks$: ObservableCursor<Network>;
  streams$: ObservableCursor<Stream>;
  genders$: ObservableCursor<Gender>;
  playTime: string[];
  deviceTypes: string[];
  radii: number[];
  ageRanges$: ObservableCursor<AgeRange>;
  interests$: ObservableCursor<Interest>;
  intents$: ObservableCursor<Intent>;
  brands$: ObservableCursor<Brand>;
  events$: ObservableCursor<Event>;
  lengths: string[];
}
