<div class="modal-integration-status">
  <div class="modal__header">
    <h4 class="modal__header__title">
      Are you sure you want to approve the station?
    </h4>
    <button type="button" class="modal__header__close" (click)="close()" aria-label="Close"></button>
  </div>
  <div class="modal__body">
      The station you are about to approve has <span class="fail">Failed</span> verifcation status.
  </div>
  <div class="modal__footer">
    <div class="footer__actions">
      <button (click)="openStreamIntegrationStatus()">Details</button>
      <div class="footer__actions--right">
        <button (click)="close()" class="button__secondary">Cancel</button>
        <button (click)="confirm()">Approve</button>
      </div>
    </div>
  </div>
</div>
