<div class="container settings" *ngIf="campaign">
  <div class="row align-items-center settings__header">
    <h3>
      Campaign
      <span class="muted" *ngIf="id">#{{ campaign.campaignId }}</span>
    </h3>

    <app-campaign-actions-bar [campaign]="campaign"></app-campaign-actions-bar>

  </div>
  <div class="row settings__data">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field label="Name" [content]="campaign.name"></app-detail-field>
        <app-detail-field label="Domain" [content]="campaign.domain"></app-detail-field>
        <app-detail-field
          label="Network"
          [content]="getNetworkName(campaign.networkId)"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field *ngIf="campaign?.dealId && campaign?.dealId.length" label="Deal ID" [content]="campaign.dealId"></app-detail-field>
        <app-detail-field *ngIf="campaign?.buyerSeat && campaign?.buyerSeat.length" label="Buyer Seat" [content]="campaign.buyerSeat"></app-detail-field>
        <app-detail-field label="Type" [content]="getCampaignType(campaign.length)"></app-detail-field>

        <div class="detail-field app-detail-field">
          <label class="detail-field__label">Audio file</label>
          <span class="detail-field__value" *ngFor="let item of campaign.files | keyvalue">
            <audio controls>
              <source [src]="filesUrl + item.value['128_44100_s']" type="audio/mpeg" />
            </audio>
          </span>
          <div *ngIf="isFilesProcessing">
            <span class="detail-field__value">
              Some files are processing.
            </span>
          </div>
          <span *ngIf="campaign?.callToActionFilesAlexa && campaign?.deviceTypes.length && campaign?.deviceTypes.includes('smart speaker')">
            <label class="detail-field__label-without-top-margin">Call to action for Amazon Alexa</label>
            <span class="detail-field__value">
              <audio controls>
                <source [src]="filesUrl + (campaign?.callToActionFilesAlexa)['128_44100_s']" type="audio/mpeg" />
              </audio>
            </span>
          </span>
          <span *ngIf="campaign?.callToActionFilesGoogleHome && campaign?.deviceTypes.length && campaign?.deviceTypes.includes('smart speaker')">
            <label class="detail-field__label-without-top-margin">Call to action for Google Home</label>
            <span class="detail-field__value">
              <audio controls>
                <source [src]="filesUrl + (campaign?.callToActionFilesGoogleHome)['128_44100_s']" type="audio/mpeg" />
              </audio>
            </span>
          </span>
        </div>

        <app-detail-field
          *ngIf="campaign.banner"
          label="Companion banner"
          [image]="campaign.banner.image.publicURL"
          [imageLink]="campaign.banner.clickThroughUrl"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!campaign.banner"
          label="Companion banner"
          content="No"
        ></app-detail-field>


        <app-detail-field label="Ad Language" [content]="campaign.adLanguage | uppercase"></app-detail-field>
        <app-detail-field
          label="Ad Category"
          [content]="getAdCategoryName(campaign.adCategory)"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Remarketing"
          [content]="campaign.remarketing ? 'Yes' : 'No'"
          emptyArrayPlaceholder="No"
        ></app-detail-field>
        <app-detail-field
          label="Product Type"
          [content]="campaign.productType || 'All'"
          emptyArrayPlaceholder="No"
        ></app-detail-field>
      </div>
    </div>

    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field
          label="Device"
          [content]="campaign.deviceTypes || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Language"
          [content]="getLanguages(campaign.languages) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Country"
          [content]="getCountries(campaign.countryCodes) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Regions"
          [content]="regions$ | async"
          emptyArrayPlaceholder="All"
          ></app-detail-field>
        <app-detail-field
          label="City"
          [content]="cities$ | async"
          emptyArrayPlaceholder="All"
          ></app-detail-field>
        <app-detail-field
          label="Geotargeting"
          [content]="geotargetingDescription"
        ></app-detail-field>
        <app-detail-field
          [label]="campaign.gendersExcludeFlag ? 'Gender (exclude)' : 'Gender'"
          [content]="getGenders(campaign.genders) || 'All'"
          [emptyArrayPlaceholder]="campaign.gendersExcludeFlag ? 'None' : 'All'"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="gendersExactTargeting">Exact Targeting Enabled</div>
        <app-detail-field
          [label]="campaign.ageRangeExcludeFlag ? 'Age (exclude)' : 'Age'"
          [content]="getAgeRanges(campaign.ageRangeIds) || 'All'"
          [emptyArrayPlaceholder]="campaign.ageRangeExcludeFlag ? 'None' : 'All'"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="ageExactTargeting">Exact Targeting Enabled</div>
        <app-detail-field
          label="Interest"
          [content]="getInterests(campaign.interestIds) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="interestExactTargeting">Exact Targeting Enabled</div>
        <app-detail-field
          label="Intent"
          [content]="getIntents(campaign.intentIds) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="intentsExactTargeting">Exact Targeting Enabled</div>
        <app-detail-field
          label="Brand"
          [content]="getBrands(campaign.brandIds) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="brandsExactTargeting">Exact Targeting Enabled</div>
        <app-detail-field
          label="Event"
          [content]="getEvents(campaign.eventIds) || 'All'"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <div class="detail-field__value exact-targeting" *ngIf="eventsExactTargeting">Exact Targeting Enabled</div>
      </div>
    </div>
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <app-detail-field
          label="Nielsen Segment"
          [content]="nielsenSegments$ | async"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Content Language"
          [content]="language$ | async"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Content Category"
          [content]="contentCategories$ | async"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Audio Channel Types"
          [content]="getAudioProductTypes(campaign.audioProductTypes || [])"
        ></app-detail-field>
        <app-detail-field
          label="Mobile Segment"
          [content]="campaign.mobileSegment"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Streams"
          [content]="getStreams(campaign.streamIds)"
          emptyArrayPlaceholder="All"
        ></app-detail-field>
        <app-detail-field
          label="Date range"
          content="{{ campaign.startDate | amDateFormat: 'DD.MM.YYYY' }} - {{
            campaign.endDate | amDateFormat: 'DD.MM.YYYY'
          }}"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!campaign.playTimes || !campaign.playTimes.length"
          label="Play times"
          content="All week"
        ></app-detail-field>
        <app-detail-field
          label="Playout capping"
          content="{{ campaign.playoutCapping ? 'Up to ' + campaign.playoutCapping : 'No limit' }}"
        ></app-detail-field>

        <app-detail-field label="Daily Budget" content="{{ getDailyBudget() }}"></app-detail-field>

        <app-detail-field
          *ngIf="isAdmin"
          label="Cost per play"
          content="{{ campaign.normalizedCPP | price6 }} GBP{{
            campaign.currency !== 'gbp'
              ? ' / ' + (campaign.costPerPlay | price6) + ' ' + (campaign.currency | uppercase)
              : ''
          }}"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!isAdmin"
          label="Cost per play"
          content="{{ campaign.costPerPlay | price6 }} {{ campaign.currency | uppercase }}"
        ></app-detail-field>

        <app-detail-field
          *ngIf="isAdmin"
          label="Total cost per play"
          content="{{ campaign.normalizedTotalCPP | price6 }} GBP{{
            campaign.currency !== 'gbp'
              ? ' / ' +
                (campaign.totalTargetingCost + campaign.costPerPlay | price6) +
                ' ' +
                (campaign.currency | uppercase)
              : ''
          }}"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!isAdmin"
          label="Total cost per play"
          content="{{ campaign.totalTargetingCost + campaign.costPerPlay | price6 }} {{
            campaign.currency | uppercase
          }}"
        ></app-detail-field>

        <app-detail-field
          *ngIf="isAdmin"
          label="Total Budget"
          content="{{ campaign.normalizedTotalBudget | price2 }} GBP{{
            campaign.currency !== 'gbp'
              ? ' / ' + (campaign.totalBudget | price2) + ' ' + (campaign.currency | uppercase)
              : ''
          }}"
        ></app-detail-field>
        <app-detail-field
          *ngIf="!isAdmin"
          label="Total Budget"
          content="{{ campaign.totalBudget | price2 }} {{ campaign.currency | uppercase }}"
        ></app-detail-field>

        <ng-template [ngIf]="isPublisherAsAdvertiser()">
          <app-detail-field
            *ngIf="isAdmin"
            label="Advertiser Budget"
            content="{{ campaign.normalizedTotalBudget / (tooltips.playout_commission$ | async)?.value | price2 }} GBP{{
              campaign.currency !== 'gbp'
                ? ' / ' +
                  (campaign.totalBudget / (tooltips.playout_commission$ | async)?.value | price2) +
                  ' ' +
                  (campaign.currency | uppercase)
                : ''
            }}"
          >
          </app-detail-field>
          <app-detail-field
            *ngIf="!isAdmin"
            label="Advertiser Budget"
            content="{{ campaign.totalBudget / (tooltips.playout_commission$ | async)?.value | price2 }} {{
              campaign.currency | uppercase
            }}"
          >
          </app-detail-field>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row settings__data" *ngIf="campaign.playTimes && campaign.playTimes.length">
    <div class="settings__data__box">
      <div class="settings__data__box__content">
        <p class="detail-field__label text-center">
          Play Times
        </p>
        <div class="row justify-content-center">
          <div
            class="col-sm-6 col-md-2 detail-field__value detail-field--play-time"
            *ngFor="let playTime of getPlayTimes(campaign.playTimes)"
          >
            <p class="detail-field__value__label">{{ playTime.label }}</p>
            <span class="detail-field__value__tag" *ngFor="let time of playTime.values">
              {{ getPlayTimeLabel(time) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
