import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LoaderComponent, LoaderManager } from 'components/loader';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(LoaderComponent) public loader: LoaderComponent;

  constructor(private _loaderManager: LoaderManager, private swUpdate: SwUpdate) {}

  ngOnInit() {
    this.hotjarInicializer();
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }

  ngAfterViewInit() {
    this._loaderManager.init(this.loader);
  }

  hotjarInicializer() {
    if (environment.production) {
      const hotjarScript = document.createElement('script');
      hotjarScript.innerHTML = `
      (function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 827228, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      `;
      document.head.appendChild(hotjarScript);
    }
  }
}
