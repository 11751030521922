import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from 'common';
import { ComponentsModule } from 'components';
import { InvoicesComponent } from './invoices.component';
import { InvoiceOrderComponent } from './invoice-order';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, ComponentsModule, BsDropdownModule.forRoot(), RouterModule, FormsModule, ReactiveFormsModule],
  declarations: [InvoicesComponent, InvoiceOrderComponent],
})
export class InvoicesModule {}
