<div class="container campaigns" *ngIf="dspCampaign">
  <div class="row campaigns__header">
    <div>
      <h3>
        Edit DSP Campaign
        <span class="muted">#{{ dspCampaign._id }}</span>
      </h3>
    </div>
  </div>
  <div class="row campaigns__form">
    <form class="campaigns__form__content campaigns__form__content--row" [formGroup]="formGroup" *ngIf="formGroup">
      <div class="col-md-12 col-lg-6">
        <div *ngIf="advertisers" class="form-group">
          <label for="advertiser">Advertiser</label>
          <select id="advertiser" formControlName="advertiserId" class="form-control">
            <option value="null">None</option>
            <option [value]="advertiser._id" *ngFor="let advertiser of advertisers">
              {{ advertiser.billingName }} ({{ advertiser.accountId }})
            </option>
          </select>
        </div>

        <div class="row m-0">
          <div class="form-group col-8 p-0">
            <label for="genders">Genders</label>
            <app-tag
              id="genders"
              formControlName="genders"
              placeholder="Add"
              valueKey="_id"
              displayKey="name"
              secondaryPlaceholder="Add new gender"
              [autocompleteItems]="autosuggestItems.genders$ | async"
              source="genders"
            ></app-tag>
          </div>
          <div class="genders_exclude col-2 p-0">
            <label class="checkbox">
              <input type="checkbox" formControlName="gendersExcludeFlag" />
              <div class="checkbox__indicator input_checkbox"></div>
              <span class="checkbox_label">Exclude</span>
            </label>
          </div>
        </div>

        <div class="row m-0">
          <div class="form-group col-8 p-0">
            <label for="ageRange">Age</label>
            <app-tag
              id="ageRange"
              formControlName="ageRangeIds"
              placeholder="Add"
              secondaryPlaceholder="Add new age"
              displayKey="name"
              valueKey="_id"
              filter="[0-9.,]"
              [autocompleteItems]="autosuggestItems.ageRanges$ | async"
              source="ageRanges"
            ></app-tag>
          </div>
          <div class="ageRange_exclude col-2 p-0">
            <label class="checkbox">
              <input type="checkbox" formControlName="ageRangeExcludeFlag" />
              <div class="checkbox__indicator input_checkbox"></div>
              <span class="checkbox_label">Exclude</span>
            </label>
          </div>
        </div>

        <div class="form-group col-8 p-0">
          <label for="interests">Interests</label>
          <app-tag
            id="interests"
            formControlName="interestIds"
            placeholder="Add"
            secondaryPlaceholder="Add new interest"
            displayKey="name"
            valueKey="_id"
            [autocompleteItems]="autosuggestItems.interests$ | async"
            source="interests"
          ></app-tag>
        </div>

        <div class="form-group col-8 p-0">
          <label for="intents">Intents</label>
          <app-tag
            id="intents"
            formControlName="intentIds"
            placeholder="Add"
            secondaryPlaceholder="Add new intent"
            displayKey="name"
            valueKey="_id"
            [autocompleteItems]="autosuggestItems.intents$ | async"
            source="intents"
          ></app-tag>
        </div>

        <div class="form-group col-8 p-0">
          <label for="brands">Brands</label>
          <app-tag
            id="brands"
            formControlName="brandIds"
            placeholder="Add"
            secondaryPlaceholder="Add new brand"
            displayKey="name"
            valueKey="_id"
            [autocompleteItems]="autosuggestItems.brands$ | async"
            source="brands"
          ></app-tag>
        </div>

        <div class="form-group col-8 p-0">
          <label for="events">Events</label>
          <app-tag
            id="events"
            formControlName="eventIds"
            placeholder="Add"
            secondaryPlaceholder="Add new event"
            displayKey="name"
            valueKey="_id"
            [autocompleteItems]="autosuggestItems.events$ | async"
            source="events"
          ></app-tag>
        </div>
      </div>
    </form>
    <div class="campaigns__form__footer">
      <button class="button button__secondary button--small" routerLink="../">
        Cancel
      </button>
      <button class="button button__primary button--small" (click)="update()">
        Save
      </button>
    </div>
  </div>
</div>
