import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { TagComponent } from './tag.component';
import { CommonModule } from 'common';

@NgModule({
  declarations: [TagComponent],
  imports: [CommonModule, FormsModule, TagInputModule],
  exports: [TagComponent],
})
export class TagModule {}
