export function twoDatesSort(
  dateA: Date | null,
  dateB: Date | null,
  sortOrder: number
) {
  let fieldA: number = dateA ? dateA.getTime() : 0;
  let fieldB: number = dateB ? dateB.getTime() : 0;

  if (fieldA > fieldB) return sortOrder;
  else if (fieldA < fieldB) return sortOrder * -1;
  return 0;
}
