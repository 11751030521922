import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DspCampaignsComponent } from './dsp-campaigns.component';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DspCampaignsComponent],
  exports: [DspCampaignsComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, BsDropdownModule.forRoot(), FormsModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [DspCampaignsComponent],
})
export class DspCampaignsModule {}
