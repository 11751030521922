import { Injectable } from '@angular/core';
import { LoaderComponent } from './loader.component';

@Injectable({
  providedIn: LoaderComponent,
})
export class LoaderManager {
  private _loader: LoaderComponent;

  public init(loader: LoaderComponent): void {
    this._loader = loader;
  }

  public show(): void {
    if (this._loader) {
      this._loader.show();
    }
  }

  public hide(): void {
    if (this._loader) {
      this._loader.hide();
    }
  }
}
