export enum DaysOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 4,
  THURSDAY = 8,
  FRIDAY = 16,
  SATURDAY = 32,
  SUNDAY = 32, // Its same value because on "play time" inputs this two days is connected in to weekend.
}

export interface DaysOfWeekObject {
  array: DaysOfWeek[] | string[];
  binary: number;
  binaryArray: ('0' | '1')[];
}
