import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AutopromoteAdComponent } from './autopromote-ad.component';
import { ComponentsModule } from 'components';
import { CommonModule } from 'common';

@NgModule({
  declarations: [AutopromoteAdComponent],
  exports: [AutopromoteAdComponent],
  imports: [CommonModule, ComponentsModule, RouterModule, BsDropdownModule.forRoot()],
  providers: [],
  bootstrap: [AutopromoteAdComponent],
})
export class AutopromoteAdModule {}
