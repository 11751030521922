import { Injectable } from '@angular/core';
import { AppDetails } from 'common/models';
import { MeteorObservable } from 'meteor-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class AppDataService {
  fetchAppDetailsByStoreUrl(storeUrl: string): Observable<AppDetails | null> {
    return MeteorObservable.call('appData.fetchAppDetails', storeUrl);
  }
}
