export interface AdCategoryRemap {
  _id: string;
  publisherId: string;
  mediaUrl: string;
  currentCategoryId: string;
  correctCategoryId: string;
  expiredAt: Date;
  updatedAt: Date;
  createdAt: Date;
}
