import { Pipe, PipeTransform } from '@angular/core';
import { RateLimit } from 'common/models';

@Pipe({
  name: 'rateLimitFormat',
})
export class RateLimitFormatPipe implements PipeTransform {
  transform(rateLimit: RateLimit, args?: any): any {
    if (rateLimit.enabled) {
      if (args === 'short') {
        return `${rateLimit.value}/${rateLimit.period}`;
      }
      return `Requests per ${rateLimit.period}: ${rateLimit.value}`;
    }
    return 'Off';
  }
}
