import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-field-area',
  templateUrl: './detail-field-area.component.html',
})
export class DetailFieldAreaComponent {
  @Input() label: string;
  @Input() code: boolean = false;
  @Input() content: string | string[];
}
