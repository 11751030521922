<div class="modal-integration-status">
  <div class="modal__header">
    <h4 class="modal__header__title">
      Are you sure you want to approve the station?
    </h4>
    <button type="button" class="modal__header__close" (click)="close()" aria-label="Close"></button>
  </div>
  <div class="modal__body">
    This station is a duplicate. Turn off the currently active station before activating this station.
  </div>
  <div class="modal__footer">
    <div class="footer__actions">
      <div class="footer__actions--right">
        <button (click)="close()" class="button__secondary">Cancel</button>
        <button (click)="confirm()">Approve</button>
      </div>
    </div>
  </div>
</div>
