export type BudgetType = 'proportional' | 'manual' | 'unlimited';
export const BudgetType: {
  Proportional: BudgetType;
  Manual: BudgetType;
  Unlimited: BudgetType;
} = {
  Proportional: 'proportional',
  Manual: 'manual',
  Unlimited: 'unlimited',
};
