import { get as _get } from 'lodash';
export function caseInsensitiveSort(itemA: any, itemB: any, sortField: string, sortOrder: number) {
  let fieldA = _get(itemA, sortField);
  let fieldB = _get(itemB, sortField);
  if (typeof fieldA === 'string' && typeof fieldB === 'string') {
    fieldA = fieldA.toLocaleLowerCase();
    fieldB = fieldB.toLocaleLowerCase();
  }

  if (fieldA > fieldB) return sortOrder;
  else if (fieldA < fieldB) return sortOrder * -1;
  return 0;
}
